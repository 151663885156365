import React from "react";
import { Link, useParams } from "react-router-dom";
import { blogData } from "./Data";
import child1 from "../../assets/img/blog/rc_post01.jpg";
import child2 from "../../assets/img/blog/rc_post02.jpg";
import child3 from "../../assets/img/blog/rc_post03.jpg";
import child4 from "../../assets/img/blog/rc_post04.jpg";
import whatsapp from "../../assets/img/icons/whasapp.png";
import logo from "../../assets/img/images/ed.svg";
import blog_1 from "../../assets/img/fee.png";

import qrcode from "../../assets/img/qrcode.png";
import { faChessBoard, faStar } from "@fortawesome/free-solid-svg-icons";
import testimonial1 from "../../assets/img/images/testi_avatar01.png";
import testimonial2 from "../../assets/img/images/testi_avatar02.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import InputFloatLabel from "../../Common/FloatLabel/InputFloatLabel";
import SelectFloatLabel from "../../Common/FloatLabel/SelectFloatLabel";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePickerFloatLable";
import { Button, Col, Form, Row, Select, Space, notification } from "antd";
import "../../assets/css/Modal.css";
import dayjs from "dayjs";
import { useCreateDemoMutation } from "../../RTKQuery/RtkQuery";
import moment from "moment";

const IntitialBlog = () => {
  // api call
  const [requestDemo] = Form.useForm();
  const [createDemo, { isLoading }] = useCreateDemoMutation();
  const { Option } = Select;

  const onFinishFailed = (errorInfo) => {};
  const dateWithTime = (preferredDemoDate) => {
    return dayjs(preferredDemoDate).format("YYYY-MM-DD HH:mm:ss");
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onFinish = async (values) => {
    const { preferredDemoDate, ...restValues } = values;
    const formattedDateTime = dateWithTime(preferredDemoDate);

    const payload = {
      ...restValues,
      preferredDemoDate: formattedDateTime,
      schoolSize: "",
      roleAtSchool: "",
      additionalInformation: "",
    };
    try {
      const response = await createDemo(payload);
      if (response.data.status === 200) {
        requestDemo.resetFields();

        notification.success({
          message: "Demo Request Successful",
          description: "Your demo request has been successfully submitted.",
        });
      } else {
        notification.error({
          message: "Demo Request Failed",
          description: "Sorry, something went wrong with your demo request.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Demo Request Failed",
        description: "Sorry, something went wrong with your demo request.",
      });
    }
  };

  const handleModalCancel = () => {
    requestDemo.resetFields();
  };

  return (
    <>
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li class="breadcrumb-item active">Blog Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section class=" mb-20 mt-20">
        <div class="container">
          <div
            class="cta-inner-wrap inner_cta"
            data-background="assets/img/bg/cta_bg.jpg"
          >
            <div class="row align-items-center">
              <div class="col-lg-9">
                <div class="cta-content">
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div class="content">
                      <span>Blog Author</span>
                      <span>Admin</span>
                    </div>
                  </div>
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div class="content">
                      <span> Director</span>
                      <span>Admin</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="content-container">
                  <div class="blog-content-span">
                    <span> Published Date :</span>
                    <span>22 Jan, 2023</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="blog-area pt-120 pb-120">
        <div class="container">
          <div class="inner-blog-wrap">
            <div class="row justify-content-center">
              <div className="col-71">
                <div className="blog-post-wrap">
                  <div className="row">
                    <div className="blog-details-header blog-widget">
                      <div className="blog-header-content">
                        <h4 className="bw-title">
                          How Fee Management Software Can Improve Your
                          Institution's Financial Health
                        </h4>
                        <img src={blog_1} alt="" />
                        <ul>
                          <h4>Table of Contents</h4>
                          <ol>
                            <li>
                              Introduction: Is Your Institution Struggling with
                              Fee Management?
                            </li>
                            <li>Streamlining Fee Clilection</li>
                            <li>Enhancing Financial Transparency</li>
                            <li>Improving Cash Flow Management</li>
                            <li>Ensuring Data Security</li>
                            <li>Case Study: Edpedia Success Story</li>
                            <li>FAQs</li>
                          </ol>
                        </ul>

                        <h5>
                          Introduction: Is Your Institution Struggling with Fee
                          Management?
                        </h5>
                        <p>
                          Are you tired of dealing with the complexities of fee
                          collection, the administrative burden, and the endless
                          paperwork? Managing fees can be a daunting task for
                          any educational institution. What if there was a way
                          to simplify this process and enhance your financial
                          health simultaneously? Enter fee management software.
                          In this blog, we'll explore how implementing fee
                          management software like Edpedia can revolutionize
                          your institution's financial operations.
                        </p>
                        <h4>
                          <strong>Streamlining Fee Collection</strong>
                        </h4>
                        <h6>Automated Billing and Invoicing</h6>
                        <p>
                          One of the most significant advantages of fee
                          management software is the automation of billing and
                          invoicing. By eliminating manual processes, your
                          institution can ensure timely and accurate fee
                          collection. Automated reminders and notifications can
                          be sent to parents and students, reducing the chances
                          of missed payments.
                        </p>
                        <h6>Multiple Payment Options</h6>
                        <p>
                          Fee management software offers multiple payment
                          options, including credit/debit cards, bank transfers,
                          and online payment gateways. This flexibility makes it
                          easier for parents to pay fees, leading to higher
                          collection rates and fewer defaults.
                        </p>
                        <h4>
                          <strong>Reducing Administrative Costs</strong>
                        </h4>
                        <h6>Minimizing Manual Errors</h6>
                        <p>
                          Manual fee collection is prone to errors, which can
                          lead to financial discrepancies and administrative
                          headaches. Fee management software reduces these
                          errors by automating calculations and record-keeping,
                          ensuring accuracy and consistency.
                        </p>
                        <h6>Saving Time and Resources</h6>
                        <p>
                          By automating routine tasks such as fee calculation,
                          receipt generation, and report creation, fee
                          management software frees up valuable administrative
                          time. This allows your staff to focus on more critical
                          tasks, enhancing overall productivity
                        </p>
                        <h4>
                          <strong>Enhancing Financial Transparency</strong>
                        </h4>
                        <h6>Real-Time Reporting</h6>
                        <p>
                          Fee management software provides real-time financial
                          reports, giving you a clear picture of your
                          institution's financial status at any moment. These
                          insights help in making informed decisions and
                          strategic planning.
                        </p>
                        <h6>Transparent Transactions</h6>
                        <p>
                          With fee management software, all transactions are
                          recorded and easily accessible. This transparency
                          builds trust with parents and stakeholders, as they
                          can see exactly where their money is going.
                        </p>
                        <h4>
                          <strong>Improving Cash Flow Management</strong>
                        </h4>
                        <h6>Timely Payments</h6>
                        <p>
                          Automated reminders and flexible payment options
                          ensure that fees are paid on time, improving your
                          institution's cash flow. Consistent cash flow is
                          crucial for meeting operational expenses and planning
                          for future investments.
                        </p>
                        <h6>Forecasting and Budgeting</h6>
                        <p>
                          Fee management software can generate detailed
                          financial forecasts and budgets based on historical
                          data. This helps in anticipating future financial
                          needs and avoiding unexpected shortfalls.
                        </p>
                        <h4>
                          <strong>Ensuring Data Security</strong>
                        </h4>
                        <h6>Secure Payment Gateways</h6>
                        <p>
                          Fee management software employs secure payment
                          gateways to protect sensitive financial information.
                          Encryption and other security measures ensure that
                          transactions are safe from cyber threats.
                        </p>
                        <h6>Compliance with Regulations</h6>
                        <p>
                          By using fee management software, your institution can
                          comply with financial regulations and standards,
                          reducing the risk of legal issues and penalties.
                        </p>
                        <h4>
                          <strong>Case Study: Edpedia Success Story</strong>
                        </h4>
                        <h6>
                          Edpedia: The Comprehensive School Management Software
                        </h6>
                        <p>
                          Edpedia is a comprehensive school management software
                          that integrates fee management with other critical
                          school operations. Schools that have implemented
                          Edpedia have reported significant improvements in
                          financial health, reduced administrative burdens, and
                          enhanced overall efficiency.
                        </p>
                        <h6>Real-Life Impact</h6>
                        <p>
                          For instance, a mid-sized private school that adopted
                          Edpedia saw a 25% increase in on-time fee payments and
                          a 30% reduction in administrative costs within the
                          first year. The software's real-time reporting
                          features enabled the school's administration to make
                          more informed financial decisions, contributing to
                          their overall growth and stability.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-29">
                <aside class="blog-sidebar">
                  <div class="blog-widget">
                    <h4 class="bw-title">Connect for free trial</h4>
                    <div class="bs-cat-list">
                      <Form
                        name="RequestDemo"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={requestDemo}
                      >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="emailId"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Email is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Email"
                                type="email"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone number is required",
                                },
                                {
                                  min: 10,
                                  message: "Please enter valid phone number",
                                },
                                {
                                  max: 10,
                                  message: "Cannot exceed more than 10",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Phone Number"
                                type="number"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="areaOfInterest"
                              rules={[
                                {
                                  required: true,
                                  message: "Area of intrest is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Area of interest"
                              >
                                <Option value="useInstitution">
                                  I want to use it for my Institution
                                </Option>
                                <Option value="resell">
                                  I want to resell it
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="schoolName"
                              rules={[
                                {
                                  required: true,
                                  message: "Institute name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Institute Name"
                              />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="preferredDemoDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Preferred demo date is required",
                                },
                              ]}
                            >
                              <DatePickerFloatLabel
                                className="datepicker"
                                label="Preferred Demo Date"
                                showTime
                                style={{ width: "100%", height: "40px" }}
                                format={dateWithTime}
                                disabledData={disabledStartDate}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: "Location is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Location"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="currentStatusOfERP"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Current status of your ERP is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Current ERP status"
                              >
                                <Option value="notInterested">
                                  Not yet, but I'm interested in learning more
                                </Option>
                                <Option value="freeTrial">
                                  Yes, I currently have a free trial account and
                                  wish to learn more
                                </Option>
                                <Option value="paidAccount">
                                  Yes, I currently have a paid account and wish
                                  to learn more
                                </Option>
                                <Option value="renewAccount">
                                  No, I want to renew my account
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>

                        <Row
                          className="my-row"
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col span={12} align="end">
                            <Space direction="vertical" size="large">
                              <Button
                                className="submit-button "
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                            </Space>
                          </Col>
                          <Col span={12}>
                            <Space direction="vertical" size="large">
                              <Button
                                className="cancel-button "
                                onClick={handleModalCancel}
                              >
                                Cancel
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </aside>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-8">
                <section class=" mb-20 mt-20">
                  <div class="container">
                    <div
                      class=" faq-bg"
                      data-background="assets/img/bg/cta_bg.jpg"
                    >
                      <div class="row align-items-center">
                        <h4>
                          <strong>FAQs</strong>
                        </h4>
                        <div className="faq-content">
                          <h5>
                            <strong>What is fee management software?</strong>
                          </h5>
                          <p>
                            Fee management software is a digital solution
                            designed to automate and streamline the process of
                            fee collection, invoicing, and financial reporting
                            for educational institutions.
                          </p>
                        </div>
                        <div className="faq-content">
                          {" "}
                          <h5>
                            <strong>
                              How does fee management software improve financial
                              health?
                            </strong>
                          </h5>
                          <p>
                            By automating billing, reducing errors, enhancing
                            transparency, and improving cash flow management,
                            fee management software helps institutions manage
                            their finances more effectively.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              Is Edpedia suitable for all types of educational
                              institutions?
                            </strong>
                          </h5>
                          <p>
                            Yes, Edpedia is designed to cater to the needs of
                            various educational institutions, including schools,
                            colleges, and universities. Its flexible features
                            can be customized to fit different requirements.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              How secure is fee management software?
                            </strong>
                          </h5>
                          <p>
                            Fee management software like Edpedia employs
                            advanced security measures, including encryption and
                            secure payment gateways, to protect sensitive
                            financial data and ensure compliance with
                            regulations.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              Can fee management software integrate with other
                              systems?
                            </strong>
                          </h5>
                          <p>
                            Yes, many fee management software solutions,
                            including Edpedia, can integrate seamlessly with
                            other school management systems, providing a unified
                            platform for managing all school operations. In
                            conclusion, fee management software is an
                            indispensable tool for modern educational
                            institutions. By implementing a robust solution like
                            Edpedia, your institution can enhance its financial
                            health, reduce administrative burdens, and improve
                            overall efficiency. Don't let fee management be a
                            hassle—embrace the future with Edpedia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntitialBlog;
