import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import about_img1 from "../assets/img/images/h4_about_img01.jpg";
import about_img2 from "../assets/img/images/h4_about_img02.jpg";
import about_shape from "../assets/img/images/h4_about_shape.png";
import feature_01 from "../assets/img/images/features_shape01.png";
import feature_02 from "../assets/img/images/features_shape02.png";
import { Link } from "react-router-dom";
import AOS from "aos";
const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button className="scroll-top scroll-to-target" data-target="html">
        <i className="fas fa-angle-up"></i>
      </button>
      <main className="fix">
        <section className="breadcrumb-area text-left">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="Breadcrumb Shape 1" />
            <img src={breadcrumb_shape2} alt="Breadcrumb Shape 2" />
          </div>
        </section>

        <section className="pt-50 pb-100">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="about-img-wrap-five about-img-wrap-ten">
                  <img src={about_img1} alt="About Us 1" />
                  <img
                    src={about_img2}
                    alt="About Us 2"
                    data-parallax='{"y" : 100 }'
                  />
                  <div className="experience-wrap">
                    <h2 className="title">
                      100%<span>Data Accuracy</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content-five about-content-ten">
                  <div className="section-title-two mb-30">
                    <span className="sub-title">Get To know US</span>
                    <h2 className="title">About Us</h2>
                  </div>
                  <p>
                    Are you tired of juggling multiple platforms and systems to
                    manage the daily operations of your educational institution?
                    Look no further! We are thrilled to present EdPedia, the
                    cutting-edge education management system ERP designed to
                    revolutionize the way schools, colleges, universities, and
                    all other educational institutes are run.
                  </p>
                  <p>
                    EdPedia is the comprehensive solution you've been waiting
                    for, seamlessly integrating all aspects of educational
                    administration into one powerful platform.
                  </p>
                  <p>
                    With its user-friendly interface and advanced features,
                    EdPedia empowers educational institutions to streamline
                    their processes, enhance efficiency, and provide a superior
                    learning experience for students.
                  </p>

                  <div className="about-success-wrap">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-family"></i>
                        </div>
                        <div className="content">
                          <h2 className="count">
                            <span
                              className="odometer odometer-auto-theme"
                              data-count="63"
                            >
                              <div className="odometer-inside">
                                <span className="odometer-digit">
                                  <span className="odometer-digit-spacer">
                                    8
                                  </span>
                                  <span className="odometer-digit-inner">
                                    <span className="odometer-ribbon">
                                      <span className="odometer-ribbon-inner">
                                        <span className="odometer-value">
                                          6
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span className="odometer-digit">
                                  <span className="odometer-digit-spacer">
                                    8
                                  </span>
                                  <span className="odometer-digit-inner">
                                    <span className="odometer-ribbon">
                                      <span className="odometer-ribbon-inner">
                                        <span className="odometer-value">
                                          3
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </span>
                            %
                          </h2>
                          <p>Our Partner</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-trophy"></i>
                        </div>
                        <div className="content">
                          <h2 className="count">
                            <span
                              className="odometer odometer-auto-theme"
                              data-count="95"
                            >
                              <div className="odometer-inside">
                                <span className="odometer-digit">
                                  <span className="odometer-digit-spacer">
                                    8
                                  </span>
                                  <span className="odometer-digit-inner">
                                    <span className="odometer-ribbon">
                                      <span className="odometer-ribbon-inner">
                                        <span className="odometer-value">
                                          9
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span className="odometer-digit">
                                  <span className="odometer-digit-spacer">
                                    8
                                  </span>
                                  <span className="odometer-digit-inner">
                                    <span className="odometer-ribbon">
                                      <span className="odometer-ribbon-inner">
                                        <span className="odometer-value">
                                          5
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </span>
                            %
                          </h2>
                          <p>Satisfied Award</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-shape-five">
            <img src={about_shape} alt="About Shape" />
          </div>
        </section>

        <section
          className="features-area-five features-bg"
          data-background="assets/img/bg/features_bg.jpg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6">
                <div className="section-title-two mb-30 text-center">
                  <span className="sub-title">Why EdPedia</span>
                  <h2 className="title">
                    Here's what stands out from the Crowd
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 className="title">Centralized Management</h2>
                    </div>
                    <p>
                      Bid farewell to the hassle of managing multiple software
                      solutions. EdPedia offers a centralized system that
                      combines all essential modules, including student
                      enrollment, academic management, attendance tracking,
                      grading, financial management, HR, and more. Say goodbye
                      to data silos and enjoy a unified approach to education
                      administration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-inspiration"></i>
                      </div>
                      <h2 className="title">Seamless Collaboration</h2>
                    </div>
                    <p>
                      EdPedia promotes seamless collaboration among teachers,
                      administrators, students, and parents. Through its
                      integrated communication tools, everyone involved in the
                      educational journey can easily share information, exchange
                      feedback, and stay connected, fostering a productive and
                      engaging learning environment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-profit"></i>
                      </div>
                      <h2 className="title">Enhanced Student Experience</h2>
                    </div>
                    <p>
                      EdPedia puts students at the forefront, providing a range
                      of features to support their academic journey. From online
                      registration and course selection to personalized learning
                      plans and progress tracking, EdPedia empowers students to
                      take ownership of their education while receiving the
                      guidance they need to succeed.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-profit"></i>
                      </div>
                      <h2 className="title">Data-Driven Insights</h2>
                    </div>
                    <p>
                      With EdPedia's robust analytics and reporting
                      capabilities, educational institutions gain valuable
                      insights into their operations and student performance. By
                      harnessing the power of data, institutions can make
                      informed decisions, identify areas for improvement, and
                      ultimately enhance their overall educational outcomes.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-profit"></i>
                      </div>
                      <h2 className="title">Scalable and Customizable</h2>
                    </div>
                    <p>
                      EdPedia understands that every educational institution is
                      unique. Our solution is highly scalable and customizable
                      to meet the specific needs and requirements of your
                      institution. Whether you're a small school or a large
                      university, EdPedia adapts to your size and grows with you
                      as your institution flourishes. Unlock the full potential
                      of your educational institution with EdPedia and embark on
                      a journey towards streamlined administration, improved
                      collaboration, and exceptional learning experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features-shape-wrap">
            <img src={feature_01} alt="Feature 1" />
            <img src={feature_02} alt="Feature 2" />
          </div>
        </section>
      </main>
    </>
  );
};

export default About;
