import React from "react";
import { Link, useParams } from "react-router-dom";
import { blogData } from "./Data";
import child1 from "../../assets/img/blog/rc_post01.jpg";
import child2 from "../../assets/img/blog/rc_post02.jpg";
import child3 from "../../assets/img/blog/rc_post03.jpg";
import child4 from "../../assets/img/blog/rc_post04.jpg";
import whatsapp from "../../assets/img/icons/whasapp.png";
import logo from "../../assets/img/images/ed.svg";
import blog_1 from "../../assets/img/fee.png";

import qrcode from "../../assets/img/qrcode.png";
import { faChessBoard, faStar } from "@fortawesome/free-solid-svg-icons";
import testimonial1 from "../../assets/img/images/testi_avatar01.png";
import testimonial2 from "../../assets/img/images/testi_avatar02.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import InputFloatLabel from "../../Common/FloatLabel/InputFloatLabel";
import SelectFloatLabel from "../../Common/FloatLabel/SelectFloatLabel";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePickerFloatLable";
import { Button, Col, Form, Row, Select, Space, notification } from "antd";
import "../../assets/css/Modal.css";
import dayjs from "dayjs";
import { useCreateDemoMutation } from "../../RTKQuery/RtkQuery";
import moment from "moment";

const WhatsappAlertBlog = () => {
  // api call
  const [requestDemo] = Form.useForm();
  const [createDemo, { isLoading }] = useCreateDemoMutation();
  const { Option } = Select;

  const onFinishFailed = (errorInfo) => {};
  const dateWithTime = (preferredDemoDate) => {
    return dayjs(preferredDemoDate).format("YYYY-MM-DD HH:mm:ss");
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onFinish = async (values) => {
    const { preferredDemoDate, ...restValues } = values;
    const formattedDateTime = dateWithTime(preferredDemoDate);

    const payload = {
      ...restValues,
      preferredDemoDate: formattedDateTime,
      schoolSize: "",
      roleAtSchool: "",
      additionalInformation: "",
    };
    try {
      const response = await createDemo(payload);
      if (response.data.status === 200) {
        requestDemo.resetFields();

        notification.success({
          message: "Demo Request Successful",
          description: "Your demo request has been successfully submitted.",
        });
      } else {
        notification.error({
          message: "Demo Request Failed",
          description: "Sorry, something went wrong with your demo request.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Demo Request Failed",
        description: "Sorry, something went wrong with your demo request.",
      });
    }
  };

  const handleModalCancel = () => {
    requestDemo.resetFields();
  };

  return (
    <>
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li class="breadcrumb-item active">Blog Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section class=" mb-20 mt-20">
        <div class="container">
          <div
            class="cta-inner-wrap inner_cta"
            data-background="assets/img/bg/cta_bg.jpg"
          >
            <div class="row align-items-center">
              <div class="col-lg-9">
                <div class="cta-content">
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div class="content">
                      <span>Blog Author</span>
                      <span> G V Riteesh Reddy</span>
                    </div>
                  </div>
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div class="content">
                      <span> Designation</span>
                      <span>Founding Member</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="content-container">
                  <div class="blog-content-span">
                    <span> Published Date :</span>
                    <span>10-06-2024</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="blog-area pt-120 pb-120">
        <div class="container">
          <div class="inner-blog-wrap">
            <div class="row justify-content-center">
              <div className="col-71">
                <div className="blog-post-wrap">
                  <div className="row">
                    <div className="blog-details-header blog-widget">
                      <div className="blog-header-content">
                        <h4 className="bw-title">
                          10 Benefits of Using WhatsApp Fee Alerts for School
                          Fee Management
                        </h4>
                        <img src={blog_1} alt="" />
                        <ul>
                          <h4>Table of Contents</h4>
                          <ol>
                            <li>
                              Introduction: Are Late Payments Affecting Your
                              School's Finances?
                            </li>
                            <li>Benefit 1: Instant Communication</li>
                            <li>Benefit 2: Increased On-Time Payments</li>
                            <li>Benefit 3: Reduced Administrative Burden</li>
                            <li>Benefit 4: Cost-Effective Solution</li>
                            <li>Benefit 5: Improved Parent Engagement</li>
                            <li>Benefit 6: Customizable Alerts</li>
                            <li>Benefit 7: Enhanced Data Security</li>
                            <li>Benefit 8: Easy Integration with Edpedia</li>
                            <li>Benefit 9: Real-Time Updates</li>
                            <li>Benefit 10: Environmental Sustainability</li>
                            <li>FAQs</li>
                          </ol>
                        </ul>
                        <h5>
                          Introduction: Are Late Payments Affecting Your
                          School's Finances?
                        </h5>
                        <p>
                          Is your school or college struggling with late fee
                          payments? Are you finding it hard to keep track of who
                          has paid and who hasn't? Traditional methods of fee
                          reminders, such as paper notices and phone calls, are
                          often inefficient and time-consuming. What if you
                          could streamline this process with a simple, effective
                          solution? Enter WhatsApp Fee Alerts. In this blog,
                          we'll explore the top 10 benefits of using WhatsApp
                          fee alerts for school fee management. And if you're
                          looking for a comprehensive school management system,
                          Edpedia has got you covered.
                        </p>
                        <h4>
                          <strong>Benefit 1: Instant Communication</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          WhatsApp provides instant communication, ensuring that
                          fee alerts reach parents and students in real-time.
                          This immediacy can significantly reduce delays in
                          payment.
                        </p>
                        <h6>Key Features</h6>
                        <p>
                          Instant message delivery Read receipts to confirm
                          receipt of messages
                        </p>
                        <h4>
                          <strong>Benefit 2: Increased On-Time Payments</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Regular, timely reminders about upcoming fee due dates
                          can encourage parents to pay on time, reducing the
                          occurrence of late payments.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>Automated reminders</li>

                          <li>Customizable schedules for alerts</li>
                        </ul>
                        <h4>
                          <strong>
                            Benefit 3: Reduced Administrative Burden
                          </strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Automating fee alerts with WhatsApp reduces the
                          workload for administrative staff, freeing them up to
                          focus on other important tasks.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>liAutomated message sending</li>{" "}
                          <li> Reduced need for manual follow-ups</li>
                        </ul>
                        <h5>Benefit 4: Cost-Effective Solution</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          Using WhatsApp for fee alerts is a cost-effective
                          alternative to traditional methods like postal mail
                          and phone calls, saving your institution money.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>No postal costs</li>
                          <li>Lower communication expenses</li>
                        </ul>

                        <h5>Benefit 5: Improved Parent Engagement</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          WhatsApp is a widely used platform, making it easier
                          for parents to receive and respond to fee alerts,
                          leading to better engagement and communication.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>High engagement rates</li>
                          <li>Convenient for parents</li>
                        </ul>

                        <h5>Benefit 6: Customizable Alerts</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          Customizable fee alerts allow schools to tailor
                          messages according to their needs, ensuring relevant
                          and specific information is communicated.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>Personalized messages</li>
                          <li>Adjustable notification settings</li>
                        </ul>

                        <h5>Benefit 7: Enhanced Data Security</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          WhatsApp uses end-to-end encryption, ensuring that
                          sensitive fee-related information is secure and only
                          accessible to the intended recipients.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>Secure message transmission</li>
                          <li>Compliance with data protection regulations</li>
                        </ul>

                        <h5>Benefit 8: Easy Integration with Edpedia</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          Edpedia, a comprehensive school management software,
                          seamlessly integrates with WhatsApp, enabling
                          efficient management of fee alerts and other
                          administrative tasks.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>Seamless integration</li>
                          <li>Centralized management system</li>
                        </ul>

                        <h5>Benefit 9: Real-Time Updates</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          Real-time updates on fee payments allow schools to
                          track transactions as they happen, ensuring accurate
                          and up-to-date records.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li></li>
                        </ul>

                        <h5>Benefit 10: Environmental Sustainability</h5>
                        <h6>Why It Matters</h6>
                        <p>
                          Using digital alerts reduces the need for paper,
                          contributing to environmental sustainability and
                          reducing the institution's carbon footprint.
                        </p>
                        <h6>Key Features</h6>
                        <ul>
                          <li>Paperless communication</li>
                          <li>Eco-friendly alternative</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-29">
                <aside class="blog-sidebar">
                  <div class="blog-widget">
                    <h4 class="bw-title">Connect for free trial</h4>
                    <div class="bs-cat-list">
                      <Form
                        name="RequestDemo"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={requestDemo}
                      >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="emailId"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Email is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Email"
                                type="email"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone number is required",
                                },
                                {
                                  min: 10,
                                  message: "Please enter valid phone number",
                                },
                                {
                                  max: 10,
                                  message: "Cannot exceed more than 10",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Phone Number"
                                type="number"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="areaOfInterest"
                              rules={[
                                {
                                  required: true,
                                  message: "Area of intrest is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Area of interest"
                              >
                                <Option value="useInstitution">
                                  I want to use it for my Institution
                                </Option>
                                <Option value="resell">
                                  I want to resell it
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="schoolName"
                              rules={[
                                {
                                  required: true,
                                  message: "Institute name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Institute Name"
                              />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="preferredDemoDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Preferred demo date is required",
                                },
                              ]}
                            >
                              <DatePickerFloatLabel
                                className="datepicker"
                                label="Preferred Demo Date"
                                showTime
                                style={{ width: "100%", height: "40px" }}
                                format={dateWithTime}
                                disabledData={disabledStartDate}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: "Location is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Location"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="currentStatusOfERP"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Current status of your ERP is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Current ERP status"
                              >
                                <Option value="notInterested">
                                  Not yet, but I'm interested in learning more
                                </Option>
                                <Option value="freeTrial">
                                  Yes, I currently have a free trial account and
                                  wish to learn more
                                </Option>
                                <Option value="paidAccount">
                                  Yes, I currently have a paid account and wish
                                  to learn more
                                </Option>
                                <Option value="renewAccount">
                                  No, I want to renew my account
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>

                        <Row
                          className="my-row"
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col span={12} align="end">
                            <Space direction="vertical" size="large">
                              <Button
                                className="submit-button "
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                            </Space>
                          </Col>
                          <Col span={12}>
                            <Space direction="vertical" size="large">
                              <Button
                                className="cancel-button "
                                onClick={handleModalCancel}
                              >
                                Cancel
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </aside>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-8">
                <section class="mb-20 mt-20">
                  <div class="container">
                    <div
                      class="faq-bg"
                      data-background="assets/img/bg/cta_bg.jpg"
                    >
                      <div class="row align-items-center">
                        <h4>
                          <strong>FAQs</strong>
                        </h4>
                        <div className="faq-content">
                          <h5>
                            <strong>What are WhatsApp fee alerts?</strong>
                          </h5>
                          <p>
                            WhatsApp fee alerts are automated notifications sent
                            via WhatsApp to inform parents and students about
                            upcoming fee due dates, payment confirmations, and
                            outstanding balances.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              How can WhatsApp fee alerts benefit schools and
                              colleges?
                            </strong>
                          </h5>
                          <p>
                            They ensure timely payments, reduce administrative
                            workload, improve communication, and offer a
                            cost-effective solution for fee management.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              Is Edpedia suitable for implementing WhatsApp fee
                              alerts?
                            </strong>
                          </h5>
                          <p>
                            Yes, Edpedia is a comprehensive school management
                            software that integrates WhatsApp fee alerts, making
                            it easy to manage and automate fee notifications.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>How secure are WhatsApp fee alerts?</strong>
                          </h5>
                          <p>
                            WhatsApp uses end-to-end encryption, ensuring that
                            the messages are secure and only accessible to the
                            intended recipients.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              Can Edpedia provide support for setting up
                              WhatsApp fee alerts?
                            </strong>
                          </h5>
                          <p>
                            Yes, Edpedia offers dedicated support and training
                            to help institutions set up and effectively use
                            WhatsApp fee alerts.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>In conclusion</strong>
                          </h5>
                          <p>
                            WhatsApp fee alerts offer numerous benefits for
                            school fee management, from improving on-time
                            payments to reducing administrative burdens. By
                            integrating these alerts with a comprehensive system
                            like Edpedia, educational institutions can
                            streamline their fee management processes and
                            enhance communication with parents. Explore Edpedia
                            today and revolutionize your school's fee
                            management!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatsappAlertBlog;
