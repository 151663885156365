import { Button, Col, Row, Space } from 'antd'
import React from 'react'

const FormButtons = ({
    saveText,
    cancelText,
    onCancel,
}) => {
    return (
        <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
                <Space direction="vertical" size="large">
                    <Button className="submit-button " htmlType="submit">
                        {saveText}
                    </Button>
                </Space>
            </Col>
            <Col span={12}>
                <Space direction="vertical" size="large">
                    <Button className="cancel-button " onClick={onCancel}>
                        {cancelText}
                    </Button>
                </Space>
            </Col>
        </Row>
    )
}

export default FormButtons