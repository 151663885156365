import React from "react";
import { Link } from "react-router-dom";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import shape from "../../assets/img/images/h4_about_shape.png";
import id from "../../assets/img/id.jpg";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";

const AttendanceManagement = () => {
  return (
    <>
      <main class="fix">
        <section class="breadcrumb-area text-left ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/features">Features</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Attendance Management
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area pt-50 pb-50">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <div class="about-img-wrap">
                  <img src={id} alt="" class="main-img" />
                  <img src={about_img_shape1} alt="" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="about-content">
                  <div class="section-title mb-25 tg-heading-subheading animation-style2">
                    <h2 class="title">Attendance Management</h2>
                  </div>
                  <p>
                    Dive deeper into the challenges institutions face with
                    attendance tracking and how EdPedia's Attendance Management
                    feature addresses them. Highlight the importance of accurate
                    attendance records for monitoring student progress and
                    compliance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="features-area-five features-bg multi-feature-bg"
          //   data-background="assets/img/bg/features_bg.jpg"
          //   style='background-image: url("assets/img/bg/features_bg.jpg");'
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="section-title-two mb-30 text-center">
                  <h3 class="title">
                    Key Aspects of Attendance Management Include
                  </h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">Automated attendance tracking</h2>
                    </div>
                    <p>
                      Explain how EdPedia uses AI algorithms or RFID technology
                      to automate attendance recording, reducing manual effort
                      and errors.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Branch-specific Data and Settings</h2>
                    </div>
                    <p>
                      Multi Branching Management allows you to define
                      branch-specific data and settings. You can configure
                      unique information for each branch, such as contact
                      details, address, operating hours, and branch-specific
                      policies or procedures.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Customizable attendance reports</h2>
                    </div>
                    <p>
                      Describe the flexibility of EdPedia in generating various
                      attendance reports tailored to the institution's needs,
                      including daily, weekly, or monthly summaries.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Integration with biometric devices</h2>
                    </div>
                    <p>
                      Discuss how EdPedia seamlessly integrates with biometric
                      attendance systems, ensuring secure and reliable
                      identification of students and staff.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Centralized Communication</h2>
                    </div>
                    <p>
                      Multi Branching Management facilitates seamless
                      communication and collaboration across branches. It
                      provides integrated communication channels such as
                      messaging systems, email notifications, and shared
                      calendars, allowing branch administrators and staff
                      members to communicate effectively and stay updated on
                      important information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src="assets/img/images/features_shape01.png" alt="" />
            <img src="assets/img/images/features_shape02.png" alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default AttendanceManagement;
