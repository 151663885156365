import { Col, Form, Modal, Row } from 'antd'
import React from 'react'
import InputFloatLabel from '../Common/FloatLabel/InputFloatLabel';
import FormButtons from '../Common/FormButtons';
import { SAVE_STORE_QUOTE, STORE_BASE_URL } from '../Common/utils/constants';
import axios from 'axios';
import { errorNotification, successNotification } from '../Common/utils/utils';
import { ERROR_MESSAGE, QUOTE_RESPONSE } from '../Common/utils/stringConstants';

const QuoteModal = ({
    quoteModal,
    setQuoteModal,
    item,
}) => {
    const [quoteForm] = Form.useForm();
    const handleCancel = () => {
        setQuoteModal(false);
    }

    const onFinish = values => {
        const payload = {
            ...values,
            itemName: item?.name,
            itemId: item?.id,
        };
        axios.post(STORE_BASE_URL + SAVE_STORE_QUOTE, payload)
        .then(response => {
                Modal.info({
                    title: QUOTE_RESPONSE,
                });
                // successNotification(QUOTE_RESPONSE);
            })
            .catch(error => {
                errorNotification(ERROR_MESSAGE);
            })

        handleCancel();
    }

  return (
    <Modal
        title="Get Quote"
        open={quoteModal}
        onCancel={handleCancel}
        footer={null}
    >
        <Form onFinish={onFinish} form={quoteForm} name="quoteForm">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="instAdminName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel className="float-inputs" label="Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="instName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Institute Name"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="instAdminEmail"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Email"
                  type="email"
                />
              </Form.Item>
            </Col>
            
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="instAdminNumber"
                rules={[
                  { required: true, message: "" },
                  {
                    len: 10,
                    message: "Invalid Number",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Mobile Number"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="studentsStrength"
                rules={[
                  { required: true, message: "" },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Students Strength"
                  type="number"
                />
              </Form.Item>
            </Col>
            </Row>
            <FormButtons 
                saveText="Get Quote"
                cancelText="Cancel"
                onCancel={handleCancel}
            />
        </Form>
    </Modal>
  )
}

export default QuoteModal