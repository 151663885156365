import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import contact from "../assets/img/images/contact_img.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Contact
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="inner-contact-area pt-50 pb-100">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="inner-contact-img">
                  <img src={contact} alt="" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="inner-contact-info">
                  <h2 class="title" data-aos="zoom-out">
                    Our Office Address
                  </h2>
                  <div class="contact-info-item">
                    {/* <!-- <h5 class="title-two">USA Office</h5> --> */}
                    <ul class="list-wrap">
                      <li>
                        {" "}
                        Plot No: 8-1-284/OU/623, OU Colony, Dream Valley Road,
                        Manikonda, Shaikpet, Hyderabd Telangana - 500008.
                      </li>
                      <li>+91 86607 26301</li>
                      <li>+91 7093929617</li>
                      <li>+91-7801064510</li>
                      <li>sales@edpedia.com</li>
                      <li>contact@eaglemountsoft.com</li>
                    </ul>
                  </div>
                  <div class="footer-social footer-social-two">
                    <ul class="list-wrap">
                      <li>
                        <Link to="/">
                          <i class="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i class="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i class="fab fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i class="fab fa-pinterest-p"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i class="fab fa-youtube"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- <div class="contact-info-item">
                        <h5 class="title-two">Australia Office</h5>
                        <ul class="list-wrap">
                            <li>100 Wilshire Blvd, Suite 700 Santa <br> Monica, CA 90401, USA</li>
                            <li>+1 (310) 620-8565</li>
                            <li>info@gmail.com</li>
                        </ul>
                    </div> --> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.2071547052874!2d78.38583596949546!3d17.401843465920955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb96a22ce78383%3A0x4d879985844dd153!2sDream%20Valley%20Rd%2C%20OU%20Colony%2C%20Shaikpet%2C%20Hyderabad%2C%20Pokalwada%2C%20Telangana%20500089!5e0!3m2!1sen!2sin!4v1715829146278!5m2!1sen!2sin"
            width="600"
            height="450"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </main>
    </>
  );
};

export default Contact;
