import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import overview_01 from "../assets/img/images/overview_img01.jpg";
import sb_insta from "../assets/img/images/sb_insta06.jpg";
import about_shape01 from "../assets/img/images/h2_about_shape01.png";

import about_shape02 from "../assets/img/images/h2_about_shape02.png";
import about_shape03 from "../assets/img/images/h2_about_shape03.png";
import inner_about_01 from "../assets/img/images/inner_about_img01.jpg";
import inner_about_02 from "../assets/img/images/inner_about_img02.jpg";
import inner_about_shape1 from "../assets/img/images/inner_about_shape01.png";
import services_shape_01 from "../assets/img/services/h6_services_shape01.png";
import services_shape_2 from "../assets/img/services/h6_services_shape02.png";
import inner_choose_img from "../assets/img/images/inner_choose_img.jpg";
import request from "../assets/img/images/h2_request_shape01.png";
import inner_about_shape from "../assets/img/images/inner_about_shape01.png";
import overview03 from "../assets/img/images/h3_overview_img01.jpg";
import abou_img_03 from "../assets/img/images/h8_about_img03.jpg";
import feature_01 from "../assets/img/images/features_shape01.png";
import feature_02 from "../assets/img/images/features_shape02.png";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

import AOS from "aos";

const ModuleFeature = () => {
  const { Panel } = Collapse;
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/modules">Modules</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Explore All Modules
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>
        <section class="about-area-three overview-area">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-9">
                <div class="about-img-wrap-three">
                  <img
                    src={overview_01}
                    alt=""
                    data-aos="fade-down-right"
                    data-aos-delay="0"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={sb_insta}
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay="100"
                    class="aos-init aos-animate"
                  />
                  <div
                    class="experience-wrap aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <h2 class="title">
                      5 <span>Years</span>
                    </h2>
                    <p>Of Experience in This ERP Software Company.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content-three">
                  <div class="section-title-two mb-20 tg-heading-subheading animation-style3">
                    <span class="sub-title">Explore All Modules</span>
                    <h2
                      class="title tg-element-title"
                      //   style="perspective: 400px;"
                    >
                      Ultimate School ERP Software
                    </h2>
                  </div>
                  <p class="info-one">
                    Edpedia School ERP Software provides user-friendly
                    dashboards with login access for teachers, non-teaching
                    staff, students, parents, and management personnel of your
                    institution. The various modules available in school ERP
                    facilitate all the processes of your institution, from the
                    admission of new students to generating transfer
                    certificates when students complete their studies.
                  </p>

                  <p class="info-one">
                    Edpedia School ERP has modules to manage Timetable,
                    Attendance, Examinations, Gradebooks, Edpedia News, Hostel,
                    Library, Transportation, School Calendar, Events, and many
                    more. It has a fully-fledged Human Resource module to manage
                    the payroll and employee pay slips. The Finance module helps
                    you to plan and allot different fee structures to students.
                    It is also an excellent collaboration tool using its Task,
                    Discussion, Poll, Blog, and Videoconference plugins.
                  </p>
                  <p>
                    There is an internal messaging system within Edpedia, but
                    you can also integrate it with external communication tools
                    like email and texting. To find out more school management
                    system, scroll through the following descriptions, try the
                    free demo or contact sales
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="about-shape-wrap-two">
            <img src={about_shape01} alt="" />
            <img src={about_shape02} alt="" />
            <img
              src={about_shape03}
              alt=""
              data-aos="fade-left"
              data-aos-delay="300"
              class="aos-init aos-animate"
            />
          </div>
        </section>
        <section class="services-area-twelve- fix  pt-50 pb-100 bg-light mt-30">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two text-center mb-50">
                  <h2 class="title"> Pre-admission Features</h2>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="about-img-seven-wrap">
                  <img
                    src={inner_about_01}
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay="0"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={inner_about_02}
                    alt=""
                    data-aos="fade-up"
                    data-aos-delay="300"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={inner_about_shape1}
                    alt=""
                    class="shape aos-init"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  />
                </div>
              </div>
              <div class="col-lg-8">
                <div class="row gutter-24">
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Paperless Admission</Link>
                        </h2>
                        <p>
                          Streamline your admission processes with our fully
                          integrated online admissions software. Edpedia
                          features can be customised to fit your admissions
                          process, providing a seamless experience for enrolling
                          students.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Online Application Forms
                          </Link>
                        </h2>
                        <p>
                          Our online admission forms make it easy and convenient
                          for prospective students to apply to your institution.
                          Track and review where a student is in the application
                          process and manage their journey with ease.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Online Fee Payment</Link>
                        </h2>
                        <p>
                          Provide students and parents the opportunity to pay
                          fees online, at their convenience. Using our secure
                          transaction platform is a quick and easy way to
                          process all fees.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Form Mapping to SIS</Link>
                        </h2>
                        <p>
                          Institutions no longer have to worry about gathering
                          and formatting student data. Students can update the
                          SIS by filling in their application form at the time
                          of enrollment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={services_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={services_shape_2}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>
        <section class="services-area-twelve- fix  pt-50 pb-50 mt-30">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two text-center mb-50">
                  <h2 class="title">CRM Features</h2>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-8">
                <div class="row gutter-24">
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Lead Management</Link>
                        </h2>
                        <p>
                          Identify students who are interested in the courses
                          offered by your institute. Edpedia helps you to manage
                          potential leads through online behavior patterns,
                          providing you with the insight you need to convert
                          these leads to admissions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Lead Nurturing</Link>
                        </h2>
                        <p>
                          Manage your admissions pipeline, log sales activities
                          and get insights that your admissions team will use.
                          Our integrated CRM helps you to get more enrolments
                          with less work making it easy to enrol students.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Followups</Link>
                        </h2>
                        <p>
                          Our integrated SMS and Email features help you to keep
                          in touch with prospective students. Edpedia makes
                          relationship management easy. Keep students up to date
                          with institution’s news, while they are making their
                          decision.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Sales Reporting</Link>
                        </h2>
                        <p>
                          Analyze sales and enquires by status, territory,
                          course or any custom fields. Use Edpedia reporting to
                          compare course registrations, capacity planning and
                          conversion rates of leads to enrolments.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Click to Call and Email
                          </Link>
                        </h2>
                        <p>
                          One click email, call or SMS prospects. Our integrated
                          CRM function enables your admissions staff to
                          proactively chase up potential leads with ease.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="about-img-seven-wrap">
                  <img
                    src={overview03}
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay="0"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={abou_img_03}
                    alt=""
                    data-aos="fade-up"
                    data-aos-delay="300"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={inner_about_shape1}
                    alt=""
                    class="shape aos-init"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={services_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={services_shape_2}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>
        <section class="choose-area-three">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="choose-content-three">
                  <div class="section-title-two white-title mb-30">
                    <h2 class="title">Student Information System Features</h2>
                  </div>

                  <div className="accordion-wrap-two">
                    <Collapse
                      accordion
                      className="accordion"
                      defaultActiveKey={["1"]}
                    >
                      <Panel
                        header="Course Management"
                        key="1"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Managing courses and class timetables becomes
                            effortless with our easy-to-use drag-and-drop
                            interface. Importing an existing course schedule is
                            simple with our import features.
                          </p>
                          <p>
                            Additionally, you can customize timetables to fit
                            the unique needs of your institution, assign
                            instructors to specific courses, and track room
                            availability to optimize space utilization.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Notice or Bulletin Boards"
                        key="2"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Get real-time updates on the latest Edpedia news and
                            updates. Manage separate bulletin boards for
                            students, teachers, and parents.
                          </p>
                          <p>
                            You can post important announcements, schedule
                            reminders, event notifications, and even share
                            multimedia content like images and videos to keep
                            everyone informed and engaged.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Reviews & Activity"
                        key="3"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Communication is key to a successful learning
                            environment. Use Edpedia to monitor meetings between
                            students, parents, and teachers. Ensure everyone is
                            in the loop and can contribute to a student’s
                            progress and wellbeing.
                          </p>
                          <p>
                            Track feedback from parents and students, schedule
                            regular progress meetings, and maintain records of
                            student activities and achievements to provide a
                            comprehensive view of their academic journey.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Health Records"
                        key="4"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Electronically store detailed records of routine
                            physical checkups for every student. Edpedia
                            automatically offers a behavior diagnosis based on
                            past data, suggesting tips to help keep your
                            students bright and healthy.
                          </p>
                          <p>
                            Access immunization records, monitor health trends,
                            and provide personalized health plans to ensure that
                            students receive the care and attention they need
                            for optimal physical and mental health.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Attendance"
                        key="5"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Reduce the time it takes to track class attendance.
                            Students can now scan customized ID cards to log and
                            track their attendance electronically for every
                            session.
                          </p>
                          <p>
                            Generate attendance reports, identify patterns of
                            absenteeism, and integrate attendance data with
                            academic performance to provide comprehensive
                            student insights.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Graduation & Alumni Management"
                        key="6"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Keep in touch with students even after they have
                            graduated. Keep alumni informed about school
                            activities and manage their records for future
                            reference with ease.
                          </p>
                          <p>
                            Organize alumni events, maintain a database of
                            graduates, and create networking opportunities that
                            benefit both current students and alumni.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Portals"
                        key="7"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Give everyone in your institution their own
                            dashboard to manage personal and academic
                            information. Students, parents, teachers, and
                            administrators can manage all their needs with
                            Edpedia.
                          </p>
                          <p>
                            Customize portals to include academic records,
                            financial information, communication tools, and more
                            to provide a seamless and user-friendly experience
                            for all users.
                          </p>
                        </div>
                      </Panel>
                      <Panel
                        header="Academic Progress"
                        key="8"
                        className="accordion-buttons"
                      >
                        <div className="accordion-body">
                          <p>
                            Track the academic progress of every student for the
                            duration of their time at your institution.
                            Administrators can easily verify student details to
                            ensure they meet the required qualifications for any
                            degree they want to pursue.
                          </p>
                          <p>
                            Provide detailed progress reports, set academic
                            goals, and offer tailored support to help each
                            student achieve their full potential.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="choose-img-three">
                  <img src={inner_choose_img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="services-area-twelve- fix  pt-50 pb-50 mt-30">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two text-center mb-50">
                  <h2 class="title">Finance & Accounting</h2>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="about-img-seven-wrap">
                  <img
                    src={overview_01}
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay="0"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={inner_about_02}
                    alt=""
                    data-aos="fade-up"
                    data-aos-delay="300"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={inner_about_shape}
                    alt=""
                    class="shape aos-init aos-animate"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  />
                </div>
              </div>
              <div class="col-lg-8">
                <div class="row gutter-24">
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Fees Collection</Link>
                        </h2>
                        <p>
                          Keep track of all transactions, admissions fees,
                          library fees and any other payments. Students and
                          parents can easily see records of transactions they’ve
                          made to the institute, all tracked in one simple
                          place.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Online Payment Options
                          </Link>
                        </h2>
                        <p>
                          Remove the burden of waiting to pay fees upfront in
                          person. Edpedia’s secure transaction platform allows
                          students to pay from the comfort of their homes. An
                          essential feature of any modern school management
                          software.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Balance Sheets PNL Statement
                          </Link>
                        </h2>
                        <p>
                          Let Edpedia manage your financial statements and
                          crunch the numbers to provide detailed statistics on
                          your institution’s economic standing, such as Balance
                          Sheets and Profit and Loss Statements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Grants & Scholarship Management
                          </Link>
                        </h2>
                        <p>
                          Financial assistance is a key deciding factor for many
                          students reviewing prospective universities and
                          colleges. Easily manage grants and scholarships
                          offered by your government, institution or non-profit
                          organisations with Edpedia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Donor and Donations Management
                          </Link>
                        </h2>
                        <p>
                          Any donation, big or small, is an asset for a thriving
                          Edpedia community. Let Edpedia keep tabs on all
                          benevolent donors and their contributions that made
                          your institution what it is today.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={services_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={services_shape_2}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>
        <section
          class="features-area-five features-bg feature_bg_container"
          data-background="assets/img/bg/features_bg.jpg"
          //   style='background-image: url("assets/img/bg/features_bg.jpg");'
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6">
                <div class="section-title text-center mb-50 mt-30">
                  <h2 class="title">Analytics</h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">Student & Behaviour Analytics</h2>
                    </div>
                    <p>
                      How Happy And Healthy Are Your Students? Are They Getting
                      Along With Their Peers? Edpedia Displays Aggregate
                      Behavioral Patterns And Suggests Appropriate Actions To
                      Take In Case Of Anomalous Behavior.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Institution Analytics</h2>
                    </div>
                    <p>
                      Instantly Crunch The Numbers On Your School’s Academic
                      Performance, Holistically And At A Class Level. Easily
                      Compare Our Interactive Graphs And Charts To Old
                      Statistics And Get A Sense Of Where Your School Currently
                      Stands.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Advanced Reporting</h2>
                    </div>
                    <p>
                      Take Your Institution's Reporting Further And Observe
                      Trends In Academic Performance, Behavior And Health
                      Analytics For Every Student In Your Institution. We Help
                      To Make Sure No Student Is Left Behind With The Addition
                      Of This Student Management System Module.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src={feature_01} alt="" />
            <img src={feature_02} alt="" />
          </div>
        </section>
        <section class="services-area-twelve- fix  pt-50 pb-100 bg-blue">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two white-title text-center mb-50">
                  <h2 class="title tg-element-title">
                    Learning Management System
                  </h2>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="row gutter-24">
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Blended Learning</Link>
                        </h2>
                        <p>
                          Many features within Edpedia’s school management
                          software are designed to let students dictate the pace
                          of lectures in class. At Edpedia, we integrate
                          technology into the classroom to convert your
                          teacher-centric education system into a
                          student-centric one.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Flip Class</Link>
                        </h2>
                        <p>
                          Edpedia acknowledge that every student is unique. We
                          endorse an innovative method of learning in the
                          Flipped Classroom that allows students to study at a
                          pace that suits them.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Discussions</Link>
                        </h2>
                        <p>
                          Edpedia provides a social platform to enhance and
                          encourage teacher to student and student to student
                          interaction. Students can easily form study groups to
                          engage in active conversations, debates or group
                          problem-solving sessions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Online Quizzes and Tests
                          </Link>
                        </h2>
                        <p>
                          Edpedia gives teachers the power to create online
                          quizzes and tests with varying question types like
                          Multiple Choice, Open Ended or fill in the blanks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Live Lectures</Link>
                        </h2>
                        <p>
                          With Edpedia, your home can be your classroom! Take
                          live classes on the go and help students clearing
                          their doubts instantly and efficiently.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Immersive Lecture Experience
                          </Link>
                        </h2>
                        <p>
                          Upload all kinds of study material. We will take care
                          of the rest! Edpedia provides inline browser views of
                          PDF, audio, video, presentation and word files for an
                          immersive lecture experience.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Portals</Link>
                        </h2>
                        <p>
                          Give everyone in your institution their own dashboard
                          to manage personal and academic information. Students,
                          parents, teachers and administrators can manage all
                          their needs with Edpedia.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Academic Progress</Link>
                        </h2>
                        <p>
                          Track the academic progress of every student for the
                          duration of their time at your institution.
                          Administrators can easily verify student details to
                          ensure they meet the required qualifications for any
                          degree they want to pursue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={services_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={services_shape_2}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>
        <section class="services-area-twelve- fix  pt-50 pb-50  bg-light">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two text-center mb-50">
                  <h2 class="title">Other Add-ons</h2>
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="row gutter-24 justify-content-center">
                  <div class="col-md-4">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Alumni management</Link>
                        </h2>
                        <p>
                          Make sure your relationship with students continues
                          past graduation. Save time by managing your alumni
                          relations within Edpedia. Keep past students up to
                          date with updates and news, and easily manage their
                          records for the future.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">E-commerce</Link>
                        </h2>
                        <p>
                          Build your own online store and sell back-to- school
                          items, school T-shirts, sportswear and much more!
                          Manage orders, stock, offer discounts and keep track
                          of sales with our integrated online E-Commerce
                          platform.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">Edpedia recruitment</Link>
                        </h2>
                        <p>
                          {" "}
                          When hundreds of companies come over to recruit your
                          brightest students, it is important to help them
                          manage student job applications. Edpedia’s provides a
                          platform that promotes student to industry
                          interaction.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">White label</Link>
                        </h2>
                        <p>
                          {" "}
                          We give you custom URLs to manage the Edpedia
                          institution setup. No watermarks, no legal issues. You
                          own the platform, yet don’t need to go through the
                          hassle of maintaining it!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="services-item-eight">
                      <div class="services-content-eight">
                        <h2 class="title">
                          <Link to="/module-details">
                            Biometric Integration System
                          </Link>
                        </h2>
                        <p>
                          {" "}
                          Monitor students and staff attendance with minimal
                          effort. Biometric/RFID Attendance System Integration
                          in Edpedia School ERP Software
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={services_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={services_shape_2}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default ModuleFeature;
