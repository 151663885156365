import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import about1 from "../../assets/img/images/about_img01.png";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import { Link } from "react-router-dom";
const Multibranchmanagement = () => {
  return (
    <>
      {" "}
      <main class="fix">
        <section class="breadcrumb-area text-left ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/features">Features</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Multi Branch Management
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area pt-50 pb-50">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <div class="about-img-wrap">
                  <img src={about1} alt="" class="main-img" />
                  <img src={about_img_shape1} alt="" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="about-content">
                  <div class="section-title mb-25 tg-heading-subheading animation-style2">
                    <h2 class="title">Multi Branch Management</h2>
                  </div>
                  <p>
                    Multi Branching Management is a comprehensive feature that
                    allows you to efficiently manage multiple branches or
                    locations of your institution or organization from a
                    centralized system. This feature provides you with the tools
                    and functionalities to streamline operations, optimize
                    communication, and ensure smooth coordination across all
                    branches.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="features-area-five features-bg multi-feature-bg"
          //   data-background="assets/img/bg/features_bg.jpg"
          //   style='background-image: url("assets/img/bg/features_bg.jpg");'
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="section-title-two mb-30 text-center">
                  <h3 class="title">
                    Key Aspects of Multi Branching Management Include
                  </h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">Centralized Management</h2>
                    </div>
                    <p>
                      In version control systems like Git, branches are separate
                      snapshots of the codebase that allow for isolated
                      development. With multi-branching management, different
                      branches are created to work on different features, bug
                      fixes, or experiments.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Branch-specific Data and Settings</h2>
                    </div>
                    <p>
                      Multi Branching Management allows you to define
                      branch-specific data and settings. You can configure
                      unique information for each branch, such as contact
                      details, address, operating hours, and branch-specific
                      policies or procedures.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">User Access Control</h2>
                    </div>
                    <p>
                      The feature includes user access control mechanisms that
                      allow you to assign different levels of permissions and
                      access rights to staff members based on their roles and
                      responsibilities. This ensures that staff members can only
                      access and modify information relevant to their assigned
                      branches.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Data-Driven Insights</h2>
                    </div>
                    <p>
                      With EdPedia's robust analytics and reporting
                      capabilities, educational institutions gain valuable
                      insights into their operations and student performance. By
                      harnessing the power of data, institutions can make
                      informed decisions, identify areas for improvement, and
                      ultimately enhance their overall educational outcomes.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Centralized Communication</h2>
                    </div>
                    <p>
                      Multi Branching Management facilitates seamless
                      communication and collaboration across branches. It
                      provides integrated communication channels such as
                      messaging systems, email notifications, and shared
                      calendars, allowing branch administrators and staff
                      members to communicate effectively and stay updated on
                      important information.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">
                        Data Synchronization and Integration
                      </h2>
                    </div>
                    <p>
                      The feature ensures that data across branches remains
                      synchronized and up to date. It allows for seamless
                      integration with other modules or systems, such as student
                      information systems, HR systems, or financial management
                      tools, ensuring consistency and accuracy of data across
                      all branches.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Reporting and Analytics</h2>
                    </div>
                    <p>
                      Multi Branching Management provides robust reporting and
                      analytics capabilities. You can generate customized
                      reports and analytics dashboards to gain insights into
                      branch performance, compare key metrics, identify trends,
                      and make data-driven decisions to improve overall
                      efficiency and effectiveness.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Resource Allocation and Management</h2>
                    </div>
                    <p>
                      The feature facilitates efficient resource allocation and
                      management across branches. You can manage and track
                      resources such as staff members, classrooms, equipment,
                      and inventory, ensuring optimal utilization and avoiding
                      resource conflicts or shortages.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Streamlined Workflows and Processes</h2>
                    </div>
                    <p>
                      The feature enables the standardization and automation of
                      workflows and processes across branches. You can define
                      and implement consistent procedures, approvals, and
                      workflows, reducing manual effort and ensuring compliance
                      with organizational policies and guidelines.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Scalability and Expansion</h2>
                    </div>
                    <p>
                      Multi Branching Management is designed to accommodate
                      organizational growth and scalability. It supports the
                      addition of new branches or locations without disrupting
                      existing operations, allowing for seamless expansion and
                      integration of new branches into the centralized
                      management system.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src="assets/img/images/features_shape01.png" alt="" />
            <img src="assets/img/images/features_shape02.png" alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default Multibranchmanagement;
