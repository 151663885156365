import React, { useEffect } from "react";
import edpedia10 from "..//assets/img/icons/edpedia10.png";
import request1 from "../assets/img/images/h2_request_shape01.png";
import request2 from "../assets/img/images/h2_request_shape02.png";
import logo from "../assets/img/logo/logo.png";
import check_icon from "../assets/img/icons/check_icon02.svg";
import qrcode from "../assets/img/qrcode.png";
import AOS from "aos";
import { Link } from "react-router-dom";
const Footer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const getDate = new Date().getFullYear();

  return (
    <footer>
      <section class="request-area-two">
        <div className="container container-partner">
          <div className="row align-items-center gx-10">
            <div className="col-lg-5">
              <div className="request-content-two">
                <div className="section-title-two white-title mb-15 tg-heading-subheading animation-style3">
                  <h2
                    className="title tg-element-title"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="300"
                  >
                    Become a Partner
                  </h2>
                </div>
                <p>
                  Ever find yourself staring at your computer screen a good
                  consulting slogan to come to mind? Oftentimes.
                </p>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="request-form-wrap">
                <div className="row ">
                  <div className="col-lg-6">
                    <img src={qrcode} alt="QR Code" />
                  </div>
                  <div className="col-lg-6 text-div">
                    <h1 className="contact-text">
                      Scan the QR code to connect with us directly
                    </h1>
                    <h2 className="contact-break">OR</h2>
                    <span className="become-text">
                      Connect with below WhatsApp number
                    </span>
                    <span className="become-number">+917801064510</span>
                    <span className="become-text">
                      Connect with below WhatsApp number
                    </span>
                    <span className="become-number">+91 7093929617</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="request-shape-wrap">
          <img src={request1} alt="" />
          <img
            src={request2}
            alt=""
            data-aos="fade-left"
            data-aos-delay="200"
          />
        </div>
      </section>

      {/* //footer */}
      <div
        className="footer-area-two footer-bg-two"
        data-background="assets/img/bg/h2_footer_bg.jpg"
      >
        <div className="footer-top-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <ul className="list-wrap">
                      <li className="header-contact-two mb-10">
                        <div className="icon pr-15">
                          <img src={edpedia10} alt="" width="50" />
                        </div>
                        <div className="content mt-15">
                          <h5 className="m-0">Dedicated Mobile App for</h5>
                          <p>Parents | Staff | Transport</p>
                        </div>
                      </li>
                    </ul>
                    <div className="footer-info">
                      <ul className="list-wrap">
                        <li>
                          <div className="icon">
                            <i className="flaticon-phone-call"></i>
                          </div>
                          <div className="content">
                            <Link to="/contact"> +917801064510</Link>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="flaticon-location"></i>
                          </div>
                          <div className="content">
                            <p>
                              Plot No: 8-1-284/OU/623, OU Colony, Dream Valley
                              Road, Manikonda, Shaikpet, Hyderabd Telangana -
                              500008.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Menu</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/modules">Modules</Link>
                      </li>
                      <li>
                        <Link to="/features">Features</Link>
                      </li>
                      <li>
                        <Link to="/benefits">Benefits</Link>
                      </li>

                      <li>
                        <Link to="/prices">Prices</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/terms&conditions">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>

                      <li>
                        <Link to="/blogs">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Our Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Sign up to Privitar’s weekly newsletter to get the latest
                      updates.
                    </p>
                    <form action="#">
                      <input type="email" placeholder="enter your e-mail" />
                      <button type="submit">Subscribe</button>
                    </form>
                    <div className="footer-social footer-social-two">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://www.facebook.com/people/Edpedia/61553421634862/?mibextid=LQQJ4d">
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>

                        <li>
                          <Link to="https://www.instagram.com/edpedia.co/">
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/company/edpedia-co">
                            <i class="fa-brands fa-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.youtube.com/@EdPedia-lb2wh/featured">
                            <i className="fab fa-youtube"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text-two text-center">
                  <p>Copyright ©2022-{getDate} edpedia | All Right Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
