// Preloader.js
import React from "react";

const Preloader = () => (
  <div id="preloader">
    <div id="loading-center">
      <div className="loader">
        <div className="loader-outter"></div>
        <div className="loader-inner"></div>
      </div>
    </div>
  </div>
);

export default Preloader;
