import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  Form,
  Space,
  notification,
  Row,
  Col,
  Modal,
  Image,
} from "antd";
import { useCreateDemoMutation } from "../RTKQuery/RtkQuery";
import congrates from "../assets/img/Congratulations.png";
import InputFloatLabel from "../Common/FloatLabel/InputFloatLabel";
import SelectFloatLabel from "../Common/FloatLabel/SelectFloatLabel";
import DatePickerFloatLabel from "../Common/FloatLabel/DatePickerFloatLable";
import "../assets/css/Modal.css";
import dayjs from "dayjs";
import { Select } from "antd";
const { Option } = Select;

const RequestDemo = ({ isModalVisible, setIsModalVisible }) => {
  const [requestDemo] = Form.useForm();
  const [createDemo, { isLoading }] = useCreateDemoMutation();

  const onFinishFailed = (errorInfo) => {};
  const dateWithTime = (preferredDemoDate) => {
    return dayjs(preferredDemoDate).format("YYYY-MM-DD HH:mm:ss");
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onFinish = async (values) => {
    const { preferredDemoDate, ...restValues } = values;
    const formattedDateTime = dateWithTime(preferredDemoDate);

    const payload = {
      ...restValues,
      preferredDemoDate: formattedDateTime,
      schoolSize: "",
      roleAtSchool: "",
      additionalInformation: "",
    };
    try {
      const response = await createDemo(payload);
      if (response.data.status === 200) {
        requestDemo.resetFields();
        // Show success notification
        notification.success({
          message: "Demo Request Successful",
          description: "Your demo request has been successfully submitted.",
        });
      } else {
        // Show error notification
        notification.error({
          message: "Demo Request Failed",
          description: "Sorry, something went wrong with your demo request.",
        });
      }
    } catch (error) {
      // Show error notification
      notification.error({
        message: "Demo Request Failed",
        description: "Sorry, something went wrong with your demo request.",
      });
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    requestDemo.resetFields();
  };

  return (
    <>
      <Modal
        title="Start Free Trail"
        open={isModalVisible}
        width={900}
        onCancel={handleModalCancel}
        centered
        footer={null}
        bodyStyle={{ maxHeight: "600px", overflowY: "auto", overflowX: "clip" }}
        className="addstudent-popup"
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={congrates}
            style={{ height: 430, width: 800 }}
            loading="lazy"
            alt="image"
          />
        </div>
        <Form
          name="RequestDemo"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={requestDemo}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <InputFloatLabel className="float-inputs" label="Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="emailId"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Email is required",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Email"
                  type="email"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="phoneNumber"
                rules={[
                  { required: true, message: "Phone number is required" },
                  {
                    min: 10,
                    message: "Please enter valid phone number",
                  },
                  {
                    max: 10,
                    message: "Cannot exceed more than 10",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Phone Number"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="areaOfInterest"
                rules={[
                  {
                    required: true,
                    message: "Area of intrest is required",
                  },
                ]}
              >
                <SelectFloatLabel
                  className="float-inputs"
                  label="Area of interest"
                >
                  <Option value="useInstitution">
                    I want to use it for my Institution
                  </Option>
                  <Option value="resell">I want to resell it</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="schoolName"
                rules={[
                  {
                    required: true,
                    message: "Institute name is required",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Institute Name"
                />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={12}>
              <Form.Item
                name="schoolType"
                rules={[
                  {
                    required: true,
                    message: "Institute type is required",
                  },
                ]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Institute Type"
                />
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="preferredDemoDate"
                rules={[
                  {
                    required: true,
                    message: "Preferred demo date is required",
                  },
                ]}
              >
                <DatePickerFloatLabel
                  className="datepicker"
                  label="Preferred Demo Date"
                  showTime
                  style={{ width: "100%", height: "40px" }}
                  format={dateWithTime}
                  disabledData={disabledStartDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="location"
                rules={[{ required: true, message: "Location is required" }]}
              >
                <InputFloatLabel className="float-inputs" label="Location" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="currentStatusOfERP"
                rules={[
                  {
                    required: true,
                    message: "Current status of your ERP is required",
                  },
                ]}
              >
                <SelectFloatLabel
                  className="float-inputs"
                  label="Current ERP status"
                >
                  <Option value="notInterested">
                    Not yet, but I'm interested in learning more
                  </Option>
                  <Option value="freeTrial">
                    Yes, I currently have a free trial account and wish to learn
                    more
                  </Option>
                  <Option value="paidAccount">
                    Yes, I currently have a paid account and wish to learn more
                  </Option>
                  <Option value="renewAccount">
                    No, I want to renew my account
                  </Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={12}>
              <Form.Item
                name="country"
                rules={[{ required: true, message: "Country is required" }]}
              >
                <InputFloatLabel className="float-inputs" label="Country" />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {/* <Col className="gutter-row" span={12}>
              <Form.Item
                name="currentStatusOfERP"
                rules={[
                  {
                    required: true,
                    message: "Current status of your ERP is required",
                  },
                ]}
              >
                <SelectFloatLabel
                  className="float-inputs"
                  label="Current ERP status"
                >
                  <Option value="notInterested">
                    Not yet, but I'm interested in learning more
                  </Option>
                  <Option value="freeTrial">
                    Yes, I currently have a free trial account and wish to learn
                    more
                  </Option>
                  <Option value="paidAccount">
                    Yes, I currently have a paid account and wish to learn more
                  </Option>
                  <Option value="renewAccount">
                    No, I want to renew my account
                  </Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col> */}
            {/* <Col className="gutter-row" span={12}>
              <Form.Item
                name="preferredDemoDate"
                rules={[
                  {
                    required: true,
                    message: "Preferred demo date is required",
                  },
                ]}
              >
                <DatePickerFloatLabel
                  className="datepicker"
                  label="Preferred Demo Date"
                  showTime
                  style={{ width: "100%", height: "40px" }}
                  format={dateWithTime}
                  disabledData={disabledStartDate}
                />
              </Form.Item>
            </Col> */}
          </Row>

          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="cancel-button " onClick={handleModalCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RequestDemo;
