export const data = [
  {
    id: 1,
    planName: "Basic",
    subscriptionPlanFeatures: [
      { featureId: 1, featureName: "AI Generated Study Material" },
      { featureId: 2, featureName: "Departments" },
      { featureId: 3, featureName: "Students Management" },
      { featureId: 4, featureName: "Fee Collection Reports" },
    ],
    durationAmountMap: {
      MONTH: 2499.0,
      YEARLY: 29999.0,
    },
    status: "Active",
  },
  {
    id: 2,
    planName: "Standard",
    subscriptionPlanFeatures: [
      { featureId: 5, featureName: "Fee Collection Reports" },
      { featureId: 6, featureName: "Policies" },
      { featureId: 7, featureName: "Send Enquiry Forms" },
      { featureId: 8, featureName: "Dashboard" },
    ],
    durationAmountMap: {
      MONTH: 3999.0,
      YEARLY: 47999.0,
    },
    status: "Active",
  },
  {
    id: 3,
    planName: "Enterprise",
    subscriptionPlanFeatures: [
      { featureId: 9, featureName: "AI Generated Study Material" },
      { featureId: 10, featureName: "Students Management" },
      { featureId: 11, featureName: "Fee Collection Reports" },
      { featureId: 12, featureName: "Departments" },
    ],
    durationAmountMap: {
      MONTH: 5999.0,
      YEARLY: 71999.0,
    },
    status: "Active",
  },
];
