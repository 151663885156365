import { SUPPORT } from "../Common/utils/stringConstants";

const StoreItem = ({
    item,
    setQuoteModal,
    setSelectedItem,
}) => {
    const handleQuote = () => {
        setQuoteModal(true);
        setSelectedItem(item);
    }
  return (
    <div className={`modal-store-item-container ${item?.isHighlighted ? "highligh-modal-store-item" : ""}`}>
        <img src={item?.src} className="modal-store-item-image"/>
        <h1 className="modal-store-item-name">{item?.name}</h1>
        <p className="modal-store-item-description">{item?.description}</p>
        {item?.name !== SUPPORT && <span onClick={handleQuote} className="quote-text">Get Quote</span>}
    </div>
  )
}

export default StoreItem