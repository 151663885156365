import React, { useEffect } from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import blog_details_1 from "../../assets/img/img2.webp";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import avatar1 from "../../assets/img/blog/blog_avatar01.png";
import post1 from "../../assets/img/img1.jpg";
import post2 from "../../assets/img/img2.webp";
import post3 from "../../assets/img/img1.jpg";
import post4 from "../../assets/img/img3.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";

const ExamManagement = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {" "}
      <main class="fix">
        <section class="breadcrumb-area text-left ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/features">Features</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Exam Management
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area pt-50 pb-50">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <div class="about-img-wrap">
                  <img src={blog_details_1} alt="" class="main-img" />
                  <img src={about_img_shape1} alt="" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="about-content">
                  <div class="section-title mb-25 tg-heading-subheading animation-style2">
                    <h2 class="title">Exam Management</h2>
                  </div>
                  <p>
                    Exam Management is a crucial component of any educational
                    institution's operations, encompassing the creation,
                    administration, and evaluation of exams. EdPedia's Exam
                    Management feature offers a comprehensive solution to
                    streamline these processes and enhance the overall exam
                    experience for administrators, teachers, and students.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="features-area-five features-bg multi-feature-bg"
          //   data-background="assets/img/bg/features_bg.jpg"
          //   style='background-image: url("assets/img/bg/features_bg.jpg");'
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="section-title-two mb-30 text-center">
                  <h3 class="title">Key Aspects of Exam Management Include</h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">
                        Online Exam Creation and Scheduling:
                      </h2>
                    </div>
                    <p>
                      EdPedia simplifies the process of creating and scheduling
                      exams by providing a user-friendly interface where
                      administrators can easily design exams with various
                      question types (e.g., multiple-choice, short answer,
                      essay) and set parameters such as time limits and
                      availability.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Secure Exam Administration</h2>
                    </div>
                    <p>
                      With EdPedia, institutions can ensure the integrity and
                      security of exams through features such as randomized
                      question pools, timer controls, and lockdown browser
                      functionality. This minimizes the risk of cheating and
                      ensures a fair testing environment for all students.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Instant Grading and Feedback</h2>
                    </div>
                    <p>
                      EdPedia automates the grading process, allowing for
                      immediate feedback and results distribution to students
                      upon exam completion. This not only saves time for
                      teachers but also enables students to receive timely
                      feedback on their performance, facilitating learning and
                      improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src="assets/img/images/features_shape01.png" alt="" />
            <img src="assets/img/images/features_shape02.png" alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default ExamManagement;
