import React from "react";
import { Link } from "react-router-dom";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import shape from "../../assets/img/images/h4_about_shape.png";
import feature_01 from "../../assets/img/images/features_shape01.png";
import about_01 from "../../assets/img/images/student_group.jpg";
import about_02 from "../../assets/img/images/h2_about_img02.jpg";
import feature_02 from "../../assets/img/images/features_shape02.png";
import about_shape01 from "../../assets/img/images/h2_about_shape01.png";
import about_shape02 from "../../assets/img/images/h2_about_shape02.png";
import about_shape03 from "../../assets/img/images/h2_about_shape03.png";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import CountUp from "react-countup";
const StudentManagement = () => {
  return (
    <main class="fix">
      <section class="breadcrumb-area text-left ">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/features">Features</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Student Management
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section class="about-area pt-50 pb-50">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="about-img-wrap">
                <img src={about_01} alt="" class="main-img" />
                <img src={about_img_shape1} alt="" />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="about-content">
                <div class="section-title mb-25 tg-heading-subheading animation-style2">
                  <h2 class="title">Student Management</h2>
                </div>
                <p>
                  Student Management is a fundamental aspect of educational
                  institutions, involving the administration, tracking, and
                  support of students throughout their academic journey.
                  EdPedia's Student Management feature offers a comprehensive
                  solution to streamline these processes and enhance the overall
                  student experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="features-area-five features-bg multi-feature-bg"
        //   data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <div class="section-title-two mb-30 text-center">
                <h3 class="title">Key Aspects of Student Management Include</h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">Student Enrollment and Registration</h2>
                  </div>
                  <p>
                    EdPedia simplifies the student enrollment and registration
                    process by providing an online platform where prospective
                    students can submit their applications, upload required
                    documents, and complete registration forms. This feature
                    enhances accessibility and reduces administrative burden.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">Student Profile and Academic Records</h2>
                  </div>
                  <p>
                    With EdPedia's student profile feature, administrators can
                    maintain comprehensive records of each student, including
                    personal information, contact details, academic transcripts,
                    attendance records, and disciplinary history. This
                    centralized database facilitates data management and
                    retrieval.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Performance Tracking and Analytics</h2>
                  </div>
                  <p>
                    EdPedia enables educators to track and analyze student
                    performance across various academic metrics such as grades,
                    assessments, and standardized test scores. This feature
                    provides insights into student progress, strengths, and
                    areas for improvement, facilitating personalized instruction
                    and intervention.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src="assets/img/images/features_shape01.png" alt="" />
          <img src="assets/img/images/features_shape02.png" alt="" />
        </div>
      </section>
    </main>
  );
};

export default StudentManagement;
