// import React from 'react'

// const RtkQuery = () => {
//   return (
//     <div>RtkQuery</div>
//   )
// }

// export default

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const landingPageApi = createApi({
  reducerPath: "landingPageApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/superAdmin",
    prepareHeaders: (headers, { getState }) => {
      // const token = getState().auth.token;
      // if (token) {
      //     headers.set('Authorization', `Bearer ${token}`);
      // }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllEvents: builder.query({
      query: () => {
        return {
          url: "/api/event/getAllEvents",
          method: "GET",
        };
      },
    }),
    createDemo: builder.mutation({
      query: (payload) => ({
        url: "/api/demo/createDemo",
        method: "POST",
        body: payload,
      }),
    }),
    registerEvent: builder.mutation({
      query: ({ payload, eventId }) => ({
        url: `/api/event/${eventId}/register`,
        method: "POST",
        body: payload,
      }),
    }),
    createContactUs: builder.mutation({
      query: (payload) => ({
        url: "/api/demo/createContactUs",
        method: "POST",
        body: payload,
      }),
    }),
    getAllPlans: builder.query({
      query: () => {
        return {
          url: "/api/subscription-plan/list",
          method: "GET",
        };
      },
    }),
    getAllTestmonials: builder.query({
      query: () => {
        return {
          url: "/api/testImonial/getAllActiveTestImonia",
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useCreateContactUsMutation,
  useCreateDemoMutation,
  useGetAllEventsQuery,
  useRegisterEventMutation,
  useGetAllPlansQuery,
  useGetAllTestmonialsQuery,
} = landingPageApi;
