import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, Router, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/odometer.css";
import "./assets/css/jarallax.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/slick.css";
import "./assets/css/aos.css";
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Topbar from "./Components/Topbar";
import Home from "./pages/Home";
import Footer from "./Components/Footer";
import Module from "./pages/Module";
import ModuleFeature from "./pages/ModuleFeature";
import Feature from "./pages/Feature";
import Benifits from "./pages/Benifits";
import Events from "./pages/Events";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import EventDetails from "./pages/EventDetails";
import ModuleDetails from "./pages/ModuleDetails";
import Terms from "./pages/Terms";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Price from "./pages/Price";
import AiFeatures from "./pages/AiFeatures";
import Preloader from "./Common/Preloader ";
import WhyEdpedia from "./pages/WhyEdpedia";
import ScrollToTopButton from "./Common/ScrollToTopButton";
import FeatureDetails from "./pages/FeatureDetails";
import BecomePatner from "./pages/BecomePatner";
import ScrollToTop from "./Common/ScrollToTop";
import RequestDemo from "./Common/RequestDemo";
import DemoButton from "./Common/DemoButton";
import Multibranchmanagement from "./pages/FeatureComponents/Multibranchmanagement";
import AttendanceManagement from "./pages/FeatureComponents/AttendanceManagement";
import StudentManagement from "./pages/FeatureComponents/StudentManagement";
import ExamManagement from "./pages/FeatureComponents/ExamManagement";
import AdmissionManagement from "./pages/FeatureComponents/AdmissionManagement";
import Feemanagement from "./pages/FeatureComponents/Feemanagement";
import Notefound from "./pages/Notefound";
import TransportManagement from "./pages/FeatureComponents/TransportManagement";
import SyllabusManagement from "./pages/FeatureComponents/SyllabusManagement";
import DashboardManagement from "./pages/FeatureComponents/DashboardManagement";
import InventryManagement from "./pages/FeatureComponents/InventryManagement";
import InterviewManagement from "./pages/FeatureComponents/InterviewManagement";
import DriverMobileManagement from "./pages/FeatureComponents/DriverMobileManagement";
import TransportModuleManagement from "./pages/FeatureComponents/TransportModuleManagement";
import AiFeatureManagement from "./pages/FeatureComponents/AiFeatureManagement";
import MobileAppManagement from "./pages/FeatureComponents/MobileAppManagement";
import ParentModuleManagement from "./pages/FeatureComponents/ParentModuleManagement";
import AllStaffManagement from "./pages/FeatureComponents/AllStaffManagement";
import GlobalManagement from "./pages/FeatureComponents/GlobalManagement";
import DegitalManagement from "./pages/FeatureComponents/DigitalManagement";
import HomeWorkManagement from "./pages/FeatureComponents/HomeWorkManagement";
import FrontOfficeManagement from "./pages/FeatureComponents/FrontOfficeManagement";
import TimeTableManagement from "./pages/FeatureComponents/TimeTableManagement";
import AnnouncementManagement from "./pages/FeatureComponents/AnnouncementManagement";
import NotificationManagement from "./pages/FeatureComponents/NotificationManagement";
import StudyMaterialManagement from "./pages/FeatureComponents/StudyMaterialManagement";
import TeacherManagement from "./pages/FeatureComponents/TeacherManagement";
import CulturalActivityManagement from "./pages/FeatureComponents/CulturalActivityManagement";
import BirthdayAlertManagement from "./pages/FeatureComponents/BirthdayAlertManagement";
import CampingManagement from "./pages/FeatureComponents/CampingManagement";
import StudentPromotionManagement from "./pages/FeatureComponents/StudentPromotionManagement";
import ExpenseManagement from "./pages/FeatureComponents/ExpenseManagement";
import ParentManagement from "./pages/FeatureComponents/ParentManagement";
import TutorManagement from "./pages/FeatureComponents/TutorManagement";
import VirtualStoreManagement from "./pages/FeatureComponents/VirtualStoreManagement";
import ReportsManagement from "./pages/FeatureComponents/ReportsManagement";
import SocialMediaManagement from "./pages/FeatureComponents/SocialMediaManagement";
import LeaveManagement from "./pages/FeatureComponents/LeaveManagement";
import HrManagement from "./pages/FeatureComponents/HrManagement";
import UserManagement from "./pages/FeatureComponents/UserManagement";
import SummerCampManagement from "./pages/FeatureComponents/SummerCampManagement";
import HostelManagement from "./pages/FeatureComponents/HostelManagement";
import SportsManagement from "./pages/FeatureComponents/SportsManagement";
import CommunicationManagement from "./pages/FeatureComponents/CommunicationManagement";
import CommunicationAppManagement from "./pages/FeatureComponents/CommunicationAppManagement";
import HolidayManagement from "./pages/FeatureComponents/HolidayManagement";
import PayrollManagement from "./pages/FeatureComponents/PayrollManagement";
import StudentAttendanceBiometricManagement from "./pages/FeatureComponents/StudentAttendanceBiometric";
import StudentAttandanceManuallyManagement from "./pages/FeatureComponents/StudentAttandanceManually";
import AccountsManagement from "./pages/FeatureComponents/AccountsManagement";
import DesignationManagement from "./pages/FeatureComponents/DesignationManagement";
import ExamsResultManagement from "./pages/FeatureComponents/ExamResultsManagement";
import AiExamManagement from "./pages/FeatureComponents/AiExamManagement";
import ClassManagement from "./pages/FeatureComponents/ClassManagement";
import DepartmentManagement from "./pages/FeatureComponents/DepartmentManagement";
import SchoolPoliciesManagement from "./pages/FeatureComponents/SchoolPoliciesManagement";
import MeetingManagement from "./pages/FeatureComponents/MeetingManagement";
import DigitalManagement from "./pages/FeatureComponents/DigitalManagement";
import BenefitsDetails from "./pages/BenefitsDetails";
import Custumisable from "./pages/BenefitsComponents/Custumisable";
import UserFriendly from "./pages/BenefitsComponents/UserFriendly";
import MobileApp from "./pages/BenefitsComponents/MobileApp";
import Onlinepayment from "./pages/BenefitsComponents/Onlinepayment";
import TechnicalSupprort from "./pages/BenefitsComponents/TechnicalSupprort";
import Lms from "./pages/Lms";
import Alltime from "./pages/BenefitsComponents/Alltime";
import TimeAnywareAccesibility from "./pages/BenefitsComponents/TimeAnywareAccesibility";
import WellDesignedMoedule from "./pages/BenefitsComponents/WellDesignedMoedule";
import BlogInfo from "./pages/BlogComponents/BlogDetail";
import BlogDetail from "./pages/BlogComponents/BlogDetail";
import IntitialBlog from "./pages/BlogComponents/IntitialBlog";
import SecondBlog from "./pages/BlogComponents/SecondBlog";
import WhatsappAlertBlog from "./pages/BlogComponents/WhatsappAlertBlog";
import ReactPixel from "react-facebook-pixel";
import TearmsCondition from "./pages/TearmsCondition";
import EdpediaStore from "./pages/EdpediaStore";

// const usePageTracking = () => {
//   const location = useLocation();

//   useEffect(() => {
//     ReactPixel.pageView();
//   }, [location]);
// };
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "PageView");
    }
  }, [location]);
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  usePageTracking();
  useEffect(() => {
    const modalVisibilityTimer = setTimeout(() => {
      setShowModal(true);
    }, 4000);

    return () => clearTimeout(modalVisibilityTimer);
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <div>
      {loading && <Preloader />}
      <RouteChangeTracker setLoading={setLoading} />
      <ScrollToTop />
      <div>
        <Topbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/modules" element={<Module />} /> */}
          <Route path="/modules_features" element={<ModuleFeature />} />
          <Route path="/features" element={<Feature />} />
          <Route path="/benefits" element={<Benifits />} />
          <Route path="/events" element={<Events />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/event-details" element={<EventDetails />} />
          <Route path="/modules" element={<ModuleDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/prices" element={<Price />} />
          <Route path="/edpedia-store" element={<EdpediaStore />} />
          <Route path="/ai-feature" element={<AiFeatures />} />
          <Route path="/whyedpedia" element={<WhyEdpedia />} />
          <Route path="/feature-details" element={<FeatureDetails />} />
          <Route path="/become_partner" element={<BecomePatner />} />
          <Route path="/benefits-details" element={<BenefitsDetails />} />
          <Route path="/custumisable" element={<Custumisable />} />
          <Route path="/blog/:path" element={<BlogDetail />} />
          <Route path="/userfriendly" element={<UserFriendly />} />
          <Route path="/fee-management" element={<IntitialBlog />} />
          <Route path="/admission-managements" element={<SecondBlog />} />
          <Route path="/mobile-app" element={<MobileApp />} />

          <Route path="/online-payment" element={<Onlinepayment />} />

          <Route path="/technical-support" element={<TechnicalSupprort />} />
          <Route path="/whatsapp-fee-alert" element={<WhatsappAlertBlog />} />
          <Route
            path="/multi-branch-management"
            element={<Multibranchmanagement />}
          />
          <Route
            path="/attendance-management"
            element={<AttendanceManagement />}
          />
          <Route path="/student-management" element={<StudentManagement />} />
          <Route path="/exam-management" element={<ExamManagement />} />
          <Route
            path="/admission-management"
            element={<AdmissionManagement />}
          />
          <Route path="/fee-management" element={<Feemanagement />} />
          <Route
            path="/transport-management"
            element={<TransportManagement />}
          />
          <Route path="/syllabus-management" element={<SyllabusManagement />} />
          <Route
            path="/interview-management"
            element={<InterviewManagement />}
          />
          <Route path="/inventry-management" element={<InventryManagement />} />
          <Route
            path="/dashboard-management"
            element={<DashboardManagement />}
          />
          <Route path="*" element={<Notefound />} />
          <Route
            path="/drivermobile-management"
            element={<DriverMobileManagement />}
          />
          <Route
            path="/transportmodule-management"
            element={<TransportModuleManagement />}
          />
          <Route
            path="/aifeature-management"
            element={<AiFeatureManagement />}
          />
          <Route
            path="/mobileapp-management"
            element={<MobileAppManagement />}
          />
          <Route
            path="/parentmodule-management"
            element={<ParentModuleManagement />}
          />
          <Route path="/allstaff-management" element={<AllStaffManagement />} />
          <Route path="/global-management" element={<GlobalManagement />} />
          <Route path="/digital-management" element={<DigitalManagement />} />
          <Route path="/homework-management" element={<HomeWorkManagement />} />
          <Route
            path="/frontoffice-management"
            element={<FrontOfficeManagement />}
          />
          <Route
            path="/timetable-management"
            element={<TimeTableManagement />}
          />
          <Route
            path="/announcement-management"
            element={<AnnouncementManagement />}
          />
          <Route
            path="/notification-management"
            element={<NotificationManagement />}
          />
          <Route
            path="/studymaterial-management"
            element={<StudyMaterialManagement />}
          />
          <Route path="/teacher-management" element={<TeacherManagement />} />
          <Route
            path="/culturalactivity-management"
            element={<CulturalActivityManagement />}
          />
          <Route
            path="/birthdayalert-management"
            element={<BirthdayAlertManagement />}
          />
          <Route path="/camping-management" element={<CampingManagement />} />
          <Route
            path="/studentpromotion-management"
            element={<StudentPromotionManagement />}
          />
          <Route path="/expense-management" element={<ExpenseManagement />} />
          <Route path="/parent-management" element={<ParentManagement />} />
          <Route path="/meeting-management" element={<MeetingManagement />} />
          <Route path="/tutor-management" element={<TutorManagement />} />
          <Route
            path="/virtualstore-management"
            element={<VirtualStoreManagement />}
          />
          <Route path="/reports-management" element={<ReportsManagement />} />
          <Route
            path="/socialmedia-management"
            element={<SocialMediaManagement />}
          />
          <Route path="/leave-management" element={<LeaveManagement />} />
          <Route path="/hr-management" element={<HrManagement />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route
            path="/summercamp-management"
            element={<SummerCampManagement />}
          />
          <Route path="/hostel-management" element={<HostelManagement />} />
          <Route path="/sports-management" element={<SportsManagement />} />
          <Route
            path="/communication-management"
            element={<CommunicationManagement />}
          />
          <Route
            path="/communicationapp-management"
            element={<CommunicationAppManagement />}
          />
          <Route path="/holiday-management" element={<HolidayManagement />} />
          <Route path="/payroll-management" element={<PayrollManagement />} />
          <Route
            path="/studentattandancebiometric-management"
            element={<StudentAttendanceBiometricManagement />}
          />
          <Route
            path="/studentattandancemanually-management"
            element={<StudentAttandanceManuallyManagement />}
          />
          <Route path="/accounts-management" element={<AccountsManagement />} />
          <Route
            path="/designation-management"
            element={<DesignationManagement />}
          />
          <Route
            path="/examsresult-management"
            element={<ExamsResultManagement />}
          />
          <Route path="/aiexam-management" element={<AiExamManagement />} />
          <Route
            path="/class-subject-management"
            element={<ClassManagement />}
          />
          <Route
            path="/well-designed-module"
            element={<WellDesignedMoedule />}
          />
          <Route
            path="/anytime-anyware-accesibility"
            element={<TimeAnywareAccesibility />}
          />
          <Route
            path="/department-management"
            element={<DepartmentManagement />}
          />
          <Route
            path="/school-policies-management"
            element={<SchoolPoliciesManagement />}
          />
          <Route path="/lms-zoom-service" element={<Lms />} />
          <Route path="/alltime-support" element={<Alltime />} />
          <Route path="/terms&conditions" element={<TearmsCondition />} />
        </Routes>

        <DemoButton
          isModalVisible={showModal}
          setIsModalVisible={setShowModal}
        />
        <ScrollToTopButton />
        <Footer />
      </div>
      <RequestDemo
        isModalVisible={showModal}
        setIsModalVisible={setShowModal}
      />
      ;
    </div>
  );
};
const RouteChangeTracker = ({ setLoading }) => {
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, [location, setLoading]);

  return null;
};

export default App;
