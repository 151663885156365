import React, { useEffect, useState } from "react";
import logo from "../assets/img/images/ed.svg";
import { Link } from "react-router-dom";
import Sidebar from "../pages/Sidebar";
const Topbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      console.log(scrollTop);
      setIsSticky(scrollTop > 245);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log("hello");
  };

  return (
    <>
      <div id="header-fixed-height"></div>
      <header
        className={`header-style-six ${isSticky ? "sticky-menu" : ""}`}
        id="sticky-header"
      >
        <div className="heder-top-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="header-top-left">
                  <ul className="list-wrap">
                    <li>
                      <i className="flaticon-phone-call"></i>&nbsp;+91 86607
                      26301
                    </li>
                    <li>
                      <i className="flaticon-mail"></i>
                      <Link to="/contact">contact@eaglemountsoft.com</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="header-top-right">
                  <div className="header-social">
                    <ul className="list-wrap">
                      <li>
                        <Link to="https://www.linkedin.com/company/edpedia-co">
                          <i class="fa-brands fa-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.facebook.com/profile.php?id=61553421634862">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>

                      <li>
                        <Link to="https://www.instagram.com/edpedia.co/">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="sticky-header" class="menu-area">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="mobile-nav-toggler" onClick={toggleSidebar}>
                  <i class="fas fa-bars"></i>
                </div>
                <div class="menu-wrap">
                  <nav class="menu-nav">
                    <div class="logo">
                      <Link to="/whyedpedia">
                        <img src={logo} alt="Logo" className="logo" />
                      </Link>
                    </div>
                    <div class="navbar-wrap main-menu d-none d-lg-flex">
                      <ul class="navigation">
                        <li>
                          <Link to="/whyedpedia">Why EdPedia</Link>
                        </li>
                        <li>
                          <Link to="/modules_features">Modules</Link>
                          {/* <ul class="sub-menu">
                            <li>
                              <Link to="/modules_features">
                                Explore All Modules
                              </Link>
                            </li>
                          </ul> */}
                          {/* <div class="dropdown-btn">
                            <span class="fas fa-angle-down"></span>
                          </div> */}
                        </li>
                        <li>
                          <Link to="/features">Features</Link>
                        </li>
                        <li>
                          <Link to="/benefits">Benefits</Link>
                        </li>

                        <li>
                          <Link to="/prices">Prices</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link> Integration</Link>
                          <ul className="sub-menu">
                            <li className="menu-item-has-children">
                              <Link>LMS Integration</Link>
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/lms-zoom-service">
                                    Zoom Services
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/">Google Meet</Link>
                                </li>
                              </ul>
                              <div className="dropdown-btn">
                                <span className="fas fa-angle-down"></span>
                              </div>
                            </li>
                            <li className="menu-item-has-children">
                              <Link to="">Admission Integration</Link>
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/">Lead Square CRM</Link>
                                </li>
                                <li>
                                  <Link to="/">Meritto</Link>
                                </li>
                              </ul>
                              <div className="dropdown-btn">
                                <span className="fas fa-angle-down"></span>
                              </div>
                            </li>
                            <li className="menu-item-has-children">
                              <Link to="/">Payment Integration</Link>
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/">Tally</Link>
                                </li>
                                <li>
                                  <Link to="/">PG</Link>
                                </li>
                              </ul>
                              <div className="dropdown-btn">
                                <span className="fas fa-angle-down"></span>
                              </div>
                            </li>
                            <li className="menu-item-has-children">
                              <Link to="/">Attendance Integration</Link>
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/">Biometrics</Link>
                                </li>
                                <li>
                                  <Link to="/">RFID</Link>
                                </li>
                              </ul>
                              <div className="dropdown-btn">
                                <span className="fas fa-angle-down"></span>
                              </div>
                            </li>
                            <li>
                              <Link to="/">Explore All Integration</Link>
                            </li>
                          </ul>
                          <div className="dropdown-btn">
                            <span className="fas fa-angle-down"></span>
                          </div>
                        </li>
                        <li>
                          <Link to="/edpedia-store">EdPedia Store</Link>
                        </li>

                        <li>
                          <Link to="/blogs">Blogs</Link>
                          {/* <ul class="sub-menu">
                            <li>
                              <Link to="/events">Events</Link>
                            </li>
                          </ul> */}
                          {/* <div class="dropdown-btn">
                            <span class="fas fa-angle-down"></span>
                          </div> */}
                        </li>
                      </ul>
                    </div>
                    <div class="header-action d-none d-md-block">
                      <ul class="list-wrap">
                        <li class="header-btn">
                          <Link to="/become_partner" class="btn btn-two">
                            Become a Partner
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>

                <div class="mobile-menu">
                  <nav class="menu-box">
                    <div class="close-btn">
                      <i class="fas fa-times"></i>
                    </div>
                    <div class="nav-logo">
                      <Link to="/">
                        <img src="assets/img/logo/logo.png" alt="Logo" />
                      </Link>
                    </div>
                    <div class="mobile-search">
                      <form action="#">
                        <input type="text" placeholder="Search here..." />
                        <button>
                          <i class="flaticon-search"></i>
                        </button>
                      </form>
                    </div>
                    <div class="menu-outer"></div>
                    <div class="social-links">
                      <ul class="clearfix list-wrap">
                        <li>
                          <Link to="/">
                            <i class="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/">
                            <i class="fab fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/">
                            <i class="fab fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/">
                            <i class="fab fa-linkedin-in"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/">
                            <i class="fab fa-youtube"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div class="menu-backdrop"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="search-popup-wrap"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="search-close">
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
          <div className="search-wrap text-center">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="title">... Search Here ...</h2>
                  <div className="search-form">
                    <form action="#">
                      <input
                        type="text"
                        name="search"
                        placeholder="Type keywords here"
                      />
                      <button className="search-btn">
                        <i className="fas fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div
          className="menu-backdrop"
          onClick={toggleSidebar}
          style={{ display: isSidebarOpen ? "block" : "none" }}
        ></div>
      </header>
    </>
  );
};

export default Topbar;
