import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import blog_details_1 from "../assets/img/blog/blog-details01.jpg";
import avatar1 from "../assets/img/blog/blog_avatar01.png";
import post1 from "../assets/img/blog/rc_post01.jpg";
import post2 from "../assets/img/blog/rc_post02.jpg";
import post3 from "../assets/img/blog/rc_post03.jpg";
import post4 from "../assets/img/blog/rc_post04.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
const EventDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Events Details
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="blog-details-area pt-50 pb-100">
          <div class="container">
            <div class="blog-details-wrap">
              <div class="row justify-content-center">
                <div class="col-71">
                  <div class="blog-details-thumb">
                    <img src={blog_details_1} alt="" />
                  </div>
                  <div class="blog-details-content">
                    <h2 class="title">
                      Why Should Business Payroll Outsourcing
                    </h2>
                    <div class="blog-meta-three">
                      <ul class="list-wrap">
                        <li>
                          <i class="far fa-calendar"></i>22 Jan, 2023
                        </li>
                        <li>
                          <img src={avatar1} alt="" /> by{" "}
                          <Link to="/blogs">Kat Doven</Link>
                        </li>
                        <li>
                          <i class="fas fa-tags"></i>{" "}
                          <Link to="/blogs">Finance,</Link>
                          <Link to="/blogs">Business</Link>
                        </li>
                        <li>
                          <i class="flaticon-speech-bubble"></i>
                          <Link to="/blogs">05 Comments</Link>
                        </li>
                      </ul>
                    </div>
                    <p>
                      when an unknown printer took ar galley offer type year
                      anddey scrambled make type aewer specimen book bethas
                      survived not only five when annery unknown printer.eed a
                      little help from our friends from time to time. Although
                      we offer the one-stop convenience.
                    </p>
                    <p>
                      eed a little help from our friends from time to time.
                      Although we offer the one-stop convenience of annery
                      integrated range of legal, financial services under one
                      roof, there are occasions when our clients areaneed
                      specia- list advice beyond the scope of our own expertise.
                    </p>
                  </div>
                </div>
                <div class="col-29">
                  <aside class="blog-sidebar">
                    <div class="sidebar-search">
                      <form action="#">
                        <input type="text" placeholder="Search Here . . ." />
                        <button type="submit">
                          <i class="flaticon-search"></i>
                        </button>
                      </form>
                    </div>

                    <div class="blog-widget">
                      <h4 class="bw-title">Recent Posts</h4>
                      <div class="rc-post-wrap">
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/blogs">
                              <img src={post1} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>22 Jan, 2023
                            </span>
                            <h2 class="title">
                              <Link to="/blogs">
                                Whale be raised must be in a month
                              </Link>
                            </h2>
                          </div>
                        </div>
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/blogs">
                              <img src={post2} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>22 Jan, 2023
                            </span>
                            <h2 class="title">
                              <Link to="/blogs">
                                Whale be raised must be in a month
                              </Link>
                            </h2>
                          </div>
                        </div>
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/blogs">
                              <img src={post3} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>22 Jan, 2023
                            </span>
                            <h2 class="title">
                              <Link to="/blogs">
                                Whale be raised must be in a month
                              </Link>
                            </h2>
                          </div>
                        </div>
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/blogs">
                              <img src={post4} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>22 Jan, 2023
                            </span>
                            <h2 class="title">
                              <Link to="/blogs">
                                Whale be raised must be in a month
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EventDetails;
