import React from "react";
import notfound from "../assets/img/not.png";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <img src={notfound} alt="Not Found" style={styles.image} />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh", // full height of the viewport
  },
  image: {
    width: "800px", // set desired width
    height: "auto", // auto height to maintain aspect ratio
  },
};

export default NotFound;
