import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import overview_01 from "../assets/img/images/overview_img01.jpg";
import sb_insta from "../assets/img/images/sb_insta06.jpg";
import about_shape01 from "../assets/img/images/h2_about_shape01.png";
import about_shape02 from "../assets/img/images/h2_about_shape02.png";
import about_shape03 from "../assets/img/images/h2_about_shape03.png";
import inner_about_01 from "../assets/img/images/inner_about_img01.jpg";
import inner_about_02 from "../assets/img/images/inner_about_img02.jpg";
import inner_about_shape1 from "../assets/img/images/inner_about_shape01.png";
import services_shape_01 from "../assets/img/services/h6_services_shape01.png";
import services_shape_2 from "../assets/img/services/h6_services_shape02.png";
import inner_choose_img from "../assets/img/images/inner_choose_img.jpg";
import request from "../assets/img/images/h2_request_shape01.png";
import inner_about_shape from "../assets/img/images/inner_about_shape01.png";
import overview03 from "../assets/img/images/h3_overview_img01.jpg";
import abou_img_03 from "../assets/img/images/h8_about_img03.jpg";
import feature_01 from "../assets/img/images/features_shape01.png";
import feature_02 from "../assets/img/images/features_shape02.png";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

import AOS from "aos";
const { Panel } = Collapse;

const BenefitsDetails = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <main class="fix">
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Explore All Modules
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section class="services-area-twelve- fix  pt-50 pb-100 bg-light mt-30">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-7">
              <div class="section-title-two text-center mb-50">
                <h2 class="title">Key Benefits of EdPedia</h2>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-4">
              <div class="about-img-seven-wrap">
                <img
                  src={inner_about_01}
                  alt=""
                  data-aos="fade-right"
                  data-aos-delay="0"
                  class="aos-init aos-animate"
                />
                <img
                  src={inner_about_02}
                  alt=""
                  data-aos="fade-up"
                  data-aos-delay="300"
                  class="aos-init aos-animate"
                />
                <img
                  src={inner_about_shape1}
                  alt=""
                  class="shape aos-init"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                />
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row gutter-24">
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">
                          Easy School Management
                        </Link>
                      </h2>
                      <p>
                        Simplify and automate your school's administrative
                        tasks. With EdPedia, you can efficiently manage student
                        records, attendance, timetables, and more, all from one
                        platform.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Collaboration Tools</Link>
                      </h2>
                      <p>
                        Enhance communication and collaboration among students,
                        teachers, and parents. Our platform includes messaging,
                        forums, and shared resources to foster a collaborative
                        learning environment.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">
                          User-Friendly Interface
                        </Link>
                      </h2>
                      <p>
                        Experience a seamless and intuitive user interface
                        designed for ease of use. EdPedia's clean design and
                        straightforward navigation ensure that all users can
                        quickly and effectively utilize the platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Data Security</Link>
                      </h2>
                      <p>
                        Keep your data safe with our robust security measures.
                        EdPedia ensures that all sensitive information is
                        protected through advanced encryption and regular
                        security updates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="services-shape-wrap">
          <img
            src={services_shape_01}
            alt="shape"
            data-aos="fade-down-left"
            data-aos-delay="200"
            class="aos-init aos-animate"
          />
          <img
            src={services_shape_2}
            alt="shape"
            data-aos="fade-up-right"
            data-aos-delay="200"
            class="aos-init aos-animate"
          />
        </div>
      </section>

      <section
        class="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6">
              <div class="section-title text-center mb-50">
                <h2 class="title">Advanced Analytics</h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">Student & Behavior Analytics</h2>
                  </div>
                  <p>
                    Understand the well-being and social interactions of your
                    students with EdPedia's behavior analytics. Monitor
                    aggregate behavioral patterns and receive suggestions for
                    interventions in case of any anomalies.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">Institution Performance Analytics</h2>
                  </div>
                  <p>
                    Get a comprehensive view of your institution’s academic
                    performance with interactive graphs and charts. Compare
                    current statistics with historical data to understand trends
                    and identify areas for improvement.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Advanced Reporting</h2>
                  </div>
                  <p>
                    Enhance your reporting capabilities with detailed insights
                    into academic performance, behavior, and health analytics.
                    Ensure no student is left behind by leveraging advanced
                    reporting tools in EdPedia’s student management system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src={feature_01} alt="" />
          <img src={feature_02} alt="" />
        </div>
      </section>

      <section class="services-area-twelve- fix  pt-50 pb-100 bg-blue">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-7">
              <div class="section-title-two white-title text-center mb-50">
                <h2 class="title tg-element-title">
                  Learning Management System
                </h2>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="row gutter-24">
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Blended Learning</Link>
                      </h2>
                      <p>
                        Many features within Edpedia’s school management
                        software are designed to let students dictate the pace
                        of lectures in class. At Edpedia, we integrate
                        technology into the classroom to convert your
                        teacher-centric education system into a student-centric
                        one.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Flip Class</Link>
                      </h2>
                      <p>
                        Edpedia acknowledge that every student is unique. We
                        endorse an innovative method of learning in the Flipped
                        Classroom that allows students to study at a pace that
                        suits them.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Discussions</Link>
                      </h2>
                      <p>
                        Edpedia provides a social platform to enhance and
                        encourage teacher to student and student to student
                        interaction. Students can easily form study groups to
                        engage in active conversations, debates or group
                        problem-solving sessions.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">
                          Online Quizzes and Tests
                        </Link>
                      </h2>
                      <p>
                        Edpedia gives teachers the power to create online
                        quizzes and tests with varying question types like
                        Multiple Choice, Open Ended or fill in the blanks.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Live Lectures</Link>
                      </h2>
                      <p>
                        With Edpedia, your home can be your classroom! Take live
                        classes on the go and help students clearing their
                        doubts instantly and efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">
                          Immersive Lecture Experience
                        </Link>
                      </h2>
                      <p>
                        Upload all kinds of study material. We will take care of
                        the rest! Edpedia provides inline browser views of PDF,
                        audio, video, presentation and word files for an
                        immersive lecture experience.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Portals</Link>
                      </h2>
                      <p>
                        Give everyone in your institution their own dashboard to
                        manage personal and academic information. Students,
                        parents, teachers and administrators can manage all
                        their needs with Edpedia.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="services-item-eight">
                    <div class="services-content-eight">
                      <h2 class="title">
                        <Link to="/benefits-details">Academic Progress</Link>
                      </h2>
                      <p>
                        Track the academic progress of every student for the
                        duration of their time at your institution.
                        Administrators can easily verify student details to
                        ensure they meet the required qualifications for any
                        degree they want to pursue.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="services-shape-wrap">
          <img
            src={services_shape_01}
            alt="shape"
            data-aos="fade-down-left"
            data-aos-delay="400"
            class="aos-init aos-animate"
          />
          <img
            src={services_shape_2}
            alt="shape"
            data-aos="fade-up-right"
            data-aos-delay="400"
            class="aos-init aos-animate"
          />
        </div>
      </section>
    </main>
  );
};

export default BenefitsDetails;
