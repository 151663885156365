import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { gsap } from "gsap";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store/Store";
import ReactPixel from "react-facebook-pixel";
const root = ReactDOM.createRoot(document.getElementById("root"));
gsap.registerPlugin();
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init("7842536345821926", options);
ReactPixel.pageView();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
