import React, { useEffect, useState } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import child1 from "../assets/img/blog/rc_post01.jpg";
import child2 from "../assets/img/blog/rc_post02.jpg";
import child3 from "../assets/img/blog/rc_post03.jpg";
import child4 from "../assets/img/blog/rc_post04.jpg";

import blog_2 from "../assets/img/blog/blog_avatar01.png";

import blog_4 from "../assets/img/blog/blog_avatar02.png";
import blog_1 from "../assets/img/fee.png";
import blog_3 from "../assets/img/admission.png";
import blog_5 from "../assets/img/blog/h3_blog_img03.jpg";
import blog_6 from "../assets/img/blog/blog_avatar03.png";
import blog_7 from "../assets/img/blog/h3_blog_img04.jpg";
import blog_8 from "../assets/img/blog/blog_avatar04.png";
import blog_9 from "../assets/img/blog/h3_blog_img05.jpg";
import blog_10 from "../assets/img/blog/blog_avatar05.png";
import blog_11 from "../assets/img/blog/h3_blog_img06.jpg";
import blog_12 from "../assets/img/blog/blog_avatar06.png";

import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import { blogData } from "./BlogComponents/Data";
const Blog = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogData);
  }, []);

  return (
    <>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Blogs
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="blog-area pt-120 pb-120">
          <div class="container">
            <div class="inner-blog-wrap">
              <div class="row justify-content-center">
                <div class="col-71">
                  <div class="blog-post-wrap">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="blog-post-item-two">
                          <div class="blog-post-thumb-two">
                            <Link to="/whatsapp-fee-alert">
                              <img src={blog_1} alt="" />
                            </Link>
                            <Link to="/whatsapp-fee-alert" class="tag tag-two">
                              WhatsApp Fee Alerts
                            </Link>
                          </div>
                          <div class="blog-post-content-two">
                            <h2 class="title">
                              <Link to="/whatsapp-fee-alert">
                                WhatsApp Fee Alerts for School Fee Management
                              </Link>
                            </h2>
                            <p>
                              A user-friendly interface ensures that both
                              applicants and administrators can navigate the
                              system effortlessly.
                            </p>
                            <div class="blog-meta">
                              <ul class="list-wrap">
                                <li>
                                  <Link to="/whatsapp-fee-alert">
                                    <img src={blog_4} alt="" />
                                    Admin
                                  </Link>
                                </li>
                                <li>
                                  <i class="far fa-calendar"></i> 10th jun 2024
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="blog-post-item-two">
                          <div class="blog-post-thumb-two">
                            <Link to="/fee-management">
                              <img src={blog_1} alt="" />
                            </Link>
                            <Link to="/blog" class="tag tag-two">
                              Fee Management software
                            </Link>
                          </div>
                          <div class="blog-post-content-two">
                            <h2 class="title">
                              <Link to="/fee-management">
                                How Fee Management Software Can Improve Your
                                Institution's Financial Health
                              </Link>
                            </h2>
                            <p>
                              One of the most significant advantages of fee
                              management software is the automation of billing
                              and invoicing
                            </p>
                            <div class="blog-meta">
                              <ul class="list-wrap">
                                <li>
                                  <Link to="/fee-management">
                                    <img src={blog_2} alt="" />
                                    Admin
                                  </Link>
                                </li>
                                <li>
                                  <i class="far fa-calendar"></i>22 Jan, 2023
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="blog-post-item-two">
                          <div class="blog-post-thumb-two">
                            <Link to="/admission-managements">
                              <img src={blog_3} alt="" />
                            </Link>
                            <Link
                              to="/admission-managements"
                              class="tag tag-two"
                            >
                              Admission Management
                            </Link>
                          </div>
                          <div class="blog-post-content-two">
                            <h2 class="title">
                              <Link to="/admission-managements">
                                Top 10 Features to Look for in Admission
                                Management Software
                              </Link>
                            </h2>
                            <p>
                              A user-friendly interface ensures that both
                              applicants and administrators can navigate the
                              system effortlessly.
                            </p>
                            <div class="blog-meta">
                              <ul class="list-wrap">
                                <li>
                                  <Link to="/admission-managements">
                                    <img src={blog_4} alt="" />
                                    Admin
                                  </Link>
                                </li>
                                <li>
                                  <i class="far fa-calendar"></i> 15 Dec 2022
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-29">
                  <aside class="blog-sidebar">
                    <div class="sidebar-search">
                      <form action="#">
                        <input type="text" placeholder="Search Here . . ." />
                        <button type="submit">
                          <i class="flaticon-search"></i>
                        </button>
                      </form>
                    </div>
                    {/* <div class="blog-widget">
                      <h4 class="bw-title">Categories</h4>
                      <div class="bs-cat-list">
                        <ul class="list-wrap">
                          <li>
                            <Link to="/blogs">
                              Business <span>(02)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogs">
                              Consulting <span>(08)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogs">
                              Corporate <span>(05)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogs">
                              Design <span>(02)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogs">
                              Fashion <span>(11)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogs">
                              Marketing <span>(12)</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div class="blog-widget">
                      <h4 class="bw-title">Recent Blogs</h4>
                      <div class="rc-post-wrap">
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/whatsapp-fee-alert">
                              <img src={child1} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>
                            </span>
                            <h2 class="title">
                              <Link to="/whatsapp-fee-alert">
                                WhatsApp Fee Alerts for School Fee Management
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="rc-post-wrap">
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/fee-management">
                              <img src={child1} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>
                            </span>
                            <h2 class="title">
                              <Link to="/fee-management">
                                How Fee Management Software Can Improve Your
                                Institution's Financial Health
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="rc-post-wrap">
                        <div class="rc-post-item">
                          <div class="thumb">
                            <Link to="/admission-managements">
                              <img src={child1} alt="" />
                            </Link>
                          </div>
                          <div class="content">
                            <span class="date">
                              <i class="far fa-calendar"></i>
                            </span>
                            <h2 class="title">
                              <Link to="/admission-managements">
                                Top 10 Features to Look for in Admission
                                Management Software
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Blog;
