import { Button } from "antd";
import React, { useState } from "react";

const DemoButton = ({ isModalVisible, setIsModalVisible }) => {
  const onHandleRequest = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="button-container1 cardZooming">
      <Button
        onClick={onHandleRequest}
        className="arrow-button demo-button corner-button"
      >
        Start Free Trail
      </Button>
    </div>
  );
};
export default DemoButton;
