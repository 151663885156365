import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import team_details from "../assets/img/team_details_img.jpg";
import about_9 from "../assets/img/images/h10_about_img01.jpg";
import about_10 from "../assets/img/h10_about_img02.jpg";
import about_11 from "../assets/img/images/h10_about_img03.jpg";
import about_shape from "../assets/img/images/h10_about_shape.png";
import contact_shape01 from "../assets/img/images/h6_contact_shape01.png";
import contact_shape02 from "../assets/img/images/h6_contact_shape01.png";
import { Link } from "react-router-dom";
import AOS from "aos";
const BecomePatner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Become A Partner{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area-eight pt-50 pb-120 bg-light mt-30">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6">
                <div class="about-content-eight">
                  <div class="section-title-two mb-30">
                    {/* <!-- <span class="sub-title">Get To Know More</span> --> */}
                    <h2 class="title">Start your journey today!</h2>
                  </div>
                  <p>
                    Estimates show that there are more than 200 million schools
                    in the world. A simple calculation reveals that this equates
                    to an average of one school in every square kilometer of
                    global land mass. This is the size of the market that has a
                    real need for Edpedia School Management Software. It
                    represents an excellent opportunity for Edpedia partners to
                    expand their business with a tried and trusted product.
                  </p>

                  <p>
                    Edpedia is totally committed to supporting its partners who
                    wish to market the Edpedia open source school ERP system. We
                    are aware of the demand that is out there for a world class
                    school ERP solution. At present, the market is dominated by
                    two types of suppliers. One are the non-professional outfits
                    who supply a solution but are unable to provide the vital
                    after sales support that every institution needs The others
                    offer high priced solutions that very often are beyond the
                    budgetary capacity of most institutions.
                  </p>

                  <p>
                    The Edpedia system is perfectly positioned in terms of
                    suitability, quality, pricing and after sales support. As a
                    partner, you can easily tap into the huge potential that is
                    out there at very low risk. You can select the partnership
                    program that is best aligned to your values and objectives.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-9">
                <div class="about-img-eight">
                  <img src={team_details} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about-area-eight pt-50 pb-120">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-9">
                <div class="about-img-sixteen">
                  <img
                    src={about_9}
                    alt="img"
                    data-aos="fade-right"
                    data-aos-delay="200"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={about_10}
                    alt="img"
                    data-aos="fade-left"
                    data-aos-delay="400"
                    class="aos-init aos-animate"
                  />
                  <img
                    src={about_11}
                    alt="img"
                    data-aos="fade-up"
                    data-aos-delay="600"
                    class="aos-init aos-animate"
                  />
                  <img src={about_shape} alt="shape" class="shape" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content-eight">
                  <div class="section-title-two mb-30">
                    {/* <!-- <span class="sub-title">Get To Know More</span> --> */}
                    <h2 class="title">Become a Edpedia Channel Partner</h2>
                  </div>
                  <p>
                    We are the leading school ERP management software in India.
                    We enable schools to automate everyday operations with our
                    innovative solutions.
                  </p>

                  <div class="about-content-inner">
                    <ul class="list-wrap">
                      <li>
                        <div class="icon">
                          <i class="flaticon-business-presentation"></i>
                        </div>
                        <div class="content">
                          <h4 class="title">Attractive commission</h4>
                          <p>
                            Partner with us to boost your career as you align
                            your success with Edpedia and earn a handsome
                            commission.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div class="icon">
                          <i class="flaticon-investment"></i>
                        </div>
                        <div class="content">
                          <h4 class="title">
                            Assistance in follow-ups & closures
                          </h4>
                          <p>
                            Our in-house team of product experts is happy to
                            assist you in achieving lead closures.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div class="icon">
                          <i class="flaticon-investment"></i>
                        </div>
                        <div class="content">
                          <h4 class="title">
                            Expert training & presentation materials
                          </h4>
                          <p>
                            Bring fast and quality closures of leads using our
                            training & presentation materials, which product
                            experts create in Edpedia.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div class="icon">
                          <i class="flaticon-investment"></i>
                        </div>
                        <div class="content">
                          <h4 class="title">Partner agreement</h4>
                          <p>
                            Receive a formal partnership agreement to verify
                            your partnership and work with authority as an MCB
                            channel partner.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="contact-area-three fix">
          <div class="container-fulid">
            <div class="contact-inner-wrap">
              <div
                class="contact-img-two contact_bg"
                data-background="assets/img/images/h6_contact_img.jpg"
                // style='background-image: url("assets/img/images/h6_contact_img.jpg");'
              ></div>
              <div class="row g-0 justify-content-end">
                <div class="col-54">
                  <div class="contact-content-two">
                    <div class="section-title-two white-title mb-10 tg-heading-subheading animation-style3">
                      <span class="sub-title">Contact With Us</span>
                      <h2
                        class="title tg-element-title"
                        // style="perspective: 400px;"
                        style={{ perspective: 400 }}
                      >
                        Refer & Earn with Edpedia
                      </h2>
                    </div>
                    <p>
                      Whether you’re a Teacher, educationalist, Homemaker,
                      Retired Employee, Self Employed Individual, College
                      pass-outs Consultants and freelancers in the education
                      industry, or anyone with good contacts, we are excited to
                      get you on board to take our vision forward for providing
                      digital solutions to schools.
                    </p>
                    <div class="contact-form contact-form-two">
                      <form action="#">
                        <div class="row gutter-15">
                          <div class="col-md-6">
                            <div class="form-grp">
                              <input type="text" placeholder="Name *" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-grp">
                              <input type="email" placeholder="E-mail *" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-grp">
                              <input type="number" placeholder="Phone *" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-grp">
                              <input type="text" placeholder="Subject *" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-grp">
                              <textarea placeholder="Comments *"></textarea>
                            </div>
                          </div>
                        </div>
                        <button type="submit">Submit Now</button>
                      </form>
                    </div>
                    <div class="contact-shape-wrap">
                      <img
                        src={contact_shape01}
                        alt="shape"
                        data-aos="fade-down-left"
                        data-aos-delay="400"
                        class="aos-init aos-animate"
                      />
                      <img
                        src={contact_shape02}
                        alt="shape"
                        data-aos="fade-up-right"
                        data-aos-delay="400"
                        class="aos-init aos-animate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default BecomePatner;
