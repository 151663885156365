import React from "react";
import { Link, useParams } from "react-router-dom";
import { blogData } from "./Data";
import child1 from "../../assets/img/blog/rc_post01.jpg";
import child2 from "../../assets/img/blog/rc_post02.jpg";
import child3 from "../../assets/img/blog/rc_post03.jpg";
import child4 from "../../assets/img/blog/rc_post04.jpg";
import logo from "../../assets/img/images/ed.svg";
import whatsapp from "../../assets/img/icons/whasapp.png";
import qrcode from "../../assets/img/qrcode.png";
import blog_3 from "../../assets/img/admission.png";
import { faChessBoard, faStar } from "@fortawesome/free-solid-svg-icons";
import testimonial1 from "../../assets/img/images/testi_avatar01.png";
import testimonial2 from "../../assets/img/images/testi_avatar02.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import InputFloatLabel from "../../Common/FloatLabel/InputFloatLabel";
import SelectFloatLabel from "../../Common/FloatLabel/SelectFloatLabel";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePickerFloatLable";
import { Button, Col, Form, Row, Select, Space, notification } from "antd";
import "../../assets/css/Modal.css";
import dayjs from "dayjs";
import { useCreateDemoMutation } from "../../RTKQuery/RtkQuery";
import moment from "moment";

const SecondBlog = () => {
  // api call
  const [requestDemo] = Form.useForm();
  const [createDemo, { isLoading }] = useCreateDemoMutation();
  const { Option } = Select;

  const onFinishFailed = (errorInfo) => {};
  const dateWithTime = (preferredDemoDate) => {
    return dayjs(preferredDemoDate).format("YYYY-MM-DD HH:mm:ss");
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onFinish = async (values) => {
    const { preferredDemoDate, ...restValues } = values;
    const formattedDateTime = dateWithTime(preferredDemoDate);

    const payload = {
      ...restValues,
      preferredDemoDate: formattedDateTime,
      schoolSize: "",
      roleAtSchool: "",
      additionalInformation: "",
    };
    try {
      const response = await createDemo(payload);
      if (response.data.status === 200) {
        requestDemo.resetFields();

        notification.success({
          message: "Demo Request Successful",
          description: "Your demo request has been successfully submitted.",
        });
      } else {
        notification.error({
          message: "Demo Request Failed",
          description: "Sorry, something went wrong with your demo request.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Demo Request Failed",
        description: "Sorry, something went wrong with your demo request.",
      });
    }
  };

  const handleModalCancel = () => {
    requestDemo.resetFields();
  };

  return (
    <>
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li class="breadcrumb-item active">Blog Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section class=" mb-20 mt-20">
        <div class="container">
          <div
            class="cta-inner-wrap inner_cta"
            data-background="assets/img/bg/cta_bg.jpg"
          >
            <div class="row align-items-center">
              <div class="col-lg-9">
                <div class="cta-content">
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div class="content">
                      <span>Blog Author</span>
                      <span>Admin</span>
                    </div>
                  </div>
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div class="content">
                      <span> Director</span>
                      <span>Admin</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="content-container">
                  <div class="blog-content-span">
                    <span> Published Date :</span>
                    <span> 15 Dec 2022</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="blog-area pt-120 pb-120">
        <div class="container">
          <div class="inner-blog-wrap">
            <div class="row justify-content-center">
              <div className="col-71">
                <div className="blog-post-wrap">
                  <div className="row">
                    <div className="blog-details-header blog-widget">
                      <div className="blog-header-content">
                        <h4 className="bw-title">
                          Features to Look for in Admission Management Software
                        </h4>
                        <img src={blog_3} alt="" />
                        <ul>
                          <h4>Table of Contents</h4>
                          <ol>
                            <li>
                              Introduction: Is Your Admissions Process
                              Efficient?
                            </li>
                            <li>Feature 1: User-Friendly Interface</li>
                            <li>Feature 2: Online Application Portal</li>
                            <li>Feature 3: Application Tracking</li>
                            <li>Feature 4: Automated Communication</li>
                            <li>Feature 5: Data Analytics and Reporting</li>
                            <li>Feature 6: Integration with Other Systems</li>
                            <li>Feature 7: Mobile Accessibility</li>
                            <li>Feature 8: Secure Data Management</li>
                            <li>Feature 9: Customizable Workflows</li>
                            <li>Feature 10: Support and Training</li>
                            <li>
                              Why Choose Edpedia for Admission Management?
                            </li>
                            <li>FAQs</li>
                          </ol>
                        </ul>

                        <h5>
                          Introduction: Is Your Admissions Process Efficient?
                        </h5>
                        <p>
                          Are you finding it challenging to manage the influx of
                          applications during admission season? Is your current
                          system bogged down by inefficiencies and manual
                          processes? The right admission management software can
                          streamline your processes, enhance communication, and
                          provide valuable insights. But with so many options
                          available, what should you look for? Here are the top
                          10 features to consider when selecting admission
                          management software. And if you're looking for a
                          comprehensive solution, Edpedia is your answer.
                        </p>
                        <h4>
                          <strong>Feature 1: User-Friendly Interface</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          A user-friendly interface ensures that both applicants
                          and administrators can navigate the system
                          effortlessly. This reduces training time and minimizes
                          errors.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Intuitive dashboard</li>
                          <li>Easy navigation</li>
                          <li>Clean, clutter-free design</li>
                        </ul>
                        <h4>
                          <strong>Feature 2: Online Application Portal</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          An online application portal allows applicants to
                          submit their forms and documents digitally, making the
                          process more convenient and eco-friendly.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Simple application submission</li>
                          <li>Document upload capabilities</li>
                          <li> Progress tracking for applicants</li>
                        </ul>
                        <h4>
                          <strong>Feature 3: Application Tracking</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Tracking applications in real-time helps
                          administrators stay on top of the admission process,
                          ensuring no application is overlooked.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Status updates</li>
                          <li>notification alter</li>
                          <li>real time tracking</li>
                        </ul>
                        <h4>
                          <strong>Feature 4: Automated Communication</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Automated communication tools ensure that applicants
                          receive timely updates and reminders, enhancing their
                          experience and reducing administrative workload.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Email and SMS notifications</li>
                          <li>Automated responses</li>
                          <li>Customizable templates</li>
                        </ul>
                        <h4>
                          <strong>Customizable templates</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Data analytics provide insights into applicant trends
                          and help improve decision-making processes.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Customizable reports</li>
                          <li>Data visualization tools</li>
                          <li> Applicant statistics</li>
                        </ul>
                        <h4>
                          <strong>
                            Feature 5: Data Analytics and Reporting
                          </strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Data analytics provide insights into applicant trends
                          and help improve decision-making processes.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Customizable reports</li>
                          <li>Data visualization tools</li>
                          <li> Applicant statistics</li>
                        </ul>
                        {/* // */}
                        <h4>
                          <strong>
                            Feature 6: Integration with Other Systems
                          </strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Integration with other school management systems
                          ensures a seamless flow of information and reduces
                          redundant data entry.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Compatibility with existing systems</li>
                          <li>API support</li>
                          <li> Single sign-on (SSO) capabilities</li>
                        </ul>
                        <h4>
                          <strong>Feature 7: Mobile Accessibility</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Mobile accessibility allows applicants to submit and
                          track their applications on the go, increasing
                          convenience and engagement.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Mobile-friendly design</li>
                          <li>Dedicated mobile app</li>
                          <li> Responsive interface</li>
                        </ul>
                        <h4>
                          <strong>Feature 8: Secure Data Management</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Protecting applicant data is crucial for maintaining
                          trust and complying with regulations.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Encryption standards</li>
                          <li>Access controls</li>
                          <li> Data backup and recovery</li>
                        </ul>
                        <h4>
                          <strong>Feature 9: Customizable Workflows</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Every institution has unique admission processes.
                          Customizable workflows ensure the software can adapt
                          to your specific needs.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Flexible workflow creation</li>
                          <li>Conditional logic</li>
                          <li> Customizable forms and fields</li>
                        </ul>
                        <h4>
                          <strong>Feature 10: Support and Training</strong>
                        </h4>
                        <h6>Why It Matters</h6>
                        <p>
                          Having reliable support and training ensures that your
                          staff can effectively use the software and
                          troubleshoot any issues that arise.
                        </p>
                        <h6>What to Look For</h6>
                        <ul>
                          <li>Comprehensive training programs</li>
                          <li>24/7 customer support</li>
                          <li> Knowledge base and resources</li>
                        </ul>
                        <h4>Why Choose Edpedia for Admission Management?</h4>
                        <p>
                          Edpedia is a comprehensive school management software
                          that integrates all these features and more, making it
                          the ideal choice for schools and colleges looking to
                          streamline their admissions process. With Edpedia, you
                          can expect:
                        </p>
                        <ul>
                          <li>
                            A user-friendly interface that simplifies navigation
                            for both administrators and applicants.
                          </li>
                          <li>
                            An online application portal that allows for easy
                            submission and tracking of applications.
                          </li>
                          <li>
                            Automated communication tools to keep applicants
                            informed and engaged.
                          </li>
                          <li>
                            Advanced data analytics and reporting to help you
                            make informed decisions.
                          </li>
                          <li>
                            Seamless integration with other school management
                            systems to ensure a smooth flow of information.
                          </li>
                          <li>
                            Mobile accessibility for convenience and increased
                            engagement.
                          </li>
                          <li>
                            Secure data management to protect sensitive
                            information.
                          </li>
                          <li>
                            Customizable workflows to fit your institution's
                            unique processes.
                          </li>
                          <li>
                            Dedicated support and training to help you get the
                            most out of the software.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-29">
                <aside class="blog-sidebar">
                  <div class="blog-widget">
                    <h4 class="bw-title">Connect for free trial</h4>
                    <div class="bs-cat-list">
                      <Form
                        name="RequestDemo"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={requestDemo}
                      >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="emailId"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Email is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Email"
                                type="email"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone number is required",
                                },
                                {
                                  min: 10,
                                  message: "Please enter valid phone number",
                                },
                                {
                                  max: 10,
                                  message: "Cannot exceed more than 10",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Phone Number"
                                type="number"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="areaOfInterest"
                              rules={[
                                {
                                  required: true,
                                  message: "Area of intrest is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Area of interest"
                              >
                                <Option value="useInstitution">
                                  I want to use it for my Institution
                                </Option>
                                <Option value="resell">
                                  I want to resell it
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="schoolName"
                              rules={[
                                {
                                  required: true,
                                  message: "Institute name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Institute Name"
                              />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="preferredDemoDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Preferred demo date is required",
                                },
                              ]}
                            >
                              <DatePickerFloatLabel
                                className="datepicker"
                                label="Preferred Demo Date"
                                showTime
                                style={{ width: "100%", height: "40px" }}
                                format={dateWithTime}
                                disabledData={disabledStartDate}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: "Location is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Location"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="currentStatusOfERP"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Current status of your ERP is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Current ERP status"
                              >
                                <Option value="notInterested">
                                  Not yet, but I'm interested in learning more
                                </Option>
                                <Option value="freeTrial">
                                  Yes, I currently have a free trial account and
                                  wish to learn more
                                </Option>
                                <Option value="paidAccount">
                                  Yes, I currently have a paid account and wish
                                  to learn more
                                </Option>
                                <Option value="renewAccount">
                                  No, I want to renew my account
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>

                        <Row
                          className="my-row"
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col span={12} align="end">
                            <Space direction="vertical" size="large">
                              <Button
                                className="submit-button "
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                            </Space>
                          </Col>
                          <Col span={12}>
                            <Space direction="vertical" size="large">
                              <Button
                                className="cancel-button "
                                onClick={handleModalCancel}
                              >
                                Cancel
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </aside>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-8">
                <section class=" mb-20 mt-20">
                  <div class="container">
                    <div
                      class=" faq-bg"
                      data-background="assets/img/bg/cta_bg.jpg"
                    >
                      <div class="row align-items-center">
                        <h4>
                          <strong>FAQs</strong>
                        </h4>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              What is admission management software?
                            </strong>
                          </h5>
                          <p>
                            Admission management software is a digital tool
                            designed to streamline and automate the application
                            and enrollment process for educational institutions.
                          </p>
                        </div>
                        <div className="faq-content">
                          {" "}
                          <h5>
                            <strong>
                              How does admission management software benefit
                              schools and colleges?
                            </strong>
                          </h5>
                          <p>
                            It enhances efficiency, reduces manual errors,
                            improves communication with applicants, provides
                            valuable data insights, and ensures secure data
                            management.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              Is Edpedia suitable for all types of educational
                              institutions?
                            </strong>
                          </h5>
                          <p>
                            Yes, Edpedia is designed to cater to the needs of
                            various educational institutions, including schools,
                            colleges, and universities. Its features are
                            customizable to fit different requirements.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              Can Edpedia integrate with our existing systems?
                            </strong>
                          </h5>
                          <p>
                            Yes, Edpedia offers robust integration capabilities
                            to ensure seamless data flow between different
                            systems.
                          </p>
                        </div>
                        <div className="faq-content">
                          <h5>
                            <strong>
                              How secure is Edpedia for managing sensitive
                              applicant data?
                            </strong>
                          </h5>
                          <p>
                            Edpedia employs advanced security measures,
                            including encryption and access controls, to protect
                            sensitive information and ensure compliance with
                            data protection regulations. In conclusion, choosing
                            the right admission management software is crucial
                            for streamlining your admissions process and
                            enhancing your institution's efficiency. With its
                            comprehensive features and dedicated support,
                            Edpedia stands out as a top choice for schools and
                            colleges. Explore Edpedia today and transform your
                            admissions process!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondBlog;
