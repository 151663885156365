import React, { useState } from "react";
import logo from "../assets/img/images/ed.svg";
import { Link } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [expandedSubMenu, setExpandedSubMenu] = useState(null);

  const toggleMenu = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  const toggleSubMenu = (subMenu) => {
    setExpandedSubMenu(expandedSubMenu === subMenu ? null : subMenu);
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="top-row">
        <div className="nav-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="close-btn" onClick={toggleSidebar}>
          <i className="fas fa-times"></i>
        </div>
      </div>
      <div className="menu-box">
        <nav className="menu-nav">
          <ul className="navigation">
            <li>
              <Link to="/whyedpedia" onClick={toggleSidebar}>
                Why Edpedia
              </Link>
            </li>
            <li
              className={`menu-item-has-children ${
                expandedMenu === "modules" ? "expanded" : ""
              }`}
            >
              <Link to="#" onClick={() => toggleMenu("modules")}>
                Modules{" "}
                <i
                  className={`fas ${
                    expandedMenu === "modules"
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                  }`}
                ></i>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/modules_features" onClick={toggleSidebar}>
                    Explore All Modules
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/features" onClick={toggleSidebar}>
                Features
              </Link>
            </li>
            <li>
              <Link to="/benefits" onClick={toggleSidebar}>
                Benefits
              </Link>
            </li>
            <li>
              <Link to="/prices" onClick={toggleSidebar}>
                Prices
              </Link>
            </li>
            <li
              className={`menu-item-has-children ${
                expandedMenu === "integration" ? "expanded" : ""
              }`}
            >
              <Link to="#" onClick={() => toggleMenu("integration")}>
                Integration{" "}
                <i
                  className={`fas ${
                    expandedMenu === "integration"
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                  }`}
                ></i>
              </Link>
              <ul className="sub-menu">
                <li
                  className={`menu-item-has-children ${
                    expandedSubMenu === "lms" ? "expanded" : ""
                  }`}
                >
                  <Link to="#" onClick={() => toggleSubMenu("lms")}>
                    LMS Integration{" "}
                    <i
                      className={`fas ${
                        expandedSubMenu === "lms"
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        Zoom Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        Google Meet
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`menu-item-has-children ${
                    expandedSubMenu === "admission" ? "expanded" : ""
                  }`}
                >
                  <Link to="#" onClick={() => toggleSubMenu("admission")}>
                    Admission Integration{" "}
                    <i
                      className={`fas ${
                        expandedSubMenu === "admission"
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        Lead Square CRM
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        Meritto
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`menu-item-has-children ${
                    expandedSubMenu === "payment" ? "expanded" : ""
                  }`}
                >
                  <Link to="#" onClick={() => toggleSubMenu("payment")}>
                    Payment Integration{" "}
                    <i
                      className={`fas ${
                        expandedSubMenu === "payment"
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        Tally
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        PG
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`menu-item-has-children ${
                    expandedSubMenu === "attendance" ? "expanded" : ""
                  }`}
                >
                  <Link to="#" onClick={() => toggleSubMenu("attendance")}>
                    Attendance Integration{" "}
                    <i
                      className={`fas ${
                        expandedSubMenu === "attendance"
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        Biometrics
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={toggleSidebar}>
                        RFID
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/" onClick={toggleSidebar}>
                    Explore All Integration
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`menu-item-has-children ${
                expandedMenu === "blog" ? "expanded" : ""
              }`}
            >
              <Link to="#" onClick={() => toggleMenu("blog")}>
                Blog{" "}
                <i
                  className={`fas ${
                    expandedMenu === "blog"
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                  }`}
                ></i>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/events" onClick={toggleSidebar}>
                    Events
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
