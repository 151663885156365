import React, { useEffect } from "react";
import request from "../assets/img/images/h2_request_shape01.png";
import right_arrow from "../assets/img/icons/right-arrow.svg";
import attendance from "../assets/img/icons/attendance.svg";
import exam from "../assets/img/icons/exam.svg";
import admission from "../assets/img/icons/admission.svg";
import fee_management from "../assets/img/icons/fee_management.svg";
import transport from "../assets/img/icons/trasport.svg";
import inventry from "../assets/img/icons/inventory.svg";
import student from "../assets/img/icons/student.svg";
import parent from "../assets/img/icons/parent.svg";
import onetone from "../assets/img/icons/onetoone.svg";
import syllbus from "../assets/img/icons/syllabus.svg";
import tutor from "../assets/img/icons/tutor.svg";
import interview from "../assets/img/icons/interview.svg";
import time from "../assets/img/icons/time.svg";
import dashboard from "../assets/img/icons/dashboard.svg";
import announcement from "../assets/img/icons/announcements.svg";
import notification from "../assets/img/icons/notification.svg";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import study from "../assets/img/icons/study_meterial.svg";
import sub_teachet from "../assets/img/icons/sub_teacher.svg";
import cultural from "../assets/img/icons/cultural_activities.svg";
import birthday from "../assets/img/icons/birthday.svg";
import camping from "../assets/img/icons/camping.svg";
import multi from "../assets/img/icons/multi.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
const Feature = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section class="breadcrumb-area text-left ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Technical Support
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="services-area-seven pt-50 pb-90 mt-10">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two text-center mb-50">
                  <span class="sub-title">What We Do For You</span>
                  <h2
                    class="title"
                    data-aos="fade-left"
                    data-aos-duration="3000"
                  >
                    Features In EdPedia
                  </h2>
                  <p>
                    Edpedia has a wide range of features which helps many
                    Schools, Colleges, Institutes, Univercities in Assemble
                    manner These are some of the Important features of Edpedia.
                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={multi} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Multi Branching Management</h2>
                    <p>
                      Multi Branching Management is a comprehensive feature that
                      allows you to...{" "}
                    </p>
                    <Link to="/multi-branch-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={attendance} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Attendance Management</h2>
                    <p>
                      Attendance Management is a comprehensive feature
                      designed...{" "}
                    </p>
                    <Link to="/attendance-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={exam} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Exam Management</h2>
                    <p>
                      Exam Management is a comprehensive feature that
                      facilitates the efficient planning,..
                    </p>
                    <Link to="/exam-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={admission} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Admission Management</h2>
                    <p>
                      It is comprehensive feature that assists educational
                      institutions in efficiently...
                    </p>
                    <Link to="/admission-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={fee_management} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Fee Management</h2>
                    <p>
                      Fee Management is a crucial component of educational
                      institutions' administrative processes, enabling
                      effective...
                    </p>
                    <Link to="/fee-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={transport} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Transport Management</h2>
                    <p>
                      Transport Management is an essential aspect of educational
                      institutions...
                    </p>
                    <Link to="/transport-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={inventry} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Inventory Management</h2>
                    <p>
                      Inventory Management refers to the process of efficiently
                      overseeing and controlling...
                    </p>
                    <Link to="/inventry-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={student} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Students Management</h2>
                    <p>
                      Student Management in the context of school administration
                      refers to the comprehensive approach ...
                    </p>
                    <Link to="/student-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={parent} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Parent Management</h2>
                    <p>
                      Parent Management refers to the processes and activities
                      involved in effectively engaging and collaborating with...
                    </p>
                    <Link to="/parent-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={onetone} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">1 : 1 Management</h2>
                    <p>
                      1:1 Management, also known as One-to-One Management,
                      refers to the practice of providing individualized...
                    </p>
                    <Link to="/meeting-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={syllbus} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Syllabus Management</h2>
                    <p>
                      Syllabus Management refers to the process of organizing,
                      planning, and managing the curriculum...
                    </p>
                    <Link to="/syllabus-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={tutor} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Tutor Management</h2>
                    <p>
                      Tutor Management involves the effective coordination,
                      organization, and support of tutors...
                    </p>
                    <Link to="/tutor-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={interview} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Interview Management</h2>
                    <p>
                      Interview Management refers to the systematic and
                      organized process of ...
                    </p>
                    <Link to="/interview-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={time} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Time Table Management</h2>
                    <p>
                      Time Table Management refers to the process of creating,
                      organizing, and...
                    </p>
                    <Link to="/timetable-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={dashboard} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Dashboard Management</h2>
                    <p>
                      Time Table Management refers to the systematic
                      organization and scheduling of academic...
                    </p>
                    <Link to="/dashboard-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={announcement} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Announcement Management</h2>
                    <p>
                      {" "}
                      is the process of planning, creating, and delivering
                      important announcements...
                    </p>
                    <Link to="/announcement-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={notification} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title"> Notification Management</h2>
                    <p>
                      Notification Management refers to the systematic approach
                      of handling ...
                    </p>
                    <Link to="/notification-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={study} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Study Material Management</h2>
                    <p>
                      Study Material Management refers to the systematic
                      organization, ...
                    </p>
                    <Link to="/studymaterial-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={sub_teachet} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Subject & Teacher Management</h2>
                    <p>
                      Subject & Teacher Management refers to the processes and
                      systems in place...
                    </p>
                    <Link to="/teacher-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={cultural} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title"> Cultural Activity Management</h2>
                    <p>
                      {" "}
                      Cultural Activity Management involves the planning,
                      organization...
                    </p>
                    <Link to="/culturalactivity-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={birthday} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title"> Birthday Alert Management</h2>
                    <p>
                      Birthday Alert Management refers to the process of
                      managing and organizing ...
                    </p>
                    <Link to="/birthdayalert-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Camping <br /> Management
                    </h2>
                    <p>
                      Educational (ERP) management system software for all
                      educational Instutions...
                    </p>
                    <Link to="/camping-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Student <br /> Promotions
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/studentpromotion-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Expense
                      <br /> Management
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/expense-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      School <br /> Policies
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/school-policies-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>{" "}
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Departments <br /> Management
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/department-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Class & Section <br /> Management
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/class-subject-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Exam Management <br /> (AI integrated)
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/aiexam-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Exam Result</h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/examsresult-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Designations <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/designation-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Accounts <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/accounts-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Student Attendance <br />
                      (Manually) <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link
                      to="/studentattandancemanually-management"
                      class="link-btn"
                    >
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Student Attendance <br /> (Biometric/face recognition)
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link
                      to="/studentattandancebiometric-management"
                      class="link-btn"
                    >
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Payroll Management <br />
                      System <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/payroll-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Holiday <br />
                      Management <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/holiday-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Communication <br />
                      (In App) <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/communicationapp-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Communication <br />
                      (WhatsApp, Email) <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/communication-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Sports <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/sports-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Hostel <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/hostel-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Summer <br />
                      Camp
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/summercamp-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      User <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/user-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      HR <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/hr-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Leave <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/leave-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Social Media
                      <br />
                      integration
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/socialmedia-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Reports
                      <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/reports-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      EdPedia Virtual
                      <br />
                      Store
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/virtualstore-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Front
                      <br />
                      Office
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/frontoffice-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Homework
                      <br />
                      Management
                      <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/homework-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Digital Content
                      <br />
                      (Zoom/Google/Online videos) <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/digital-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Global
                      <br />
                      Search <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/global-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      All staff
                      <br />
                      feature <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/allstaff-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      Mobile
                      <br />
                      App <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/mobileapp-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">
                      All student/parent
                      <br />
                      module features <br />
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/parentmodule-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">AI features of library</h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/aifeature-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">All features of transport module</h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/transportmodule-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three  bg-opacity-10">
                  <div class="features-icon-three iconssm">
                    <img src={camping} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Driver mobile app</h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable{" "}
                    </p>
                    <Link to="/drivermobile-management" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Feature;
