import React from "react";
// import about_img_2 from "../../assets/img/admission1.jpg";
import about_img_3 from "../../assets/img/admission1.jpg";
import about_shape04 from "../../assets/img/images/about_shape01.png";
import about_shape05 from "../../assets/img/images/about_shape01.png";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import check from "../../assets/img/icons/check_icon.svg";
import { Link } from "react-router-dom";

const AdmissionManagement = () => {
  return (
    <main class="fix">
      <section class="breadcrumb-area text-left ">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/features">Features</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Exam Management
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section class="about-area pt-50 pb-50">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="about-img-wrap">
                <img src={about_img_3} alt="" class="main-img" />
                <img src={about_img_shape1} alt="" />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="about-content">
                <div class="section-title mb-25 tg-heading-subheading animation-style2">
                  <h2 class="title">Admission Management</h2>
                </div>
                <p>
                  Admission Management is a critical aspect of educational
                  institutions, encompassing the entire process from application
                  submission to enrollment. EdPedia's Admission Management
                  feature offers a comprehensive solution to streamline these
                  processes and enhance the overall admission experience for
                  both applicants and administrators.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="features-area-five features-bg multi-feature-bg"
        //   data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <div class="section-title-two mb-30 text-center">
                <h3 class="title">
                  Key Aspects ofAdmission Management Include
                </h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">Online Application Submission</h2>
                  </div>
                  <p>
                    EdPedia simplifies the admissions process by providing a
                    secure online platform where applicants can submit their
                    applications conveniently from anywhere, eliminating the
                    need for paper-based forms. This feature enhances
                    accessibility and reduces administrative burden.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">Automated Document Verification</h2>
                  </div>
                  <p>
                    With EdPedia's automated document verification feature,
                    institutions can streamline the verification process by
                    digitally validating applicant documents such as
                    transcripts, certificates, and recommendation letters. This
                    ensures accuracy and expedites application review.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Applicant Tracking and Analytic</h2>
                  </div>
                  <p>
                    EdPedia provides administrators with real-time insights and
                    analytics on applicant demographics, application status, and
                    conversion rates. This enables institutions to track the
                    progress of each applicant throughout the admission cycle
                    and make data-driven decisions to optimize enrollment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src="assets/img/images/features_shape01.png" alt="" />
          <img src="assets/img/images/features_shape02.png" alt="" />
        </div>
      </section>
    </main>
  );
};

export default AdmissionManagement;
