import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllPlansQuery } from "../RTKQuery/RtkQuery";
import { BiRupee } from "react-icons/bi";
import check_icon from "../assets/img/icons/check_icon02.svg";
import { data } from "../Common/Data";
import AOS from "aos";
const Price = () => {
  // const { data } = useGetAllPlansQuery();
  const [planData, setPlanData] = useState(data);
  const [selectedDuration, setSelectedDuration] = useState("MONTH");

  // useEffect(() => {
  //   if (data) {
  //     setPlanData(data.data.slice(0, 3));
  //   }
  // }, [data]);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };

  return (
    <>
      <button className="scroll-top scroll-to-target" data-target="html">
        <i className="fas fa-angle-up"></i>
      </button>
      <main className="fix">
        <section className="breadcrumb-area text-left">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Prices
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-wrap">
            <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
            <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
          </div>
        </section>

        <section className="pricing-area-two">
          <div className="pricing-shape">
            <img
              src="assets/img/images/pricing_shape.png"
              alt=""
              data-aos="fade-left"
              data-aos-delay="200"
              className="aos-init aos-animate"
            />
          </div>
          <div className="container">
            <div className="row align-items-center text-center justify-content-center">
              <div className="col-lg-8">
                <div className="section-title-two mb-50 tg-heading-subheading animation-style3">
                  <span className="sub-title">Price / Plans</span>
                  <h2 className="title tg-element-title">
                    <div
                      className="split-line price-text"
                      data-aos="fade-left"
                      data-aos-duration="3000"
                    >
                      We take some information from institutes before finalise
                      the prices.
                    </div>
                  </h2>
                </div>
              </div>
            </div>
            <div className="pricing-tabs">
              <button
                className={`btn ${
                  selectedDuration === "MONTH" ? "active" : ""
                }`}
                onClick={() => handleDurationChange("MONTH")}
              >
                Monthly
              </button>

              <button
                className={`btn ${
                  selectedDuration === "YEARLY" ? "active" : ""
                }`}
                onClick={() => handleDurationChange("YEARLY")}
              >
                Yearly
              </button>
            </div>
            <div className="pricing-item-wrap">
              <div className="row justify-content-center">
                {planData.map((item) => (
                  <div className="col-lg-4 col-md-6 col-sm-10" key={item.id}>
                    <div className="pricing-box-two">
                      <div className="pricing-head-two">
                        <h4 className="title">{item.planName}</h4>
                        <div className="pricing-price-two">
                          {Object.entries(item.durationAmountMap)
                            .filter(
                              ([duration]) => duration === selectedDuration
                            )
                            .map(([duration, amount]) => (
                              <h2 className="price" key={duration}>
                                <strong>
                                  {" "}
                                  <BiRupee />
                                </strong>
                                {amount}
                                <span>
                                  {" "}
                                  /{duration.replace(/_/g, " ").toUpperCase()} /
                                  500 Students
                                </span>
                              </h2>
                            ))}
                        </div>
                      </div>
                      <div className="pricing-bottom">
                        <div className="pricing-list">
                          <ul className="list-wrap">
                            {item.subscriptionPlanFeatures.map((feature) => (
                              <li key={feature.featureId}>
                                <img src={check_icon} alt="" />
                                {feature.featureName}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="pricing-btn-two">
                          <Link to="/contact" className="btn">
                            Get The Plan Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Price;
