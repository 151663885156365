import { notification } from 'antd'

export const errorNotification = (message) => {
    notification.error({
        message,
        // icon: <DislikeOutlined style={{ color: "red" }} />,
        duration: 3,
        placement: "bottomRight",
    });
}

export const successNotification = (message) => {
    notification.success({
        message,
        // icon: <CheckCircleFilled
        //     style={{
        //         color: "green",
        //     }}
        // />,
        duration: 3,
        placement: "bottomRight",
    });
}