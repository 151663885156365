import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";

import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
const Onlinepayment = () => {
  return (
    <>
      {" "}
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Explore All Modules
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section
        class="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6">
              <div class="section-title text-center mb-50">
                <h2 class="title">Online Payment Benefits</h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">Secure Transactions</h2>
                  </div>
                  <p>
                    Ensure the safety of all financial transactions with
                    EdPedia's secure online payment system. Our platform uses
                    advanced encryption and security measures to protect
                    sensitive data, providing peace of mind for both
                    institutions and users.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">Convenient Payment Options</h2>
                  </div>
                  <p>
                    Offer a variety of payment methods to cater to the needs of
                    parents and students. With EdPedia, users can choose from
                    credit/debit cards, bank transfers, and other online payment
                    options, ensuring flexibility and convenience.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Automated Receipts</h2>
                  </div>
                  <p>
                    Simplify the payment process with automated receipts and
                    confirmations. EdPedia's system immediately generates and
                    sends digital receipts for every transaction, ensuring
                    transparency and efficient record-keeping for both the
                    institution and users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src={feature_01} alt="" />
          <img src={feature_02} alt="" />
        </div>
      </section>
    </>
  );
};

export default Onlinepayment;
