import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";

import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";
import { Link } from "react-router-dom";
const WellDesignedMoedule = () => {
  return (
    <>
      <section className="breadcrumb-area text-left">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Any Time Any Ware Accessibility
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="Breadcrumb Shape 1" />
          <img src={breadcrumb_shape2} alt="Breadcrumb Shape 2" />
        </div>
      </section>
      <section
        className="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6">
              <div className="section-title text-center mb-50">
                <h2 className="title">Any Time Any Ware Accessibility</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className="flaticon-global"></i>
                    </div>
                    <h2 className="title">Global Accessibility</h2>
                  </div>
                  <p>
                    Access our platform from anywhere in the world. Our
                    cloud-based system ensures that you can log in and manage
                    your work from any device with an internet connection, at
                    any time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className="flaticon-responsive"></i>
                    </div>
                    <h2 className="title">Cross-Device Compatibility</h2>
                  </div>
                  <p>
                    Our platform is fully responsive and compatible with all
                    devices. Whether you're using a smartphone, tablet, or
                    desktop, you can enjoy a seamless experience and access all
                    features without any hassle.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className="flaticon-time"></i>
                    </div>
                    <h2 className="title">24/7 Availability</h2>
                  </div>
                  <p>
                    Our platform is available 24/7, ensuring that you can access
                    the resources and tools you need at any time. No matter what
                    time zone you are in, our services are always at your
                    disposal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features-shape-wrap">
          <img src={feature_01} alt="Feature Shape 1" />
          <img src={feature_02} alt="Feature Shape 2" />
        </div>
      </section>
    </>
  );
};

export default WellDesignedMoedule;
