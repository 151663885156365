import blog_1 from "../../assets/img/blog/h3_blog_img01.jpg";
import blog_2 from "../../assets/img/blog/blog_avatar01.png";
import blog_3 from "../../assets/img/blog/h3_blog_img02.jpg";
import blog_4 from "../../assets/img/blog/blog_avatar02.png";
import blog_5 from "../../assets/img/blog/h3_blog_img03.jpg";
import blog_6 from "../../assets/img/blog/blog_avatar03.png";
import blog_7 from "../../assets/img/blog/h3_blog_img04.jpg";
import blog_8 from "../../assets/img/blog/blog_avatar04.png";
import blog_9 from "../../assets/img/blog/h3_blog_img05.jpg";
import blog_10 from "../../assets/img/blog/blog_avatar05.png";
import blog_11 from "../../assets/img/blog/h3_blog_img06.jpg";
import blog_12 from "../../assets/img/blog/blog_avatar06.png";
export const blogData = [
  {
    id: 1,
    path: "ai-education",
    category: "AI in Education",
    image: blog_1,
    title: "How AI is Transforming Education Management",
    description:
      "Explore the impact of AI on education management and how EdPedia is leading the charge.",
    details:
      "Artificial intelligence is revolutionizing education management by automating administrative tasks, personalizing learning experiences, and providing data-driven insights. EdPedia utilizes advanced AI algorithms to enhance the efficiency and effectiveness of educational institutions. From student performance analytics to automated scheduling and resource allocation, discover how EdPedia is at the forefront of this transformation.",
    author: {
      name: "Kat Doven",
      image: blog_10,
    },
    date: "15 Feb, 2024",
  },
  {
    id: 2,
    path: "case-studies",
    category: "Case Studies",
    image: blog_3,
    title: "Success Stories: Schools Thriving with EdPedia",
    description:
      "Read about the success stories of institutions that have transformed their operations with EdPedia.",
    details:
      "EdPedia has been instrumental in driving success for various educational institutions. This blog post dives into detailed case studies of schools and universities that have significantly improved their operational efficiency and student outcomes using EdPedia. Learn about their challenges, the solutions implemented, and the remarkable results achieved.",
    author: {
      name: "Floyd Miles",
      image: blog_11,
    },
    date: "10 Mar, 2024",
  },
  // {
  //   id: 3,
  //   category: "Product Updates",
  //   path: "product-spring",
  //   image: blog_3,
  //   title: "New Features in EdPedia: Spring 2024",
  //   description:
  //     "Discover the latest features and improvements in the EdPedia platform.",
  //   details:
  //     "Spring 2024 brings a host of new features to the EdPedia platform. In this update, we introduce enhanced AI-driven analytics, improved user interface, and new tools for better resource management. These updates are designed to provide educators with more powerful tools to manage their institutions effectively and to offer students a more personalized learning experience.",
  //   author: {
  //     name: "Bessie Cooper",
  //     image: blog_3,
  //   },
  //   date: "20 Apr, 2024",
  // },
  // {
  //   id: 4,
  //   category: "AI in Education",
  //   path: "ai-education",
  //   image: blog_7,
  //   title: "The Future of Education: AI-Driven Learning",
  //   description:
  //     "An in-depth look at how AI is shaping the future of education and learning experiences.",
  //   details:
  //     "AI-driven learning is not just a trend; it's the future of education. This article explores the various ways AI is being integrated into learning environments to create more engaging, personalized, and effective educational experiences. From adaptive learning platforms to AI tutors, see how the landscape of education is changing and what it means for students and educators alike.",
  //   author: {
  //     name: "Devon Lane",
  //     image: blog_5,
  //   },
  //   date: "5 May, 2024",
  // },
  // {
  //   id: 5,
  //   category: "Customer Insights",
  //   path: "customer-nsights",
  //   image: blog_5,
  //   title: "How EdPedia Enhances Student Engagement",
  //   description:
  //     "Learn how EdPedia's AI tools help in increasing student engagement and improving outcomes.",
  //   details:
  //     "Student engagement is a critical factor in educational success, and EdPedia's AI tools are designed to maximize it. This blog post delves into the specific features of EdPedia that help keep students engaged, such as personalized learning paths, real-time feedback, and interactive content. Discover the positive impact these tools have had on student participation and achievement.",
  //   author: {
  //     name: "Annette Black",
  //     image: blog_6,
  //   },
  //   date: "25 May, 2024",
  // },
  // {
  //   id: 6,
  //   category: "Technology Trends",
  //   image: blog_11,
  //   path: "technology-trends",
  //   title: "AI Trends in Education Technology",
  //   description:
  //     "Stay updated with the latest AI trends in the education technology sector and how EdPedia is adapting.",
  //   details:
  //     "The education technology sector is rapidly evolving, with AI at its core. This article highlights the latest trends in AI-driven education technology, including predictive analytics, AI-powered content creation, and intelligent tutoring systems. Learn how EdPedia is incorporating these trends into its platform to stay ahead of the curve and continue providing cutting-edge solutions to educators and students.",
  //   author: {
  //     name: "Eleanor Pena",
  //     image: blog_7,
  //   },
  //   date: "10 Jun, 2024",
  // },
];
