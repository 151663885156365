import React from "react";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import about1 from "../../assets/img/inventry.jpg";

import { Link } from "react-router-dom";
const InventryManagement = () => {
  return (
    <main class="fix">
      <section class="breadcrumb-area text-left ">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/features">Features</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Inventry Management
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section class="about-area pt-50 pb-50">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="about-img-wrap">
                <img src={about1} alt="" class="main-img" />
                <img src={about_img_shape1} alt="" />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="about-content">
                <div class="section-title mb-25 tg-heading-subheading animation-style2">
                  <h2 class="title">Inventry Management</h2>
                </div>
                <p>
                  Inventory Management is crucial for educational institutions
                  to track and manage physical assets such as equipment,
                  textbooks, and supplies efficiently. EdPedia's Inventory
                  Management feature offers a comprehensive solution to
                  streamline these processes and ensure optimal resource
                  utilization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="features-area-five features-bg multi-feature-bg"
        //   data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <div class="section-title-two mb-30 text-center">
                <h3 class="title">
                  Key Aspects of Inventry Management Include
                </h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">Asset Tracking and Identification</h2>
                  </div>
                  <p>
                    EdPedia provides a centralized database where administrators
                    can catalog and track all institutional assets using unique
                    identifiers such as barcodes or RFID tags. This feature
                    enables real-time asset tracking and simplifies inventory
                    audits and reconciliation.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">
                      Inventory Optimization and Replenishment{" "}
                    </h2>
                  </div>
                  <p>
                    With EdPedia's inventory optimization tools, institutions
                    can analyze usage patterns, forecast demand, and set reorder
                    points for essential items. This feature minimizes
                    stockouts, reduces excess inventory, and ensures timely
                    replenishment of supplies.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Reporting and Analytics</h2>
                  </div>
                  <p>
                    EdPedia generates comprehensive reports and analytics on
                    inventory levels, usage trends, and asset lifecycle
                    management. This feature provides insights into inventory
                    performance, cost analysis, and areas for improvement,
                    enabling informed decision-making and resource allocation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src="assets/img/images/features_shape01.png" alt="" />
          <img src="assets/img/images/features_shape02.png" alt="" />
        </div>
      </section>
    </main>
  );
};

export default InventryManagement;
