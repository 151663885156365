import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import about_01 from "../assets/img/images/h4_about_img01.jpg";
import about_02 from "../assets/img/images/h4_about_img02.jpg";
import feature_01 from "../assets/img/images/features_shape01.png";
import feature_02 from "../assets/img/images/features_shape02.png";
import bg_shape from "../assets/img/images/h4_about_shape.png";
import CountUp from "react-countup";
import AOS from "aos";
const WhyEdpedia = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Why EdPedia
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="pt-50 pb-100">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-8">
                <div class="about-img-wrap-five about-img-wrap-ten">
                  <img src={about_01} alt="" />
                  {/* <img
                    src={about_02}
                    alt=""
                    data-parallax='{"y" : 100 }'
                    className="custom-transform "
                    // style="transform:translate3d(0px, 35.944px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(0px, 35.944px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); "
                  /> */}
                  <div class="experience-wrap">
                    <h2 class="title">
                      100%<span>Data Accurency</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content-five about-content-ten">
                  <div class="section-title-two mb-30">
                    <span class="sub-title">Get To know US</span>
                    <h2 class="title">Why EdPedia</h2>
                  </div>
                  <p>
                    Educational (ERP) management system software for
                    institutions to manage daily activities like students
                    records, examination, fees and expense management. We have
                    different logins and dashboards for Admin, teachers,
                    students, and parents. The software is online. Student
                    enrollment is very easy to manage and use throughout our
                    software
                  </p>
                  <h3>Who uses Edpedia?</h3>
                  <p> Schools, Colleges and Universities</p>

                  <div class="about-success-wrap">
                    <ul class="list-wrap">
                      <li>
                        <div class="icon">
                          <i class="flaticon-family"></i>
                        </div>
                        <div class="content">
                          <h2 class="count">
                            <CountUp end={180} start={0} delay={4} />+
                          </h2>
                          <p>Happy Institutions</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="about-shape-five">
            <img src={bg_shape} alt="" />
          </div>
        </section>

        <section
          class="features-area-five features-bg"
          data-background="assets/img/bg/features_bg.jpg"
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6">
                <div class="section-title-two mb-30 text-center">
                  <span class="sub-title">Why EdPedia</span>
                  <h2 class="title">Here's Stands out from the Crowd</h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">Centralized Management</h2>
                    </div>
                    <p>
                      Bid farewell to the hassle of managing multiple software
                      solutions. EdPedia offers a centralized system that
                      combines all essential modules, including student
                      enrollment, academic management, attendance tracking,
                      grading, financial management, HR, and more. Say goodbye
                      to data silos and enjoy a unified approach to education
                      administration.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Seamless Collaboration</h2>
                    </div>
                    <p>
                      EdPedia promotes seamless collaboration among teachers,
                      administrators, students, and parents. Through its
                      integrated communication tools, everyone involved in the
                      educational journey can easily share information, exchange
                      feedback, and stay connected, fostering a productive and
                      engaging learning environment.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Enhanced Student Experience</h2>
                    </div>
                    <p>
                      EdPedia puts students at the forefront, providing a range
                      of features to support their academic journey. From online
                      registration and course selection to personalized learning
                      plans and progress tracking, EdPedia empowers students to
                      take ownership of their education while receiving the
                      guidance they need to succeed.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Data-Driven Insights</h2>
                    </div>
                    <p>
                      With EdPedia's robust analytics and reporting
                      capabilities, educational institutions gain valuable
                      insights into their operations and student performance. By
                      harnessing the power of data, institutions can make
                      informed decisions, identify areas for improvement, and
                      ultimately enhance their overall educational outcomes.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Scalable and Customizable</h2>
                    </div>
                    <p>
                      EdPedia understands that every educational institution is
                      unique. Our solution is highly scalable and customizable
                      to meet the specific needs and requirements of your
                      institution. Whether you're a small school or a large
                      university, EdPedia adapts to your size and grows with you
                      as your institution flourishes. Unlock the full potential
                      of your educational institution with EdPedia and embark on
                      a journey towards streamlined administration, improved
                      collaboration, and exceptional learning experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src={feature_01} alt="" />
            <img src={feature_02} alt="" />
          </div>
        </section>

        <section class="pt-100 pb-100">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6">
                <div class="section-title-two mb-30 text-center">
                  <span class="sub-title">Features</span>
                  <h2 class="title">Compare with Edpedia and Other ERP</h2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <table class="table-responsive">
                  <tr>
                    <th>Features</th>
                    <th>Other ERP</th>
                    <th>Edpedia</th>
                  </tr>
                  <tr>
                    <td>Superior Customers Support</td>
                    <td>No</td>
                    <td>24/7 Support</td>
                  </tr>
                  <tr>
                    <td>Cucompermised Data Accurency</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Robust Data Privachy Measures</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>Tailored Feature Customization</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Seamless Application Scalability</td>
                    <td>Go down if 100 users login at same time</td>
                    <td>Never go down</td>
                  </tr>
                  <tr>
                    <td>Effortless Whatsapp Integration</td>
                    <td>No</td>
                    <td>Bulk Message</td>
                  </tr>
                  <tr>
                    <td>Future - Ready Technology</td>
                    <td>No</td>
                    <td>Microservice</td>
                  </tr>
                  <tr>
                    <td>Streamlined User Experience</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Comprehensive Training and Resources</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Proved Track Recor of sucess</td>
                    <td>No</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Cloud Platform</td>
                    <td>Physical servers which can crash at any time</td>
                    <td>AWS</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default WhyEdpedia;
