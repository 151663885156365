import React, { useState } from 'react'

import "../Components/EdpediaStore.css";

import BiometricImage from "../assets/img/EdpediaStoreImages/biometric device.jpeg";
import ShoesImage from "../assets/img/EdpediaStoreImages/shoes.jpeg";
import IdCardImage from "../assets/img/EdpediaStoreImages/id cards.jpeg";
import BooksImage from "../assets/img/EdpediaStoreImages/books.jpeg";
import BagsImage from "../assets/img/EdpediaStoreImages/bags.jpeg";
import UniformImage from "../assets/img/EdpediaStoreImages/uniform.jpeg";
import StationaryImage from "../assets/img/EdpediaStoreImages/stationary.jpeg";
import InteractivePanelImage from "../assets/img/EdpediaStoreImages/interactive-panels.jpg";
import CustomerSupportImage from "../assets/img/EdpediaStoreImages/customer-support.jpeg";
import StoreItem from '../Components/StoreItem';
import QuoteModal from '../Components/QuoteModal';
import { SUPPORT } from '../Common/utils/stringConstants';

const updatesCardList = [
    {
        id: 1,
        src: InteractivePanelImage,
        name: "Interactive Panels",
        description: "For effective teaching and advanced learning of concepts, use interactive panels for more accurate knowledge transfer.",
        isHighlighted: true,
    },
    {
        id: 2,
        src: BiometricImage,
        name: "Biometric Device",
        description: "An easy way to monitor attendance and track check-in and check-out timings.",
        isHighlighted: false,
    },
    {
        id: 3,
        src: IdCardImage,
        name: "Identity Cards",
        description: "A wide variety of designed identity cards featuring laminated images and detailed information.",
        isHighlighted: false,
    },
    {
        id: 4,
        src: BooksImage,
        name: "Books",
        description: "All types of subject textbooks, notes, and diaries are available, along with limited edition books featuring customized school covers.",
        isHighlighted: false,
    },
    {
        id: 5,
        src: BagsImage,
        name: "Bags",
        description: "Diverse sizes of bags for all school classes, suitable for both boys and girls.",
        isHighlighted: false,
    },
    {
        id: 6,
        src: UniformImage,
        name: "Uniform",
        description: "Bring your students together with a unified uniform.",
        isHighlighted: false,
    },
    {
        id: 7,
        src: ShoesImage,
        name: "School Shoes",
        description: "A wide variety of designed uniform shoes for school and college students, suitable for both boys and girls.",
        isHighlighted: false,
    },
    {
        id: 8,
        src: StationaryImage,
        name: "Stationary",
        description: "Everyday school supplies, such as pencils, pens, erasers, and more, are available in one convenient location.",
        isHighlighted: false,
    },
    {
        id: 9,
        src: CustomerSupportImage,
        name: SUPPORT,
        description: "For more information or any queries contact EdPedia Administration. Mobile:+917801064510 Email:contact@eaglemountsoft.com",
        isHighlighted: true,
    },
];

const EdpediaStore = () => {
    const [quoteModal, setQuoteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    return (
        <main className="fix">
            <section className="breadcrumb-area text-left">
                <div className="modal-store-items-container">
                    {updatesCardList?.map((item) => (
                        <StoreItem
                            key={item?.id}
                            item={item}
                            setQuoteModal={setQuoteModal}
                            setSelectedItem={setSelectedItem}
                        />
                    ))}
                </div>
                {quoteModal && (
                    <QuoteModal
                        quoteModal={quoteModal}
                        setQuoteModal={setQuoteModal}
                        item={selectedItem}
                    />
                )}
            </section>
        </main>
    )
}

export default EdpediaStore