import React, { useEffect } from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import blog_details_1 from "../../assets/img/book1.jpg";
import avatar1 from "../../assets/img/blog/blog_avatar01.png";
import post1 from "../../assets/img/book1.jpg";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import post2 from "../../assets/img/book2.jpg";
import post3 from "../../assets/img/book1.jpg";
import post4 from "../../assets/img/book2.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";

const SyllabusManagement = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <main class="fix">
        <section class="breadcrumb-area text-left ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/features">Features</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Syllabus Management
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area pt-50 pb-50">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <div class="about-img-wrap">
                  <img src={blog_details_1} alt="" class="main-img" />
                  <img src={about_img_shape1} alt="" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="about-content">
                  <div class="section-title mb-25 tg-heading-subheading animation-style2">
                    <h2 class="title">Syllabus Management</h2>
                  </div>
                  <p>
                    Syllabus Management is essential for educational
                    institutions to organize, manage, and disseminate course
                    content and learning objectives effectively. EdPedia's
                    Syllabus Management feature offers a comprehensive solution
                    to streamline these processes and enhance the teaching and
                    learning experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="features-area-five features-bg multi-feature-bg"
          //   data-background="assets/img/bg/features_bg.jpg"
          //   style='background-image: url("assets/img/bg/features_bg.jpg");'
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="section-title-two mb-30 text-center">
                  <h3 class="title">
                    Key Aspects of Syllabus Management Include
                  </h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">Centralized Syllabus Repository</h2>
                    </div>
                    <p>
                      EdPedia provides a centralized repository where
                      administrators and instructors can create, store, and
                      manage syllabi for various courses and subjects. This
                      feature ensures consistency and accessibility of course
                      content across the institution.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Curriculum Mapping and Alignment</h2>
                    </div>
                    <p>
                      With EdPedia's curriculum mapping feature, educators can
                      align syllabi with academic standards, learning
                      objectives, and assessment criteria. This ensures
                      coherence and alignment between course content and desired
                      learning outcomes.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">
                        Version Control and Revision History
                      </h2>
                    </div>
                    <p>
                      EdPedia tracks changes to syllabi over time, maintaining a
                      revision history that allows administrators and
                      instructors to review and compare different versions. This
                      feature promotes collaboration and ensures accuracy and
                      accountability in syllabus management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src="assets/img/images/features_shape01.png" alt="" />
            <img src="assets/img/images/features_shape02.png" alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default SyllabusManagement;
