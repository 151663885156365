import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import bannerImg from "../assets/img/banner/h2_banner_img.png";
import bannerImg_shape1 from "../assets/img/banner/h2_banner_shape01.png";
import bannerImg_shape2 from "../assets/img/banner/h2_banner_shape02.png";
import bannerImg_shape3 from "../assets/img/banner/h2_banner_shape03.png";
import about_01 from "../assets/img/images/h2_about_img01.jpg";
import about_02 from "../assets/img/images/h2_about_img02.jpg";
import blog_1 from "../assets/img/fee.png";
import blog_3 from "../assets/img/admission.png";
import blog_2 from "../assets/img/blog/blog_avatar01.png";

import blog_4 from "../assets/img/blog/blog_avatar02.png";
import about_shape01 from "../assets/img/images/h2_about_shape01.png";
import about_shape02 from "../assets/img/images/h2_about_shape02.png";
import about_shape03 from "../assets/img/images/h2_about_shape03.png";
import edpedia_png from "../assets/img/icons/edpedia4.png";
import edpedia_png1 from "../assets/img/icons/edpedia6.png";
import edpedia_png2 from "../assets/img/icons/edpedia3.png";
import edpedia_png3 from "../assets/img/icons/edpedia7.png";
import edpedia_png5 from "../assets/img/icons/edpedia5.png";
import edpedia_png4 from "../assets/img/icons/edpedia8.png";
import service_shape1 from "../assets/img/images/h9_services_shape01.png";
import service_shape2 from "../assets/img/images/h9_services_shape02.png";
import whatsapp from "../assets/img/icons/whasapp.png";
import edpedia_png15 from "../assets/img/icons/edpedia15.png";
import service_item_shape from "../assets/img/services/h8_services_item_shape.svg";
import edpedia_png22 from "../assets/img/icons/edpedia22.png";
import edpedia_png23 from "../assets/img/icons/edpedia23.png";
import { BiRupee } from "react-icons/bi";
import edpedia_png24 from "../assets/img/icons/edpedia24.png";
import edpedia_png25 from "../assets/img/icons/edpedia25.png";
import edpedia_png26 from "../assets/img/icons/edpedia26.png";
import edpedia_png27 from "../assets/img/icons/edpedia27.png";
import edpedia_png28 from "../assets/img/icons/edpedia28.png";
import edpedia_png19 from "../assets/img/icons/edpedia19.png";
import edpedia_png17 from "../assets/img/icons/edpedia17.png";
import edpedia_png18 from "../assets/img/icons/edpedia18.png";
import edpedia_png21 from "../assets/img/icons/edpedia21.png";
import service_shape_01 from "../assets/img/services/h6_services_shape01.png";
import service_shape_02 from "../assets/img/services/h6_services_shape02.png";
import about_img_2 from "../assets/img/images/about_img02.jpg";
import about_img_3 from "../assets/img/images/about_img03.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessBoard, faStar } from "@fortawesome/free-solid-svg-icons";
import testimonial1 from "../assets/img/images/testi_avatar01.png";
import testimonial2 from "../assets/img/images/testi_avatar02.png";
import about_shape04 from "../assets/img/images/about_shape01.png";
import about_shape05 from "../assets/img/images/about_shape01.png";
import principal_shape from "../assets/img/images/pricing_shape.png";
import check_Icon_02 from "../assets/img/icons/check_icon02.svg";
import inner_about_shape_01 from "../assets/img/images/inner_about_shape01.png";
import inner_about_shape_02 from "../assets/img/images/inner_about_shape02.png";
import blog1 from "../assets/img/blog/h2_blog_img01.jpg";
import blog2 from "../assets/img/blog/blog_avatar01.png";
import blog3 from "../assets/img/blog/h2_blog_img02.jpg";
import blog4 from "../assets/img/blog/blog_avatar02.png";
import blog5 from "../assets/img/blog/h2_blog_img03.jpg";
import board1 from "../assets/img/support-eduction/State-Boards.jpg";
import board2 from "../assets/img/support-eduction/international.jpg";
import board3 from "../assets/img/support-eduction/icse.jpg";
import board4 from "../assets/img/support-eduction/centraboard.jpg";
import board5 from "../assets/img/support-eduction/cambridge.png";
import board6 from "../assets/img/support-eduction/national-intitute.png";
import board7 from "../assets/img/support-eduction/icse.jpg";
import board8 from "../assets/img/support-eduction/birhar.png";
import board9 from "../assets/img/support-eduction/hariyana.gif";
import board10 from "../assets/img/support-eduction/himachala.jpg";
import board11 from "../assets/img/support-eduction/hariyana.gif";
import request from "../assets/img/images/h2_request_shape01.png";
import inner_about from "../assets/img/images/inner_about_img05.png";
import request1 from "../assets/img/images/h2_request_shape02.png";
import check_icon from "../assets/img/icons/check_icon02.svg";
import qrcode from "../assets/img/qrcode.png";
import AOS from "aos";
import { textIntro, skewGallery } from "../Components/Animation";
import CountUp from "react-countup";
import { useGetAllPlansQuery } from "../RTKQuery/RtkQuery";
import { Link } from "react-router-dom";
import { data } from "../Common/Data";
import { blogData } from "./BlogComponents/Data";

const Home = () => {
  // const { data } = useGetAllPlansQuery();

  const [planData, setPlanData] = useState(data);
  const [selectedDuration, setSelectedDuration] = useState("MONTH");
  const [showModal, setShowModal] = useState(false);
  const showDemoModal = () => {
    setShowModal(true);
  };
  const titleRef = useRef(null);
  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogData);
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     setPlanData(data.data.slice(0, 3));
  //   }
  // }, [data]);

  // useEffect(() => {
  //   const elements = document.querySelectorAll(".tg-element-title");
  //   elements.forEach((elem) => {
  //     textIntro(elem);
  //   });

  //   // Apply animation using ref if available
  //   if (titleRef.current) {
  //     textIntro(titleRef.current);
  //   }
  // }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section
          class="banner-area-two banner-bg-two main_background"
          // data-background="assets/img/banner/h2_banner_bg.jpg"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="banner-content-two">
                  {/* <!-- <span class="sub-title" data-aos="fade-up" data-aos-delay="0">We Are Expert In This Field</span> --> */}
                  <h2
                    class="title"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <span
                      class="aicolor"
                      data-aos="fade-left"
                      data-aos-duration="2000"
                    >
                      EdPedia
                    </span>
                    - AI Driven Education Management System
                  </h2>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Automate Modules, Quality Assured 100% Complete Powerful
                    ERP.
                  </p>
                  <div class="banner-btn">
                    <button
                      onClick={showDemoModal}
                      class="btn"
                      data-aos="fade-right"
                      data-aos-delay="500"
                    >
                      REGISTER FOR FREE TRAIL
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="banner-img text-center">
                  <img
                    src={bannerImg}
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay="200"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="banner-shape-wrap">
            <img src={bannerImg_shape1} alt="" />
            <img src={bannerImg_shape2} alt="" />
            <img src={bannerImg_shape3} alt="img" />
          </div>
        </section>

        <section class="counter-area-two ed-blue white-color">
          <div class="container">
            <div class="counter-item-wrap">
              <div class="row justify-content-center">
                <div class="col-lg-2 col-md-3 col-sm-6">
                  <div class="counter-item-two ">
                    <h2 class="count white-color">
                      <CountUp end={80} start={0} delay={2} />+
                    </h2>
                    <p class="white-color">School</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6">
                  <div class="counter-item-two">
                    <h2 class="count white-color">
                      <CountUp end={60} start={5} delay={2} /> +
                    </h2>
                    <p class="white-color">Colleges</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6">
                  <div class="counter-item-two">
                    <h2 class="count white-color">
                      <CountUp end={80000} start={0} delay={1} /> +
                    </h2>
                    <p class="white-color">Students</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6">
                  <div class="counter-item-two">
                    <h2 class="count white-color">
                      <CountUp end={24} start={10} delay={3} /> /7
                    </h2>
                    <p class="white-color">Support</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6">
                  <div class="counter-item-two">
                    <h2 class="count white-color">
                      <CountUp end={40} start={10} delay={3} /> +
                    </h2>
                    <p class="white-color">Other Institutes </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="about-area-three overview-area">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-9">
                <div class="about-img-wrap-three">
                  <img
                    src={about_01}
                    alt=""
                    data-aos="fade-down-right"
                    data-aos-delay="50"
                  />
                  <img
                    src={about_02}
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay="200"
                  />
                  <div
                    class="experience-wrap"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h2 class="title">
                      10+<span></span>
                    </h2>
                    <p>years of Research in Education system</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content-three">
                  <div class="section-title-two mb-20 tg-heading-subheading animation-style3">
                    <span class="sub-title">Company Overview</span>
                    <h2 class="title">
                      360<sup>o</sup> ERP solutions
                    </h2>
                  </div>
                  <h3>
                    Navigating Excellence in Education with Seamless Management
                    Solution
                  </h3>
                  <p class="info-one">
                    Educational Management software for institutions to manage
                    daily activities like students records, examination, fees
                    and expense management. We have different logins and
                    dashboards for Admin, teachers, students, and parents. The
                    software is online. Student enrollment is very easy to
                    manage and use throughout our software.
                  </p>

                  <div class="overview-content pt-20">
                    <div class="content-bottom">
                      <ul class="list-wrap">
                        <li>
                          <div class="icon">
                            <i class="flaticon-trophy"></i>
                          </div>
                          <div class="content">
                            <h2 class="count">
                              <CountUp end={5} start={5} delay={4} />+
                            </h2>
                            <p>Best Award</p>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <i class="flaticon-rating"></i>
                          </div>
                          <div class="content">
                            <h2 class="count">
                              <CountUp end={180} start={0} delay={4} />+
                            </h2>
                            <p>Happy Institutions</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="about-shape-wrap-two">
            <img src={about_shape01} alt="" />
            <img src={about_shape02} alt="" />
            <img
              src={about_shape03}
              alt=""
              data-aos="fade-left"
              data-aos-delay="400"
            />
          </div>
        </section>

        <section class="services-area-eleven section-pt-120 section-pb-50 bg-light">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="section-title-two mb-60 tg-heading-subheading animation-style3">
                <span class="sub-title">Our Expertise </span>
                <h2
                  class="title tg-element-title"
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                  ref={titleRef}
                >
                  What are the Features we Provide
                </h2>
              </div>
            </div>
            <div class="row gutter-24 justify-content-center">
              <div class="col-lg-4 col-md-6">
                <div class="features-item-six features-item-seven">
                  <div class="features-item-six-top">
                    <div class="features-icon-six">
                      <img src={edpedia_png} alt="" />
                    </div>
                    <h2 class="title">
                      <Link to="/">
                        While sending mails we can generate the content through
                        AI.
                      </Link>
                    </h2>
                  </div>
                  <div class="features-content-six">
                    <p>
                      We have mail option at student and staff view. We will
                      integrate AI to generate the mail content.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item-six features-item-seven active">
                  <div class="features-item-six-top">
                    <div class="features-icon-six">
                      <img src={edpedia_png1} alt="" />
                    </div>
                    <h2 class="title">
                      <Link to="/">Cloud-Enabled Infrastructure</Link>
                    </h2>
                  </div>
                  <div class="features-content-six">
                    <p>
                      EdPedia leverages cloud technology for seamless
                      accessibility, ensuring educators, students, and
                      administrators can securely access information anytime,
                      anywhere.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item-six features-item-seven">
                  <div class="features-item-six-top">
                    <div class="features-icon-six">
                      <img src={edpedia_png2} alt="" />
                    </div>
                    <h2 class="title">
                      <Link to="/">
                        Payment reminders to parents via whatsapp notifications
                      </Link>
                    </h2>
                  </div>
                  <div class="features-content-six">
                    <p>
                      along with the payment link. This feature is available to
                      support the schools and colleges to avoid the manual
                      calling to parents to remind the fee payments.
                    </p>
                    {/* <!-- <Link to='/' class="link-btn">Read More <img src="assets/img/icons/right-arrow.svg" alt="" /></Link> --> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item-six features-item-seven">
                  <div class="features-item-six-top">
                    <div class="features-icon-six">
                      <img src={edpedia_png3} alt="" />
                    </div>
                    <h2 class="title">
                      <Link to="/">Data Protected System</Link>
                    </h2>
                  </div>
                  <div class="features-content-six">
                    <p>
                      Our system prioritizes data security, employing robust
                      encryption and authentication protocols to safeguard
                      sensitive information and maintain the privacy of
                      students, staff & organization
                    </p>
                    {/* <!-- <Link to='/' class="link-btn">Read More <img src="assets/img/icons/right-arrow.svg" alt="" /></Link> --> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item-six features-item-seven">
                  <div class="features-item-six-top">
                    <div class="features-icon-six">
                      <img src={edpedia_png5} alt="" />
                    </div>
                    <h2 class="title">
                      <Link to="/">Timetable Generation using AI.</Link>
                    </h2>
                  </div>
                  <div class="features-content-six">
                    <p>
                      timetable generation can be done by using the inbuilt AI
                    </p>
                    {/* <!-- <Link to='/' class="link-btn">Read More <img src="assets/img/icons/right-arrow.svg" alt="" /></Link> --> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="features-item-six features-item-seven">
                  <div class="features-item-six-top">
                    <div class="features-icon-six">
                      <img src={edpedia_png4} alt="" />
                    </div>
                    <h2 class="title">
                      <Link to="/">Real-Time Analytics</Link>
                    </h2>
                  </div>
                  <div class="features-content-six">
                    <p>
                      The system provides real-time analytics and reporting
                      capabilities, empowering administrators to make
                      data-driven decisions for improved educational outcomes.
                    </p>
                    {/* <!-- <Link to='/' class="link-btn">Read More <img src="assets/img/icons/right-arrow.svg" alt="" /></Link> --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-five">
            <img
              src={service_shape1}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={service_shape2}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>

        <section class=" mb-100 mt-100">
          <div class="container">
            <div
              class="cta-inner-wrap inner_cta"
              data-background="assets/img/bg/cta_bg.jpg"
            >
              <div class="row align-items-center">
                <div class="col-lg-9">
                  <div class="cta-content">
                    <div class="cta-info-wrap">
                      <div class="icon">
                        <img src={whatsapp} alt="" />
                      </div>
                      <div class="content">
                        <span>Edpedia can send up to</span>
                        <Link to="/">10,000</Link>
                        <span>whatsapp messages at a time</span>
                      </div>
                    </div>
                    <h2 class="title">
                      It includes Annoucements, Fee remainders and lot more.
                    </h2>
                  </div>
                </div>
                <div class="col-lg-3">
                  <img src={qrcode} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="services-area-ten fix">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-10">
                <div class="section-title-two text-center white-title mb-50 tg-heading-subheading animation-style1">
                  <span
                    class="sub-title tg-element-title"
                    data-aos="fade-down-right"
                    ref={titleRef}
                  >
                    What We Do For You
                  </span>
                  <h2
                    class="title tg-element-title "
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    ref={titleRef}
                  >
                    <b class="aicolor">AI</b> - Advantages in Edpedia
                  </h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png15} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Admissions and Enrollment Management</Link>
                    </h3>
                    <p>
                      AI predicts enrollment trends, automates tasks like
                      application processing, document verification, and
                      applicant screening.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png17} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Personalized Learning Paths</Link>
                    </h3>
                    <p>
                      AI predicts enrollment trends, automates tasks like
                      application processing, document verification, and
                      applicant screening.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png18} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">
                        Predictive Analytics for Student Success
                      </Link>
                    </h3>
                    <p>
                      AI analyzes student data to predict at-risk individuals,
                      enabling early intervention for improved outcomes.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png19} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Automated Grading and Assessment</Link>
                    </h3>
                    <p>
                      AI automates grading for objective assessments, saving
                      time for teachers and providing instant feedback to
                      students.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png21} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Virtual Teaching Assistants</Link>
                    </h3>
                    <p>
                      AI chatbots offer instant support to students, answering
                      questions and providing personalized tutoring anytime,
                      anywhere.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png22} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">
                        Behavioral Analytics and Student Safety
                      </Link>
                    </h3>
                    <p>
                      AI analyzes student behavior for safety concerns like
                      bullying and monitors campus security cameras for
                      real-time threat detection, enhancing campus safety.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png23} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Parent Engagement and Communication</Link>
                    </h3>
                    <p>
                      AI chatbots streamline communication by sending automated
                      updates on student progress, events, and announcements,
                      and scheduling parent-teacher conferences.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png24} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">
                        Resource Optimization and Cost Reduction
                      </Link>
                    </h3>
                    <p>
                      AI algorithms can analyze data related to resource usage,
                      such as energy consumption, transportation routes, and
                      maintenance schedules, to identify areas where costs can
                      be reduced and resources can be optimized.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png25} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">
                        Curriculum Enhancement and Content Recommendation
                      </Link>
                    </h3>
                    <p>
                      AI can analyze curriculum standards, student performance
                      data, and educational research to recommend improvements
                      to the curriculum and suggest supplementary learning
                      materials that align with students' interests and learning
                      goals.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png26} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Smart Scheduling and Timetabling</Link>
                    </h3>
                    <p>
                      AI algorithms can optimize school timetables by
                      considering factors like teacher availability, classroom
                      capacity, and student preferences. This ensures efficient
                      use of resources and minimizes scheduling conflicts.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png27} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">
                        Poster creation for the school events by using A
                      </Link>
                    </h3>
                    <p>
                      Dynamic poster creation can be done by using the inbuilt
                      AI.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6">
                <div class="services-item-seven">
                  <div class="services-icon-seven">
                    <img src={edpedia_png28} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Daily quiz by AI</Link>
                    </h3>
                    <p>
                      We generate quiz by using AI. This will help students and
                      staff in order to improve the knowledge of student.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={service_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={service_shape_02}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>

          <div class="cta-btn text-center">
            <Link to="/ai-feature" class="btn">
              More AI Features
            </Link>
          </div>
        </section>
        <section class="about-area-two pt-110 pb-120">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-7 col-md-9 order-0 order-lg-2">
                <div class="about-img-two">
                  <div class="main-img">
                    <img src={about_img_2} alt="" />
                  </div>
                  <img src={about_img_3} alt="" />
                </div>
              </div>
              <div class="col-lg-5">
                <div class="about-content-two">
                  <div class="section-title mb-30 tg-heading-subheading animation-style2">
                    <h2
                      class="title tg-element-title"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1000"
                      ref={titleRef}
                    >
                      What are the Features we Provide
                    </h2>
                  </div>
                  <p>
                    Edpedia has a wide range of features which helps many
                    Schools, Colleges, Institutes, Univercities in Assemble
                    manner These are some of the Important features of Edpedia.
                  </p>
                  <div class="about-list">
                    <ul class="list-wrap">
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Attendence
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Exam Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Student Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Transport Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Fee Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Addmission Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Syallabus Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Timetable Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Notification Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Dashboard Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        HR Management
                      </li>
                      <li>
                        <img src="assets/img/icons/check_icon.svg" alt="" />
                        Cultural Management
                      </li>
                    </ul>
                  </div>
                  <div class="success-wrap">
                    <ul class="list-wrap">
                      <li>
                        <h2 class="count">300+</h2>
                        <p>F E A T U R E S</p>
                      </li>
                    </ul>
                  </div>
                  <Link to="/features" class="btn transparent-btn">
                    Get Started With Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="about-shape-wrap">
            <img src={about_shape04} alt="" />
            <img src={about_shape05} alt="" />
          </div>
        </section>

        <section
          class="testimonial-area-two testimonial-bg-two testimonial_container"
          //   data-background="assets/img/bg/h2_testimonial_bg.jpg"
          //   className=""
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-7">
                <div class="section-title-two white-title text-center mb-50 tg-heading-subheading animation-style3">
                  <span class="sub-title">Our Testimonials</span>
                  <h2
                    class="title tg-element-title"
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    ref={titleRef}
                  >
                    What Customers Say’s About Us
                  </h2>
                </div>
              </div>
            </div>
            <div class="testimonial-item-wrap-two">
              <div class="row testimonial-active-two">
                <div class="col-lg-6">
                  <div class="testimonial-item-two">
                    <div class="testimonial-content-two">
                      <div className="rating">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>

                      <p>
                        “ We've been using EdPedia for a few months now, and it
                        has truly transformed our school operations. The
                        platform's intuitive interface and robust features have
                        made managing student data, scheduling, and
                        communication a breeze. Our teachers and administrative
                        staff love how efficient and user-friendly it is. Highly
                        recommended
                      </p>
                      <div class="testimonial-avatar">
                        <div class="avatar-thumb">
                          <img src={testimonial1} alt="" />
                        </div>
                        <div class="avatar-info">
                          <h2 class="title">St. Teresa's High School</h2>
                          <span>Principal , Rosario </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="testimonial-item-two">
                    <div class="testimonial-content-two">
                      <div class="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p>
                        “Our experience with EdPedia has been exceptional. The
                        platform has streamlined our administrative tasks and
                        improved communication between staff, students, and
                        parents. We're particularly impressed with the ease of
                        use and comprehensive features. It has truly
                        revolutionized the way we manage our school.
                      </p>
                      <div class="testimonial-avatar">
                        <div class="avatar-thumb">
                          <img src={testimonial2} alt="" />
                        </div>
                        <div class="avatar-info">
                          <h2 class="title">SRI RAJA RAJESWARI HIGH SCHOOL</h2>
                          <span>Director , MBN RAGHAVENDRA RAO</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="testimonial-nav-two"></div>
            </div>
          </div>
        </section>

        <section class="pricing-area-two">
          <div class="pricing-shape">
            <img
              src={principal_shape}
              alt=""
              data-aos="fade-left"
              data-aos-delay="200"
            />
          </div>
          <div class="container">
            <div class="row align-items-center text-center justify-content-center">
              <div class="col-lg-8">
                <div class="section-title-two mb-50 tg-heading-subheading animation-style3">
                  <span class="sub-title">Price / Plans</span>
                  <h2
                    class="title tg-element-title"
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    ref={titleRef}
                  >
                    We take some information from institutes before finalise the
                    prices.
                  </h2>
                </div>
              </div>
              {/* <!-- <div class="col-lg-6 col-md-10">
                    <div class="section-top-content mb-30">
                        <p>Ever find yourself staring at your computer screen a good consulting slogan to come to mind? Oftentimes.</p>
                    </div>
                </div> --> */}
              <div className="pricing-tabs">
                <button
                  className={`btn ${
                    selectedDuration === "MONTH" ? "active" : ""
                  }`}
                  onClick={() => handleDurationChange("MONTH")}
                >
                  Monthly
                </button>

                <button
                  className={`btn ${
                    selectedDuration === "YEARLY" ? "active" : ""
                  }`}
                  onClick={() => handleDurationChange("YEARLY")}
                >
                  Yearly
                </button>
              </div>
              <div className="pricing-item-wrap">
                <div className="row justify-content-center">
                  {planData.map((item) => (
                    <div className="col-lg-4 col-md-6 col-sm-10" key={item.id}>
                      <div className="pricing-box-two">
                        <div className="pricing-head-two">
                          <h4 className="title">{item.planName}</h4>
                          <div className="pricing-price-two">
                            {Object.entries(item.durationAmountMap)
                              .filter(
                                ([duration]) => duration === selectedDuration
                              )
                              .map(([duration, amount]) => (
                                <h2 className="price" key={duration}>
                                  <strong>
                                    {" "}
                                    <BiRupee />
                                  </strong>
                                  {amount}
                                  <span>
                                    {" "}
                                    /{duration
                                      .replace(/_/g, " ")
                                      .toUpperCase()}{" "}
                                    / 500 Students
                                  </span>
                                </h2>
                              ))}
                          </div>
                        </div>
                        <div className="pricing-bottom">
                          <div className="pricing-list">
                            <ul className="list-wrap">
                              {item.subscriptionPlanFeatures.map((feature) => (
                                <li key={feature.featureId}>
                                  <img src={check_icon} alt="" />
                                  {feature.featureName}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="pricing-btn-two">
                            <Link to="/contact" className="btn">
                              Get The Plan Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="pb-100 pt-100 bg-light">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-9 order-0 order-lg-2">
                <div class="about-img-wrap-eleven">
                  <img src={inner_about} alt="" />
                  <img src={inner_about_shape_01} alt="" class="shape-one" />
                  <img src={inner_about_shape_02} alt="" class="shape-two" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-content-eleven">
                  <div class="section-title-two mb-50 tg-heading-subheading animation-style3">
                    <h2
                      class="title tg-element-title"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                      ref={titleRef}
                    >
                      Support / Services We Provide
                    </h2>
                  </div>

                  <div class="about-list-two">
                    <ul class="list-wrap">
                      <li>
                        <i class="fas fa-arrow-right"></i>24/7 Technical Support
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>24/7 Accessibility
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>User Customizanle
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>Dedicated SPOC Special
                        care
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>Anywhere Anytime
                        Accessibility
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>Mobile Apps
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>Online Payments
                      </li>
                      <li>
                        <i class="fas fa-arrow-right"></i>Well Designed Modules
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="blog-area-two blog-bg-two blog_conatiner"
          data-background="assets/img/bg/h2_blog_bg.jpg"
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="section-title-two text-center mb-50 tg-heading-subheading animation-style3">
                  <span class="sub-title">News & Blogs</span>
                  <h2
                    class="title tg-element-title"
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    ref={titleRef}
                  >
                    Read Our Latest Blogs
                  </h2>
                  <p>
                    Ever find yourself staring at your computer screen a good
                    consulting slogan to come to mind? Oftentimes.
                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="blog-post-item-two">
                  <div class="blog-post-thumb-two">
                    <Link to="/whatsapp-fee-alert">
                      <img src={blog_3} alt="" />
                    </Link>
                    <Link to="/whatsapp-fee-alert" class="tag tag-two">
                      WhatsApp Fee Alerts
                    </Link>
                  </div>
                  <div class="blog-post-content-two">
                    <h2 class="title">
                      <Link to="/whatsapp-fee-alert">
                        WhatsApp Fee Alerts for School Fee Management
                      </Link>
                    </h2>
                    <p>
                      A user-friendly interface ensures that both applicants and
                      administrators can navigate the system effortlessly.
                    </p>
                    <div class="blog-meta">
                      <ul class="list-wrap">
                        <li>
                          <Link to="/whatsapp-fee-alert">
                            <img src={blog_4} alt="" />
                            G V Riteesh Reddy
                          </Link>
                        </li>
                        <li>
                          <i class="far fa-calendar"></i> 10 jun 2024
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="blog-post-item-two">
                  <div class="blog-post-thumb-two">
                    <Link to="/fee-management">
                      <img src={blog_1} alt="" />
                    </Link>
                    <Link to="/blog" class="tag tag-two">
                      Fee Management software
                    </Link>
                  </div>
                  <div class="blog-post-content-two">
                    <h2 class="title">
                      <Link to="/fee-management">
                        How Fee Management Software Can Improve Your
                        Institution's Financial Health
                      </Link>
                    </h2>
                    <p>
                      One of the most significant advantages of fee management
                      software is the automation of billing and invoicing
                    </p>
                    <div class="blog-meta">
                      <ul class="list-wrap">
                        <li>
                          <Link to="/fee-management">
                            <img src={blog_2} alt="" />
                            Admin
                          </Link>
                        </li>
                        <li>
                          <i class="far fa-calendar"></i>22 Jan, 2023
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="blog-post-item-two">
                  <div class="blog-post-thumb-two">
                    <Link to="/admission-managements">
                      <img src={blog_3} alt="" />
                    </Link>
                    <Link to="/admission-managements" class="tag tag-two">
                      Admission Management
                    </Link>
                  </div>
                  <div class="blog-post-content-two">
                    <h2 class="title">
                      <Link to="/admission-managements">
                        Top 10 Features to Look for in Admission Management
                        Software
                      </Link>
                    </h2>
                    <p>
                      A user-friendly interface ensures that both applicants and
                      administrators can navigate the system effortlessly.
                    </p>
                    <div class="blog-meta">
                      <ul class="list-wrap">
                        <li>
                          <Link to="/admission-managements">
                            <img src={blog_4} alt="" />
                            Admin
                          </Link>
                        </li>
                        <li>
                          <i class="far fa-calendar"></i> 15 Dec 2022
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="brand-area-six pt-80 pb-80">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-title-two text-center mb-50 tg-heading-subheading animation-style3">
                  <span class="sub-title">Support</span>
                  <h2
                    class="title tg-element-title"
                    // style="perspective: 400px;"
                    ref={titleRef}
                    data-aos="flip-right"
                    data-aos-duration="1000"
                  >
                    Education boards that are supporte  by Edpedia.
                  </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="edcatboard">
                  <div class="edimg">
                    <img src={board1} alt="" />
                  </div>
                  <div class="edimgcontent">State Board</div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="edcatboard">
                  <div class="edimg">
                    <img src={board2} alt="" />
                  </div>
                  <div class="edimgcontent">International Baccalaureate</div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="edcatboard">
                  <div class="edimg">
                    <img src={board3} alt="" />
                  </div>
                  <div class="edimgcontent">
                    Indian Certificate of Secondary Education
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="edcatboard">
                  <div class="edimg">
                    <img src={board4} alt="" />
                  </div>
                  <div class="edimgcontent">
                    Central Board of Secondary Education
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="edcatboard">
                  <div class="edimg">
                    <img src={board5} alt="" />
                  </div>
                  <div class="edimgcontent">
                    Cambridge Assessment International{" "}
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="edcatboard">
                  <div class="edimg">
                    <img src={board6} alt="" />
                  </div>
                  <div class="edimgcontent">
                    National Institute of Open Schooling
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="edcatboard">
                  <img src={board7} alt="" />
                  <div class="edimgcontent">CISCE</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  <img src={board8} alt="" />
                  <div class="edimgcontent">Bihar Board</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  <img src={board9} alt="" />
                  <div class="edimgcontent">
                    Board Of School Education Haryana
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  <img src={board10} alt="" />
                  <div class="edimgcontent">Himachal Pradesh Board</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  {/* <img
                    src="assets/img/support-eduction/maharashtra.jpg"
                    alt=""
                  /> */}
                  <img src={board4} alt="" />
                  <div class="edimgcontent">Maharashtra Board</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  {/* <img src="assets/img/support-eduction/up-board.png" alt="" /> */}
                  <img src={board2} alt="" />
                  <div class="edimgcontent">Uttar Pradesh Board</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  {/* <img
                    src="assets/img/support-eduction/west-bengal.png"
                    alt=""
                  /> */}
                  <img src={board1} alt="" />
                  <div class="edimgcontent">West Bengal Board</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  {/* <img
                    src="assets/img/support-eduction/cbsc-board.png"
                    alt=""
                  /> */}
                  <img src={board7} alt="" />
                  <div class="edimgcontent">CBSE</div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="edcatboard">
                  {/* <img src="assets/img/support-eduction/MPBSE.png" alt="" /> */}
                  <img src={board11} alt="" />
                  <div class="edimgcontent">Madhya Pradesh Board</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
