// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

// export const textIntro = (elem) => {
//   gsap.from(elem, {
//     scrollTrigger: {
//       trigger: elem,
//       start: "top bottom", // Adjusting the start point with a negative offset
//       scrub: true,
//     },
//     x: 100,
//     duration: 1,
//     opacity: 0,
//     ease: "power4.out",
//   });
// };

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const textIntro = (elem) => {
  gsap.from(elem, {
    scrollTrigger: {
      trigger: elem,
      start: "top 100%",

      scrub: true,
    },
    scale: 0.5,
    duration: 1,
    opacity: 0,
    ease: "power4.out",
  });
};
