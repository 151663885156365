import React from "react";
import edpedia_png22 from "../../assets/img/icons/edpedia22.png";
import edpedia_png23 from "../../assets/img/icons/edpedia23.png";
import edpedia_png24 from "../../assets/img/icons/edpedia24.png";
import edpedia_png25 from "../../assets/img/icons/edpedia25.png";
import edpedia_png26 from "../../assets/img/icons/edpedia26.png";
import edpedia_png27 from "../../assets/img/icons/edpedia27.png";
import edpedia_png28 from "../../assets/img/icons/edpedia28.png";
import edpedia_png19 from "../../assets/img/icons/edpedia19.png";
import edpedia_png17 from "../../assets/img/icons/edpedia17.png";
import edpedia_png18 from "../../assets/img/icons/edpedia18.png";
import edpedia_png21 from "../../assets/img/icons/edpedia21.png";
import service_shape_01 from "../../assets/img/services/h6_services_shape01.png";
import service_item_shape from "../../assets/img/services/h8_services_item_shape.svg";
import service_shape_02 from "../../assets/img/services/h6_services_shape02.png";
import edpedia_png15 from "../../assets/img/icons/edpedia15.png";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import blog_details_1 from "../../assets/img/fee1.jpg";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import { Link } from "react-router-dom";

const Feemanagement = () => {
  return (
    <main class="fix">
      <section class="breadcrumb-area text-left ">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/features">Features</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Fee Management
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section class="about-area pt-50 pb-50">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="about-img-wrap">
                <img src={blog_details_1} alt="" class="main-img" />
                <img src={about_img_shape1} alt="" />
              </div>
            </div>
            <div class="col-lg-7">
              <div class="about-content">
                <div class="section-title mb-25 tg-heading-subheading animation-style2">
                  <h2 class="title">Fee Management</h2>
                </div>
                <p>
                  Fee Management is a critical aspect of educational
                  institutions, encompassing the billing, collection, and
                  tracking of student fees. EdPedia's Fee Management feature
                  offers a comprehensive solution to streamline these processes
                  and enhance financial transparency and efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="features-area-five features-bg multi-feature-bg"
        //   data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <div class="section-title-two mb-30 text-center">
                <h3 class="title">Key Aspects of Fee Management Include</h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">
                      Automated Fee Calculation and Invoicing
                    </h2>
                  </div>
                  <p>
                    EdPedia automates fee calculation based on predefined fee
                    structures, discounts, and exemptions, generating accurate
                    invoices for each student. This feature minimizes errors and
                    reduces manual effort associated with fee management.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">Online Payment Gateway Integration</h2>
                  </div>
                  <p>
                    With EdPedia's online payment gateway integration, parents
                    can conveniently pay fees online using various payment
                    methods such as credit/debit cards, net banking, or digital
                    wallets. This enhances convenience and ensures timely fee
                    payments.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Fee Reminder and Tracking </h2>
                  </div>
                  <p>
                    EdPedia sends automated fee reminders and notifications to
                    parents and students regarding upcoming fee due dates,
                    outstanding balances, and payment receipts. This helps
                    improve fee collection rates and reduces instances of late
                    payments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src="assets/img/images/features_shape01.png" alt="" />
          <img src="assets/img/images/features_shape02.png" alt="" />
        </div>
      </section>
    </main>
  );
};

export default Feemanagement;
