import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import about1 from "../../assets/img/bus.jpg";
import about_img_shape1 from "../../assets/img/images/about_img_shape01.png";
import { Link } from "react-router-dom";
const DriverMobileManagement = () => {
  return (
    <>
      {" "}
      <main class="fix">
        <section class="breadcrumb-area text-left ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/features">Features</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Driver Mobile App Management
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="about-area pt-50 pb-50">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-5">
                <div class="about-img-wrap">
                  <img src={about1} alt="" class="main-img" />
                  <img src={about_img_shape1} alt="" />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="about-content">
                  <div class="section-title mb-25 tg-heading-subheading animation-style2">
                    <h2 class="title">Driver Mobile App Management</h2>
                  </div>
                  <p>
                  Manage a mobile app specifically for drivers to streamline communication and operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="features-area-five features-bg multi-feature-bg"
          //   data-background="assets/img/bg/features_bg.jpg"
          //   style='background-image: url("assets/img/bg/features_bg.jpg");'
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="section-title-two mb-30 text-center">
                  <h3 class="title">
                    Key Aspects of Driver Mobile App Management Include
                  </h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 class="title">Route Information</h2>
                    </div>
                    <p>
                    Provide detailed route information and updates.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-inspiration"></i>
                      </div>
                      <h2 class="title">Attendance Recording</h2>
                    </div>
                    <p>
                    Enable drivers to record student attendance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="features-item">
                  <div class="features-content">
                    <div class="content-top">
                      <div class="icon">
                        <i class="flaticon-profit"></i>
                      </div>
                      <h2 class="title">Communication Tools</h2>
                    </div>
                    <p>
                    Facilitate communication with school administration.
                    </p>
                  </div>
                </div>
              </div>        
            </div>
          </div>
          <div class="features-shape-wrap">
            <img src="assets/img/images/features_shape01.png" alt="" />
            <img src="assets/img/images/features_shape02.png" alt="" />
          </div>
        </section>
      </main>
    </>
  );
};

export default DriverMobileManagement;
