import { configureStore } from "@reduxjs/toolkit";
import { landingPageApi } from "../RTKQuery/RtkQuery";

const store = configureStore({
  reducer: {
    [landingPageApi.reducerPath]: landingPageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(landingPageApi.middleware),
});

export default store;
