import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";

import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
const Custumisable = () => {
  return (
    <>
      {" "}
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Explore All Modules
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section
        class="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6">
              <div class="section-title text-center mb-50">
                <h2 class="title">Customizable Benefits</h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">Tailored Student & Behavior Analytics</h2>
                  </div>
                  <p>
                    Customize analytics to suit your institution's unique needs.
                    Monitor and assess student well-being and social
                    interactions, and receive actionable insights tailored to
                    your specific requirements.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">
                      Flexible Institution Performance Analytics
                    </h2>
                  </div>
                  <p>
                    Gain a customizable overview of your institution’s academic
                    performance. Use interactive graphs and charts to compare
                    current data with historical trends, and tailor the insights
                    to fit your school's goals and standards.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Advanced Custom Reporting</h2>
                  </div>
                  <p>
                    Enhance your institution’s reporting capabilities with
                    highly customizable reports. Analyze academic performance,
                    behavior, and health metrics with tailored reports that meet
                    your specific needs, ensuring no student is overlooked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src={feature_01} alt="" />
          <img src={feature_02} alt="" />
        </div>
      </section>
    </>
  );
};

export default Custumisable;
