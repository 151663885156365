import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";
import { Link } from "react-router-dom";
const TechnicalSupprort = () => {
  return (
    <>
      {" "}
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Explore All Modules
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section
        class="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
        //   style='background-image: url("assets/img/bg/features_bg.jpg");'
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6">
              <div class="section-title text-center mb-50">
                <h2 class="title">Technical Support Benefits</h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-puzzle-piece"></i>
                    </div>
                    <h2 class="title">24/7 Availability</h2>
                  </div>
                  <p>
                    Ensure uninterrupted access to support with EdPedia's 24/7
                    technical assistance. Whether you encounter issues during
                    peak hours or late at night, our support team is always
                    available to help.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 class="title">Expert Guidance</h2>
                  </div>
                  <p>
                    Receive expert guidance from our knowledgeable support team.
                    Our specialists are well-versed in resolving technical
                    issues, providing you with reliable and effective solutions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="features-item">
                <div class="features-content">
                  <div class="content-top">
                    <div class="icon">
                      <i class="flaticon-profit"></i>
                    </div>
                    <h2 class="title">Comprehensive Resources</h2>
                  </div>
                  <p>
                    Access a wealth of resources including FAQs, user guides,
                    and video tutorials. EdPedia offers comprehensive materials
                    to help you troubleshoot common issues and maximize the use
                    of our platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-shape-wrap">
          <img src={feature_01} alt="" />
          <img src={feature_02} alt="" />
        </div>
      </section>
    </>
  );
};

export default TechnicalSupprort;
