import React from "react";
import { Link } from "react-router-dom";

import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";

import { Button, Col, Form, Row, Select, Space, notification } from "antd";
const TermsConditions = () => {
  const { Option } = Select;
  return (
    <>
      <section className="breadcrumb-area text-left">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/terms&conditions">Terms & Conditions</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Terms & Conditions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section className="terms-conditions-area pt-120 pb-120">
        <div className="container">
          <div className="inner-terms-conditions-wrap blog-widget">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="terms-conditions-content">
                  <h1>Terms and Conditions</h1>
                  <p>
                    Welcome to EdPedia! These terms and conditions outline the
                    rules and regulations for the use of EdPedia's website and
                    services.
                  </p>

                  <h2>1. Introduction</h2>
                  <p>
                    By accessing this website, you accept these terms and
                    conditions in full. Do not continue to use EdPedia's website
                    if you do not accept all of the terms and conditions stated
                    on this page.
                  </p>

                  <h2>2. Intellectual Property Rights</h2>
                  <p>
                    Unless otherwise stated, EdPedia and/or its licensors own
                    the intellectual property rights for all material on
                    EdPedia. All intellectual property rights are reserved. You
                    may view and/or print pages from https://www.edpedia.com for
                    your own personal use subject to restrictions set in these
                    terms and conditions.
                  </p>

                  <h2>3. Restrictions</h2>
                  <p>
                    You are specifically restricted from all of the following:
                  </p>
                  <ul>
                    <li>Publishing any website material in any other media.</li>
                    <li>
                      Selling, sublicensing, and/or otherwise commercializing
                      any website material.
                    </li>
                    <li>
                      Using this website in any way that is or may be damaging
                      to this website.
                    </li>
                    <li>
                      Using this website in any way that impacts user access to
                      this website.
                    </li>
                    <li>
                      Using this website contrary to applicable laws and
                      regulations, or in any way may cause harm to the website,
                      or to any person or business entity.
                    </li>
                    <li>
                      Engaging in any data mining, data harvesting, data
                      extracting, or any other similar activity in relation to
                      this website.
                    </li>
                    <li>
                      Using this website to engage in any advertising or
                      marketing.
                    </li>
                  </ul>

                  <h2>4. Your Privacy</h2>
                  <p>
                    Please read our{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>.
                  </p>

                  <h2>5. Limitation of Liability</h2>
                  <p>
                    In no event shall EdPedia, nor any of its officers,
                    directors, and employees, be held liable for anything
                    arising out of or in any way connected with your use of this
                    website whether such liability is under contract. EdPedia,
                    including its officers, directors, and employees shall not
                    be held liable for any indirect, consequential, or special
                    liability arising out of or in any way related to your use
                    of this website.
                  </p>

                  <h2>6. Indemnification</h2>
                  <p>
                    You hereby indemnify to the fullest extent EdPedia from and
                    against any and/or all liabilities, costs, demands, causes
                    of action, damages, and expenses arising in any way related
                    to your breach of any of the provisions of these Terms.
                  </p>

                  <h2>7. Severability</h2>
                  <p>
                    If any provision of these Terms is found to be invalid under
                    any applicable law, such provisions shall be deleted without
                    affecting the remaining provisions herein.
                  </p>

                  <h2>8. Variation of Terms</h2>
                  <p>
                    EdPedia is permitted to revise these Terms at any time as it
                    sees fit, and by using this website you are expected to
                    review these Terms on a regular basis.
                  </p>

                  <h2>9. Assignment</h2>
                  <p>
                    EdPedia is allowed to assign, transfer, and subcontract its
                    rights and/or obligations under these Terms without any
                    notification. However, you are not allowed to assign,
                    transfer, or subcontract any of your rights and/or
                    obligations under these Terms.
                  </p>

                  <h2>10. Entire Agreement</h2>
                  <p>
                    These Terms constitute the entire agreement between EdPedia
                    and you in relation to your use of this website and
                    supersede all prior agreements and understandings.
                  </p>

                  <h2>11. Governing Law & Jurisdiction</h2>
                  <p>
                    These Terms will be governed by and interpreted in
                    accordance with the laws of the State of [Your State], and
                    you submit to the non-exclusive jurisdiction of the state
                    and federal courts located in [Your State] for the
                    resolution of any disputes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;
