import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";

import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
const UserFriendly = () => {
  return (
    <>
      <section className="breadcrumb-area text-left">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User-Friendly
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="Breadcrumb Shape 1" />
          <img src={breadcrumb_shape2} alt="Breadcrumb Shape 2" />
        </div>
      </section>
      <section
        className="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6">
              <div className="section-title text-center mb-50">
                <h2 className="title">User-Friendly Interface</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 className="title">Intuitive Design</h2>
                  </div>
                  <p>
                    EdPedia's user interface is designed with simplicity and
                    ease of use in mind. Navigate through the platform
                    effortlessly, making it accessible for all users regardless
                    of their technical proficiency.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 className="title">Responsive Design</h2>
                  </div>
                  <p>
                    Access EdPedia from any device, anywhere. Our responsive
                    design ensures that you have a seamless experience whether
                    you're on a desktop, tablet, or mobile device.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 className="title">Comprehensive Tutorials</h2>
                  </div>
                  <p>
                    Learn how to utilize all the features of EdPedia with our
                    comprehensive tutorials. From basic navigation to advanced
                    functionalities, our guides are designed to help you get the
                    most out of the platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 className="title">Secure Platform</h2>
                  </div>
                  <p>
                    Your data security is our top priority. EdPedia uses
                    advanced security measures to protect your information,
                    ensuring that all user data is safe and secure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div class="icon">
                      <i class="flaticon-inspiration"></i>
                    </div>
                    <h2 className="title">Easy Setup</h2>
                  </div>
                  <p>
                    Get started with EdPedia quickly and easily. Our
                    straightforward setup process and user-friendly onboarding
                    ensure that you can begin utilizing the platform with
                    minimal hassle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features-shape-wrap">
          <img src={feature_01} alt="Feature Shape 1" />
          <img src={feature_02} alt="Feature Shape 2" />
        </div>
      </section>
    </>
  );
};

export default UserFriendly;
