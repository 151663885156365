import React from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";

import feature_01 from "../assets/img/images/features_shape01.png";
import feature_02 from "../assets/img/images/features_shape02.png";
import { Link } from "react-router-dom";
const Lms = () => {
  return (
    <>
      {" "}
      <>
        <section className="breadcrumb-area text-left">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>

                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        LMS Zoom Service
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="Breadcrumb Shape 1" />
            <img src={breadcrumb_shape2} alt="Breadcrumb Shape 2" />
          </div>
        </section>

        <section
          className="features-area-five features-bg feature_bg_container"
          data-background="assets/img/bg/features_bg.jpg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6">
                <div className="section-title text-center mb-50">
                  <h2 className="title">Zoom service</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 className="title">
                        Tailored Student & Behavior Analytics
                      </h2>
                    </div>
                    <p>
                      Customize analytics to suit your institution's unique
                      needs. Monitor and assess student well-being and social
                      interactions, and receive actionable insights tailored to
                      your specific requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-inspiration"></i>
                      </div>
                      <h2 className="title">
                        Flexible Institution Performance Analytics
                      </h2>
                    </div>
                    <p>
                      Gain a customizable overview of your institution’s
                      academic performance. Use interactive graphs and charts to
                      compare current data with historical trends, and tailor
                      the insights to fit your school's goals and standards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-profit"></i>
                      </div>
                      <h2 className="title">Advanced Custom Reporting</h2>
                    </div>
                    <p>
                      Enhance your institution’s reporting capabilities with
                      highly customizable reports. Analyze academic performance,
                      behavior, and health metrics with tailored reports that
                      meet your specific needs, ensuring no student is
                      overlooked.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-puzzle-piece"></i>
                      </div>
                      <h2 className="title">Integrated Video Conferencing</h2>
                    </div>
                    <p>
                      Seamlessly integrate with Zoom for live classes and
                      meetings. Schedule, join, and manage video sessions
                      directly from the LMS, ensuring smooth communication and
                      interaction.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-profit"></i>
                      </div>
                      <h2 className="title">Interactive Online Courses</h2>
                    </div>
                    <p>
                      Create and manage interactive online courses with ease.
                      Use multimedia content, quizzes, and assignments to engage
                      students and enhance their learning experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="features-item">
                  <div className="features-content">
                    <div className="content-top">
                      <div className="icon">
                        <i className="flaticon-inspiration"></i>
                      </div>
                      <h2 className="title">Automated Grading and Feedback</h2>
                    </div>
                    <p>
                      Save time with automated grading tools that provide
                      instant feedback to students. Customize grading rubrics
                      and ensure fair and consistent evaluation of student work.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features-shape-wrap">
            <img src={feature_01} alt="Feature Shape 1" />
            <img src={feature_02} alt="Feature Shape 2" />
          </div>
        </section>
      </>
    </>
  );
};

export default Lms;
