import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import technical from "../assets/img/benefit/techincalsupport.png";
import right_arrow from "../assets/img/icons/right-arrow.svg";
import benefit_support from "../assets/img/benefit/24suppor.png";
import user_fri from "../assets/img/benefit/user-fri.png";
import customoz from "../assets/img/benefit/customz.png";
import anytime from "../assets/img/benefit/anytime-any.png";
import mobile from "../assets/img/benefit/mobile.png";
import online_payment from "../assets/img/benefit/online-payment.png";
import designer from "../assets/img/benefit/designer.png";
import request1 from "../assets/img/images/h2_request_shape01.png";
import request2 from "../assets/img/images/h2_request_shape02.png";
import { Link } from "react-router-dom";
import AOS from "aos";
const Benifits = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Benefits
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="services-area-seven pt-50 pb-90 mt-10">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-7">
                <div class="section-title-two text-center mb-50">
                  <span class="sub-title">What We Do For You</span>
                  <h2
                    class="title"
                    data-aos="fade-left"
                    data-aos-duration="3000"
                  >
                    Benefits In EdPedia
                  </h2>
                  <p>
                    Edpedia has a wide range of Benefits which helps many
                    Schools, Colleges, Institutes, Univercities in Assemble
                    manner These are some of the Important benefit of Edpedia.
                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={technical} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Technical Support</h2>
                    <p>
                      The Technical Support module is an essential component of
                      your website...{" "}
                    </p>
                    <Link to="/technical-support" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={benefit_support} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">24/7 Accessibility</h2>
                    <p>
                      The 24/7 Accessibility feature ensures uninterrupted
                      access to .....{" "}
                    </p>
                    <Link to="/alltime-support" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={user_fri} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">User Friendly </h2>
                    <p>
                      The User-Friendly feature is an integral part of your
                      website, prioritizing...
                    </p>
                    <Link to="/userfriendly" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={customoz} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Customisable</h2>
                    <p>
                      The Customizable feature empowers users to personalize and
                      tailor......
                    </p>
                    <Link to="/custumisable" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={anytime} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Any Time Any Ware Accessibility </h2>
                    <p>
                      The Anywhere Anytime Accessibility feature ensures that
                      your website...
                    </p>
                    <Link to="/anytime-anyware-accesibility" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={mobile} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Mobile App</h2>
                    <p>
                      The Mobile App feature offers users a dedicated
                      application that...
                    </p>
                    <Link to="/mobile-app" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={online_payment} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Online Payment</h2>
                    <p>
                      The Online Payment feature allows users to conveniently
                      and securely...
                    </p>
                    <Link to="/online-payment" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="features-item-three">
                  <div class="features-icon-three iconssm">
                    <img src={designer} alt="" />
                  </div>
                  <div class="features-content-three">
                    <h2 class="title">Well-Designed Module</h2>
                    <p>
                      The Well-Designed Module is a cornerstone of your website,
                      offering ...
                    </p>
                    <Link to="/well-designed-module" class="link-btn">
                      See Details <img src={right_arrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Benifits;
