import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import service_item_shape from "../assets/img/services/h8_services_item_shape.svg";
import edpedia_png15 from "../assets/img/icons/edpedia15.png";
import edpedia_png19 from "../assets/img/icons/edpedia19.png";
import edpedia_png17 from "../assets/img/icons/edpedia17.png";
import edpedia_png18 from "../assets/img/icons/edpedia18.png";
import edpedia_png21 from "../assets/img/icons/edpedia21.png";
import edpedia_png22 from "../assets/img/icons/edpedia22.png";
import edpedia_png23 from "../assets/img/icons/edpedia23.png";
import edpedia_png24 from "../assets/img/icons/edpedia24.png";
import edpedia_png25 from "../assets/img/icons/edpedia25.png";
import edpedia_png26 from "../assets/img/icons/edpedia26.png";
import edpedia_png27 from "../assets/img/icons/edpedia27.png";
import edpedia_png28 from "../assets/img/icons/edpedia28.png";
import service_shape_01 from "../assets/img/services/h6_services_shape01.png";
import service_shape_02 from "../assets/img/services/h6_services_shape02.png";
import { Link } from "react-router-dom";
import AOS from "aos";
const AiFeatures = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <button class="scroll-top scroll-to-target" data-target="html">
        <i class="fas fa-angle-up"></i>
      </button>
      <main class="fix">
        <section class="breadcrumb-area text-left">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-content mb-10">
                  <nav aria-label="breadcrumb ">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>

                      <li class="breadcrumb-item active" aria-current="page">
                        AI Advantages{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src={breadcrumb_shape1} alt="" />
            <img src={breadcrumb_shape2} alt="" />
          </div>
        </section>

        <section class="services-area-ten fix bg-light">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 col-md-10">
                <div class="section-title-three text-center white-title mb-50 tg-heading-subheading animation-style1">
                  <h2 class="title ">
                    <span class="aicolor">AI</span> Advantages in Edpedia
                  </h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png15} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/">Admissions and Enrollment Management</Link>
                    </h3>
                    <p>
                      AI can analyze past enrollment data, student demographics,
                      and other relevant factors to predict future enrollment
                      trends. It can also streamline the admissions process by
                      automating tasks such as application processing, document
                      verification, and applicant screening. (Admissions and
                      Enrollment Management: AI predicts enrollment trends,
                      automates tasks like application processing, document
                      verification, and applicant screening.)
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png17} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">Personalized Learning Paths</Link>
                    </h3>
                    <p>
                      AI algorithms can analyze student performance data to
                      identify individual learning needs and preferences. Based
                      on this analysis, the system can recommend personalized
                      learning paths, resources, and activities to help each
                      student reach their full potential. (Personalized Learning
                      Paths: AI analyzes student data to recommend tailored
                      learning paths, resources, and activities for optimal
                      academic growth.)
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png18} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Predictive Analytics for Student Success
                      </Link>
                    </h3>
                    <p>
                      By analyzing various factors such as attendance records,
                      grades, behavior patterns, and extracurricular activities,
                      AI can predict which students are at risk of academic
                      failure or dropping out. Early intervention strategies can
                      then be implemented to provide targeted support and
                      improve student outcomes. (Predictive Analytics for
                      Student Success: AI analyzes student data to predict
                      at-risk individuals, enabling early intervention for
                      improved outcomes.).
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png19} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Automated Grading and Assessment
                      </Link>
                    </h3>
                    <p>
                      AI-powered grading systems can automatically grade
                      objective assessments such as multiple-choice questions
                      and fill-in-the-blank exercises. This saves teachers
                      valuable time and provides students with immediate
                      feedback on their performance. (Automated Grading and
                      Assessment: AI automates grading for objective
                      assessments, saving time for teachers and providing
                      instant feedback to students.)
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png21} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">Virtual Teaching Assistants</Link>
                    </h3>
                    <p>
                      Chatbots powered by AI can provide students with instant
                      assistance and support outside of regular classroom hours.
                      They can answer frequently asked questions, provide
                      explanations, and even offer personalized tutoring based
                      on the student's learning pace and style. (Virtual
                      Teaching Assistants: AI chatbots offer instant support to
                      students, answering questions and providing personalized
                      tutoring anytime, anywhere.)
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png22} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Behavioral Analytics and Student Safety
                      </Link>
                    </h3>
                    <p>
                      AI can analyze student behavior patterns to detect signs
                      of bullying, harassment, or other safety concerns. It can
                      also monitor campus security cameras in real-time to
                      identify potential threats and alert school authorities.
                      (Behavioral Analytics and Student Safety: AI analyzes
                      student behavior for safety concerns like bullying and
                      monitors campus security cameras for real-time threat
                      detection, enhancing campus safety.)
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png23} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Parent Engagement and Communication
                      </Link>
                    </h3>
                    <p>
                      AI-powered chatbots can facilitate communication between
                      parents and teachers by sending automated updates on
                      students' academic progress, upcoming events, and school
                      announcements. They can also respond to parents' inquiries
                      and schedule parent-teacher conferences. (Parent
                      Engagement and Communication: AI chatbots streamline
                      communication by sending automated updates on student
                      progress, events, and announcements, and scheduling
                      parent-teacher conferences.)
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png24} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Resource Optimization and Cost Reduction
                      </Link>
                    </h3>
                    <p>
                      AI algorithms can analyze data related to resource usage,
                      such as energy consumption, transportation routes, and
                      maintenance schedules, to identify areas where costs can
                      be reduced and resources can be optimized.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png25} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Curriculum Enhancement and Content Recommendation
                      </Link>
                    </h3>
                    <p>
                      AI can analyze curriculum standards, student performance
                      data, and educational research to recommend improvements
                      to the curriculum and suggest supplementary learning
                      materials that align with students' interests and learning
                      goals.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png26} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Smart Scheduling and Timetabling
                      </Link>
                    </h3>
                    <p>
                      AI algorithms can optimize school timetables by
                      considering factors like teacher availability, classroom
                      capacity, and student preferences. This ensures efficient
                      use of resources and minimizes scheduling conflicts.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png27} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">
                        Poster creation for the school events by using A
                      </Link>
                    </h3>
                    <p>
                      Dynamic poster creation can be done by using the inbuilt
                      AI.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-item-seven sevnenew">
                  <div class="services-icon-seven">
                    <img src={edpedia_png28} alt="" />
                  </div>
                  <div class="services-content-seven">
                    <h3 class="title">
                      <Link to="/ai-feature">Daily quiz by AI</Link>
                    </h3>
                    <p>
                      This will help students and staff in order to improve the
                      knowledge of student.
                    </p>
                  </div>
                  <div class="shape">
                    <img src={service_item_shape} alt="shape" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-shape-wrap">
            <img
              src={service_shape_01}
              alt="shape"
              data-aos="fade-down-left"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
            <img
              src={service_shape_02}
              alt="shape"
              data-aos="fade-up-right"
              data-aos-delay="400"
              class="aos-init aos-animate"
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default AiFeatures;
