import React, { useEffect } from "react";
import breadcrumb_shape1 from "../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../assets/img/images/breadcrumb_shape02.png";
import { Link } from "react-router-dom";
import AOS from "aos";
const Privacy = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="breadcrumb-area text-left ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>

                    <li className="breadcrumb-item active">Privacy Policy</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>

      <section className="privacy-policy-area pt-120 pb-120">
        <div className="container">
          <div className="inner-privacy-policy-wrap blog-widget">
            <div className="row justify-content-center">
              <div className="col-12">
                <div style={{ padding: 30 }}>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "  [data-custom-class='body'], [data-custom-class='body'] * {          background: transparent !important;        }[data-custom-class='title'], [data-custom-class='title'] * {          font-family: Arial !important;font-size: 26px !important;color: #000000 !important;        }[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {          font-family: Arial !important;color: #595959 !important;font-size: 14px !important;        }[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {          font-family: Arial !important;font-size: 19px !important;color: #000000 !important;        }[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {          font-family: Arial !important;font-size: 17px !important;color: #000000 !important;        }[data-custom-class='body_text'], [data-custom-class='body_text'] * {          color: #595959 !important;font-size: 14px !important;font-family: Arial !important;        }[data-custom-class='link'], [data-custom-class='link'] * {          color: #3030F1 !important;font-size: 14px !important;font-family: Arial !important;word-break: break-word !important;        }",
                    }}
                  />
                  <div data-custom-class="body">
                    <div>
                      <strong>
                        <span
                          style={{
                            fontSize: "26px",
                          }}
                        >
                          <span data-custom-class="title">
                            <bdt className="block-component" />
                            <bdt className="question">PRIVACY POLICY</bdt>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </strong>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <span
                        style={{
                          color: "rgb(127, 127, 127)",
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="subtitle">
                              Last updated{" "}
                              <bdt className="question">August 03, 2024</bdt>
                            </span>
                          </span>
                        </strong>
                      </span>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <div
                      style={{
                        lineHeight: "1.5",
                      }}
                    >
                      <span
                        style={{
                          color: "rgb(127, 127, 127)",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            This privacy notice for{" "}
                            <bdt className="question">
                              EagleMount Software Pvt Ltd
                              <bdt className="block-component" />
                            </bdt>{" "}
                            (
                            <bdt className="block-component" />"
                            <strong>we</strong>
                            ," "<strong>us</strong>
                            ," or "<strong>our</strong>
                            "
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                          <span data-custom-class="body_text">
                            ), describes how and why we might collect, store,
                            use, and/or share (
                            <bdt className="block-component" />"
                            <strong>process</strong>
                            "
                            <bdt className="statement-end-if-in-editor" />
                            ) your information when you use our services (
                            <bdt className="block-component" />"
                            <strong>Services</strong>
                            "
                            <bdt className="statement-end-if-in-editor" />
                            ), such as when you:
                          </span>
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(127, 127, 127)",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <ul>
                      <li
                        data-custom-class="body_text"
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              Visit our website
                              <bdt className="block-component" /> at{" "}
                              <span
                                style={{
                                  color: "rgb(0, 58, 250)",
                                }}
                              >
                                <bdt className="question">
                                  <a
                                    data-custom-class="link"
                                    href="https://edpedia.co"
                                    target="_blank"
                                  >
                                    https://edpedia.co
                                  </a>
                                </bdt>
                              </span>
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <bdt className="statement-end-if-in-editor">
                                          , or any website of ours that links to
                                          this privacy notice
                                        </bdt>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div>
                      <bdt className="block-component">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(127, 127, 127)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </bdt>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                Engage with us in other related ways, including
                                any sales, marketing, or events
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: "15px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>Questions or concerns? </strong>
                              Reading this privacy notice will help you
                              understand your privacy rights and choices. If you
                              do not agree with our policies and practices,
                              please do not use our Services.
                              <bdt className="block-component" /> If you still
                              have any questions or concerns, please contact us
                              at{" "}
                              <bdt className="question">
                                contact@eaglemountsoft.com
                              </bdt>
                              .
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="heading_1">
                              SUMMARY OF KEY POINTS
                            </span>
                          </span>
                        </strong>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>
                                This summary provides key points from our
                                privacy notice, but you can find out more
                                details about any of these topics by clicking
                                the link following each key point or by using
                                our 
                              </em>
                            </strong>
                          </span>
                        </span>
                        <a data-custom-class="link" href="#toc">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>table of contents</em>
                              </strong>
                            </span>
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>
                                 below to find the section you are looking for.
                              </em>
                            </strong>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              What personal information do we process?
                            </strong>{" "}
                            When you visit, use, or navigate our Services, we
                            may process personal information depending on how
                            you interact with us and the Services, the choices
                            you make, and the products and features you use.
                            Learn more about 
                          </span>
                        </span>
                        <a data-custom-class="link" href="#personalinfo">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              personal information you disclose to us
                            </span>
                          </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              Do we process any sensitive personal information?
                            </strong>{" "}
                            <bdt className="block-component" />
                            We do not process sensitive personal information.
                            <bdt className="else-block" />
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              Do we collect any information from third parties?
                            </strong>{" "}
                            <bdt className="block-component" />
                            We do not collect any information from third
                            parties.
                            <bdt className="else-block" />
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>How do we process your information?</strong>{" "}
                            We process your information to provide, improve, and
                            administer our Services, communicate with you, for
                            security and fraud prevention, and to comply with
                            law. We may also process your information for other
                            purposes with your consent. We process your
                            information only when we have a valid legal reason
                            to do so. Learn more about 
                          </span>
                        </span>
                        <a data-custom-class="link" href="#infouse">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              how we process your information
                            </span>
                          </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              In what situations and with which{" "}
                              <bdt className="block-component" />
                              parties do we share personal information?
                            </strong>{" "}
                            We may share information in specific situations and
                            with specific <bdt className="block-component" />
                            third parties. Learn more about 
                          </span>
                        </span>
                        <a data-custom-class="link" href="#whoshare">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              when and with whom we share your personal
                              information
                            </span>
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            .
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              How do we keep your information safe?
                            </strong>{" "}
                            We have <bdt className="block-component" />
                            organizational
                            <bdt className="statement-end-if-in-editor" /> and
                            technical processes and procedures in place to
                            protect your personal information. However, no
                            electronic transmission over the internet or
                            information storage technology can be guaranteed to
                            be 100% secure, so we cannot promise or guarantee
                            that hackers, cybercriminals, or other{" "}
                            <bdt className="block-component" />
                            unauthorized
                            <bdt className="statement-end-if-in-editor" /> third
                            parties will not be able to defeat our security and
                            improperly collect, access, steal, or modify your
                            information. Learn more about 
                          </span>
                        </span>
                        <a data-custom-class="link" href="#infosafe">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              how we keep your information safe
                            </span>
                          </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>What are your rights?</strong> Depending on
                            where you are located geographically, the applicable
                            privacy law may mean you have certain rights
                            regarding your personal information. Learn more
                            about 
                          </span>
                        </span>
                        <a data-custom-class="link" href="#privacyrights">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              your privacy rights
                            </span>
                          </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>How do you exercise your rights?</strong>{" "}
                            The easiest way to exercise your rights is by{" "}
                            <bdt className="block-component">submitting a </bdt>
                          </span>
                        </span>
                        <a
                          data-custom-class="link"
                          href="https://app.termly.io/notify/157ce2e3-2870-4349-a449-874e7ffe672d"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              data subject access request
                            </span>
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />, or by
                            contacting us. We will consider and act upon any
                            request in accordance with applicable data
                            protection laws.
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            Want to learn more about what we do with any
                            information we collect? 
                          </span>
                        </span>
                        <a data-custom-class="link" href="#toc">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              Review the privacy notice in full
                            </span>
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">.</span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        id="toc"
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(0, 0, 0)",
                              }}
                            >
                              <strong>
                                <span data-custom-class="heading_1">
                                  TABLE OF CONTENTS
                                </span>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#infocollect">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                          </a>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#infouse">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              2. HOW DO WE PROCESS YOUR INFORMATION?
                              <bdt className="block-component" />
                            </span>
                          </a>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                            }}
                          >
                            <a data-custom-class="link" href="#whoshare">
                              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                              INFORMATION?
                            </a>
                          </span>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            <span
                              style={{
                                color: "rgb(127, 127, 127)",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#cookies">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              4. DO WE USE COOKIES AND OTHER TRACKING
                              TECHNOLOGIES?
                            </span>
                          </a>
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                          <bdt className="block-component" />
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#inforetain">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              5. HOW LONG DO WE KEEP YOUR INFORMATION?
                            </span>
                          </a>
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#infosafe">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                            </span>
                          </a>
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor" />
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#infominors">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              7. DO WE COLLECT INFORMATION FROM MINORS?
                            </span>
                          </a>
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                            }}
                          >
                            <a data-custom-class="link" href="#privacyrights">
                              8. WHAT ARE YOUR PRIVACY RIGHTS?
                            </a>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <a data-custom-class="link" href="#DNT">
                            <span
                              style={{
                                color: "rgb(0, 58, 250)",
                              }}
                            >
                              9. CONTROLS FOR DO-NOT-TRACK FEATURES
                              <bdt className="block-component" />
                            </span>
                            <bdt className="block-component">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                          </a>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <a data-custom-class="link" href="#DNT">
                          <bdt className="block-component">
                            <span
                              style={{
                                fontSize: "15px",
                              }}
                            />
                          </bdt>
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                        </a>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <a data-custom-class="link" href="#DNT">
                          <span
                            style={{
                              fontSize: "15px",
                            }}
                          />
                        </a>
                        <a data-custom-class="link" href="#policyupdates">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                            }}
                          >
                            10. DO WE MAKE UPDATES TO THIS NOTICE?
                          </span>
                        </a>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <a data-custom-class="link" href="#contact">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                              fontSize: "15px",
                            }}
                          >
                            11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                          </span>
                        </a>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <a data-custom-class="link" href="#request">
                          <span
                            style={{
                              color: "rgb(0, 58, 250)",
                            }}
                          >
                            12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                            WE COLLECT FROM YOU?
                          </span>
                        </a>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        id="infocollect"
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(0, 0, 0)",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(0, 0, 0)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(0, 0, 0)",
                                fontSize: "15px",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(0, 0, 0)",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  id="control"
                                  style={{
                                    color: "rgb(0, 0, 0)",
                                  }}
                                >
                                  <strong>
                                    <span data-custom-class="heading_1">
                                      1. WHAT INFORMATION DO WE COLLECT?
                                    </span>
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        id="personalinfo"
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          data-custom-class="heading_2"
                          style={{
                            color: "rgb(0, 0, 0)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            <strong>
                              Personal information you disclose to us
                            </strong>
                          </span>
                        </span>
                      </div>
                      <div>
                        <div>
                          <br />
                        </div>
                        <div
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        <em>In Short:</em>
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        <em></em>
                                      </strong>
                                      <em>
                                        We collect personal information that you
                                        provide to us.
                                      </em>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              We collect personal information that you
                              voluntarily provide to us when you{" "}
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <bdt className="block-component" />
                              </span>
                              register on the Services, 
                            </span>
                            <span
                              style={{
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                express an interest in obtaining information
                                about us or our products and Services, when you
                                participate in activities on the Services, or
                                otherwise when you contact us.
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                Personal Information Provided by You.
                              </strong>{" "}
                              The personal information that we collect depends
                              on the context of your interactions with us and
                              the Services, the choices you make, and the
                              products and features you use. The personal
                              information we collect may include the following:
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">names</bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">
                                      phone numbers
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">
                                      email addresses
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">
                                      mailing addresses
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">
                                      contact preferences
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">
                                      billing addresses
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="question">job titles</bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="forloop-component" />
                                </span>
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        id="sensitiveinfo"
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <strong>Sensitive Information.</strong>{" "}
                            <bdt className="block-component" />
                            We do not process sensitive information.
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="else-block" />
                          </span>
                        </span>
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>Payment Data.</strong> We may collect data
                              necessary to process your payment if you choose to
                              make purchases, such as your payment instrument
                              number, and the security code associated with your
                              payment instrument. All payment data is handled
                              and stored by
                              <bdt className="forloop-component" />
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>{" "}
                              <bdt className="question">PhonePe</bdt>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="forloop-component" />
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />{" "}
                                                                      and{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="question">
                                                                              Razorpay
                                                                            </bdt>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize:
                                                                                                "15px",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="forloop-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      . You may
                                                                      find their
                                                                      privacy
                                                                      notice
                                                                      link(s)
                                                                      here:
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="forloop-component" />
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(0, 58, 250)",
                                                                        }}
                                                                      >
                                                                        <bdt className="question">
                                                                          <a
                                                                            data-custom-class="link"
                                                                            href="https://www.phonepe.com/privacy-policy"
                                                                            target="_blank"
                                                                          >
                                                                            https://www.phonepe.com/privacy-policy
                                                                          </a>
                                                                        </bdt>
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                        fontSize:
                                                                                          "15px",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize:
                                                                                              "15px",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="forloop-component" />
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />{" "}
                                                                                      and{" "}
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(0, 58, 250)",
                                                                                          fontSize:
                                                                                            "15px",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(0, 58, 250)",
                                                                                            fontSize:
                                                                                              "15px",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="question">
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="https://razorpay.com/privacy/"
                                                                                                target="_blank"
                                                                                              >
                                                                                                https://razorpay.com/privacy/
                                                                                              </a>
                                                                                            </bdt>
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize:
                                                                                                  "15px",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize:
                                                                                                    "15px",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize:
                                                                                                        "15px",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <bdt className="forloop-component" />
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          .
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component" />
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                          <bdt className="block-component" />
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              All personal information that you provide to us
                              must be true, complete, and accurate, and you must
                              notify us of any changes to such personal
                              information.
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                        <bdt className="block-component">
                          <span
                            style={{
                              fontSize: "15px",
                            }}
                          />
                        </bdt>
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor">
                                        <bdt className="block-component" />
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        id="infouse"
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(127, 127, 127)",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  id="control"
                                  style={{
                                    color: "rgb(0, 0, 0)",
                                  }}
                                >
                                  <strong>
                                    <span data-custom-class="heading_1">
                                      2. HOW DO WE PROCESS YOUR INFORMATION?
                                    </span>
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div>
                        <div
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <br />
                        </div>
                        <div
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(127, 127, 127)",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        <em>In Short: </em>
                                      </strong>
                                      <em>
                                        We process your information to provide,
                                        improve, and administer our Services,
                                        communicate with you, for security and
                                        fraud prevention, and to comply with
                                        law. We may also process your
                                        information for other purposes with your
                                        consent.
                                      </em>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <br />
                      </div>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                We process your personal information for a
                                variety of reasons, depending on how you
                                interact with our Services, including:
                              </strong>
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <strong>
                                  To facilitate account creation and
                                  authentication and otherwise manage user
                                  accounts. 
                                </strong>
                                We may process your information so you can
                                create and log in to your account, as well as
                                keep your account in working order.
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: "15px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </div>
                      <ul>
                        <li
                          data-custom-class="body_text"
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <strong>
                                  To deliver and facilitate delivery of services
                                  to the user. 
                                </strong>
                                We may process your information to provide you
                                with the requested service.
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: "15px",
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(89, 89, 89)",
                            fontSize: "15px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                        <div
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </div>
                        <ul>
                          <li
                            data-custom-class="body_text"
                            style={{
                              lineHeight: "1.5",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    To respond to user inquiries/offer support
                                    to users. 
                                  </strong>
                                  We may process your information to respond to
                                  your inquiries and solve any potential issues
                                  you might have with the requested service.
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{
                                            fontSize: "15px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <div
                          style={{
                            lineHeight: "1.5",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}
                          >
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          <div
                            style={{
                              lineHeight: "1.5",
                            }}
                          >
                            <bdt className="block-component">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              />
                            </bdt>
                          </div>
                          <ul>
                            <li
                              data-custom-class="body_text"
                              style={{
                                lineHeight: "1.5",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <strong>
                                      To send administrative information to
                                      you. 
                                    </strong>
                                    We may process your information to send you
                                    details about our products and services,
                                    changes to our terms and policies, and other
                                    similar information.
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div
                            style={{
                              lineHeight: "1.5",
                            }}
                          >
                            <bdt className="block-component">
                              <span
                                style={{
                                  fontSize: "15px",
                                }}
                              />
                            </bdt>
                            <div
                              style={{
                                lineHeight: "1.5",
                              }}
                            >
                              <bdt className="block-component">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                />
                              </bdt>
                            </div>
                            <ul>
                              <li
                                data-custom-class="body_text"
                                style={{
                                  lineHeight: "1.5",
                                }}
                              >
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        To <bdt className="block-component" />
                                        fulfill
                                        <bdt className="statement-end-if-in-editor" />{" "}
                                        and manage your orders. 
                                      </strong>
                                      We may process your information to{" "}
                                      <bdt className="block-component" />
                                      fulfill
                                      <bdt className="statement-end-if-in-editor" />{" "}
                                      and manage your orders, payments, returns,
                                      and exchanges made through the Services.
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: "15px",
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: "15px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div
                              style={{
                                lineHeight: "1.5",
                              }}
                            >
                              <bdt className="block-component">
                                <span
                                  style={{
                                    fontSize: "15px",
                                  }}
                                >
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <p
                                style={{
                                  fontSize: "15px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <bdt className="block-component">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  />
                                </bdt>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <bdt className="block-component">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  />
                                </bdt>
                              </p>
                              <p
                                style={{
                                  fontSize: "15px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <bdt className="block-component" />
                              </p>
                              <ul>
                                <li
                                  data-custom-class="body_text"
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <strong>To request feedback. </strong>
                                          We may process your information when
                                          necessary to request feedback and to
                                          contact you about your use of our
                                          Services.
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </li>
                              </ul>
                              <p
                                style={{
                                  fontSize: "15px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <bdt className="block-component" />
                              </p>
                              <div
                                style={{
                                  lineHeight: "1.5",
                                }}
                              >
                                <bdt className="block-component">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  >
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <bdt className="block-component">
                                    <span
                                      style={{
                                        fontSize: "15px",
                                      }}
                                    />
                                  </bdt>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <bdt className="block-component">
                                      <span
                                        style={{
                                          fontSize: "15px",
                                        }}
                                      />
                                    </bdt>
                                    <div
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "15px",
                                        }}
                                      >
                                        <bdt className="block-component">
                                          <span data-custom-class="body_text" />
                                        </bdt>
                                      </span>
                                      <div
                                        style={{
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <bdt className="block-component">
                                          <span
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          >
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div
                                          style={{
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          <bdt className="block-component">
                                            <span
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            >
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </div>
                                        <ul>
                                          <li
                                            data-custom-class="body_text"
                                            style={{
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <strong>
                                                  To protect our Services.
                                                </strong>{" "}
                                                We may process your information
                                                as part of our efforts to keep
                                                our Services safe and secure,
                                                including fraud monitoring and
                                                prevention.
                                              </span>
                                            </span>
                                            <bdt className="statement-end-if-in-editor">
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </li>
                                        </ul>
                                        <div
                                          style={{
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          <bdt className="block-component">
                                            <span
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            >
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div
                                            style={{
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            <bdt className="block-component">
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div
                                              style={{
                                                lineHeight: "1.5",
                                              }}
                                            >
                                              <bdt className="block-component">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                            </div>
                                            <ul>
                                              <li
                                                data-custom-class="body_text"
                                                style={{
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    <strong>
                                                      To evaluate and improve
                                                      our Services, products,
                                                      marketing, and your
                                                      experience.
                                                    </strong>{" "}
                                                    We may process your
                                                    information when we believe
                                                    it is necessary to identify
                                                    usage trends, determine the
                                                    effectiveness of our
                                                    promotional campaigns, and
                                                    to evaluate and improve our
                                                    Services, products,
                                                    marketing, and your
                                                    experience.
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </li>
                                            </ul>
                                            <div
                                              style={{
                                                lineHeight: "1.5",
                                              }}
                                            >
                                              <bdt className="block-component">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{
                                                  lineHeight: "1.5",
                                                }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{
                                                    lineHeight: "1.5",
                                                  }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </bdt>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="whoshare"
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span data-custom-class="heading_1">
                                                                        3. WHEN
                                                                        AND WITH
                                                                        WHOM DO
                                                                        WE SHARE
                                                                        YOUR
                                                                        PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <em>
                                                                    In Short:
                                                                  </em>
                                                                </strong>
                                                                <em>
                                                                   We may share
                                                                  information in
                                                                  specific
                                                                  situations
                                                                  described in
                                                                  this section
                                                                  and/or with
                                                                  the following{" "}
                                                                  <bdt className="block-component" />
                                                                  third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We{" "}
                                                              <bdt className="block-component" />
                                                              may need to share
                                                              your personal
                                                              information in the
                                                              following
                                                              situations:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Business
                                                                  Transfers.
                                                                </strong>{" "}
                                                                We may share or
                                                                transfer your
                                                                information in
                                                                connection with,
                                                                or during
                                                                negotiations of,
                                                                any merger, sale
                                                                of company
                                                                assets,
                                                                financing, or
                                                                acquisition of
                                                                all or a portion
                                                                of our business
                                                                to another
                                                                company.
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  When we use
                                                                  Google Maps
                                                                  Platform APIs.
                                                                </strong>{" "}
                                                                We may share
                                                                your information
                                                                with certain
                                                                Google Maps
                                                                Platform APIs
                                                                (e.g.
                                                                <bdt className="block-component" />
                                                                ,
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                Google Maps API,
                                                                Places API).
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                <bdt className="block-component" />
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span data-custom-class="body_text" />
                                                            </bdt>
                                                          </span>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5",
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span data-custom-class="heading_1">
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="cookies"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                4.
                                                                                DO
                                                                                WE
                                                                                USE
                                                                                COOKIES
                                                                                AND
                                                                                OTHER
                                                                                TRACKING
                                                                                TECHNOLOGIES?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                           We
                                                                          may
                                                                          use
                                                                          cookies
                                                                          and
                                                                          other
                                                                          tracking
                                                                          technologies
                                                                          to
                                                                          collect
                                                                          and
                                                                          store
                                                                          your
                                                                          information.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We may
                                                                        use
                                                                        cookies
                                                                        and
                                                                        similar
                                                                        tracking
                                                                        technologies
                                                                        (like
                                                                        web
                                                                        beacons
                                                                        and
                                                                        pixels)
                                                                        to
                                                                        gather
                                                                        information
                                                                        when you
                                                                        interact
                                                                        with our
                                                                        Services.
                                                                        Some
                                                                        online
                                                                        tracking
                                                                        technologies
                                                                        help us
                                                                        maintain
                                                                        the
                                                                        security
                                                                        of our
                                                                        Services
                                                                        <bdt className="block-component" />{" "}
                                                                        and your
                                                                        account
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        ,
                                                                        prevent
                                                                        crashes,
                                                                        fix
                                                                        bugs,
                                                                        save
                                                                        your
                                                                        preferences,
                                                                        and
                                                                        assist
                                                                        with
                                                                        basic
                                                                        site
                                                                        functions.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We also
                                                                        permit
                                                                        third
                                                                        parties
                                                                        and
                                                                        service
                                                                        providers
                                                                        to use
                                                                        online
                                                                        tracking
                                                                        technologies
                                                                        on our
                                                                        Services
                                                                        for
                                                                        analytics
                                                                        and
                                                                        advertising,
                                                                        including
                                                                        to help
                                                                        manage
                                                                        and
                                                                        display
                                                                        advertisements,
                                                                        to
                                                                        tailor
                                                                        advertisements
                                                                        to your
                                                                        interests,
                                                                        or to
                                                                        send
                                                                        abandoned
                                                                        shopping
                                                                        cart
                                                                        reminders
                                                                        (depending
                                                                        on your
                                                                        communication
                                                                        preferences).
                                                                        The
                                                                        third
                                                                        parties
                                                                        and
                                                                        service
                                                                        providers
                                                                        use
                                                                        their
                                                                        technology
                                                                        to
                                                                        provide
                                                                        advertising
                                                                        about
                                                                        products
                                                                        and
                                                                        services
                                                                        tailored
                                                                        to your
                                                                        interests
                                                                        which
                                                                        may
                                                                        appear
                                                                        either
                                                                        on our
                                                                        Services
                                                                        or on
                                                                        other
                                                                        websites.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        Specific
                                                                        information
                                                                        about
                                                                        how we
                                                                        use such
                                                                        technologies
                                                                        and how
                                                                        you can
                                                                        refuse
                                                                        certain
                                                                        cookies
                                                                        is set
                                                                        out in
                                                                        our
                                                                        Cookie
                                                                        Notice
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                            .
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                      </span>
                                                                                      <bdt className="block-component">
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="inforetain"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                5.
                                                                                HOW
                                                                                LONG
                                                                                DO
                                                                                WE
                                                                                KEEP
                                                                                YOUR
                                                                                INFORMATION?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short: 
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          We
                                                                          keep
                                                                          your
                                                                          information
                                                                          for as
                                                                          long
                                                                          as
                                                                          necessary
                                                                          to{" "}
                                                                          <bdt className="block-component" />
                                                                          fulfill
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          the
                                                                          purposes
                                                                          outlined
                                                                          in
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          unless
                                                                          otherwise
                                                                          required
                                                                          by
                                                                          law.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We will
                                                                        only
                                                                        keep
                                                                        your
                                                                        personal
                                                                        information
                                                                        for as
                                                                        long as
                                                                        it is
                                                                        necessary
                                                                        for the
                                                                        purposes
                                                                        set out
                                                                        in this
                                                                        privacy
                                                                        notice,
                                                                        unless a
                                                                        longer
                                                                        retention
                                                                        period
                                                                        is
                                                                        required
                                                                        or
                                                                        permitted
                                                                        by law
                                                                        (such as
                                                                        tax,
                                                                        accounting,
                                                                        or other
                                                                        legal
                                                                        requirements).
                                                                        <bdt className="block-component" />{" "}
                                                                        No
                                                                        purpose
                                                                        in this
                                                                        notice
                                                                        will
                                                                        require
                                                                        us
                                                                        keeping
                                                                        your
                                                                        personal
                                                                        information
                                                                        for
                                                                        longer
                                                                        than{" "}
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                        the
                                                                        period
                                                                        of time
                                                                        in which
                                                                        users
                                                                        have an
                                                                        account
                                                                        with us
                                                                        <bdt className="block-component" />
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="else-block" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        When we
                                                                        have no
                                                                        ongoing
                                                                        legitimate
                                                                        business
                                                                        need to
                                                                        process
                                                                        your
                                                                        personal
                                                                        information,
                                                                        we will
                                                                        either
                                                                        delete
                                                                        or{" "}
                                                                        <bdt className="block-component" />
                                                                        anonymize
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        such
                                                                        information,
                                                                        or, if
                                                                        this is
                                                                        not
                                                                        possible
                                                                        (for
                                                                        example,
                                                                        because
                                                                        your
                                                                        personal
                                                                        information
                                                                        has been
                                                                        stored
                                                                        in
                                                                        backup
                                                                        archives),
                                                                        then we
                                                                        will
                                                                        securely
                                                                        store
                                                                        your
                                                                        personal
                                                                        information
                                                                        and
                                                                        isolate
                                                                        it from
                                                                        any
                                                                        further
                                                                        processing
                                                                        until
                                                                        deletion
                                                                        is
                                                                        possible.
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="infosafe"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                6.
                                                                                HOW
                                                                                DO
                                                                                WE
                                                                                KEEP
                                                                                YOUR
                                                                                INFORMATION
                                                                                SAFE?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short: 
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          We aim
                                                                          to
                                                                          protect
                                                                          your
                                                                          personal
                                                                          information
                                                                          through
                                                                          a
                                                                          system
                                                                          of{" "}
                                                                          <bdt className="block-component" />
                                                                          organizational
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          and
                                                                          technical
                                                                          security
                                                                          measures.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We have
                                                                        implemented
                                                                        appropriate
                                                                        and
                                                                        reasonable
                                                                        technical
                                                                        and{" "}
                                                                        <bdt className="block-component" />
                                                                        organizational
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        security
                                                                        measures
                                                                        designed
                                                                        to
                                                                        protect
                                                                        the
                                                                        security
                                                                        of any
                                                                        personal
                                                                        information
                                                                        we
                                                                        process.
                                                                        However,
                                                                        despite
                                                                        our
                                                                        safeguards
                                                                        and
                                                                        efforts
                                                                        to
                                                                        secure
                                                                        your
                                                                        information,
                                                                        no
                                                                        electronic
                                                                        transmission
                                                                        over the
                                                                        Internet
                                                                        or
                                                                        information
                                                                        storage
                                                                        technology
                                                                        can be
                                                                        guaranteed
                                                                        to be
                                                                        100%
                                                                        secure,
                                                                        so we
                                                                        cannot
                                                                        promise
                                                                        or
                                                                        guarantee
                                                                        that
                                                                        hackers,
                                                                        cybercriminals,
                                                                        or other{" "}
                                                                        <bdt className="block-component" />
                                                                        unauthorized
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        third
                                                                        parties
                                                                        will not
                                                                        be able
                                                                        to
                                                                        defeat
                                                                        our
                                                                        security
                                                                        and
                                                                        improperly
                                                                        collect,
                                                                        access,
                                                                        steal,
                                                                        or
                                                                        modify
                                                                        your
                                                                        information.
                                                                        Although
                                                                        we will
                                                                        do our
                                                                        best to
                                                                        protect
                                                                        your
                                                                        personal
                                                                        information,
                                                                        transmission
                                                                        of
                                                                        personal
                                                                        information
                                                                        to and
                                                                        from our
                                                                        Services
                                                                        is at
                                                                        your own
                                                                        risk.
                                                                        You
                                                                        should
                                                                        only
                                                                        access
                                                                        the
                                                                        Services
                                                                        within a
                                                                        secure
                                                                        environment.
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="infominors"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                7.
                                                                                DO
                                                                                WE
                                                                                COLLECT
                                                                                INFORMATION
                                                                                FROM
                                                                                MINORS?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                           We do
                                                                          not
                                                                          knowingly
                                                                          collect
                                                                          data
                                                                          from
                                                                          or
                                                                          market
                                                                          to{" "}
                                                                          <bdt className="block-component" />
                                                                          children
                                                                          under
                                                                          18
                                                                          years
                                                                          of age
                                                                          <bdt className="else-block" />
                                                                          .
                                                                        </em>
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We do
                                                                        not
                                                                        knowingly
                                                                        collect,
                                                                        solicit
                                                                        data
                                                                        from, or
                                                                        market
                                                                        to
                                                                        children
                                                                        under 18
                                                                        years of
                                                                        age, nor
                                                                        do we
                                                                        knowingly
                                                                        sell
                                                                        such
                                                                        personal
                                                                        information.
                                                                        By using
                                                                        the
                                                                        Services,
                                                                        you
                                                                        represent
                                                                        that you
                                                                        are at
                                                                        least 18
                                                                        or that
                                                                        you are
                                                                        the
                                                                        parent
                                                                        or
                                                                        guardian
                                                                        of such
                                                                        a minor
                                                                        and
                                                                        consent
                                                                        to such
                                                                        minor
                                                                        dependent’s
                                                                        use of
                                                                        the
                                                                        Services.
                                                                        If we
                                                                        learn
                                                                        that
                                                                        personal
                                                                        information
                                                                        from
                                                                        users
                                                                        less
                                                                        than 18
                                                                        years of
                                                                        age has
                                                                        been
                                                                        collected,
                                                                        we will
                                                                        deactivate
                                                                        the
                                                                        account
                                                                        and take
                                                                        reasonable
                                                                        measures
                                                                        to
                                                                        promptly
                                                                        delete
                                                                        such
                                                                        data
                                                                        from our
                                                                        records.
                                                                        If you
                                                                        become
                                                                        aware of
                                                                        any data
                                                                        we may
                                                                        have
                                                                        collected
                                                                        from
                                                                        children
                                                                        under
                                                                        age 18,
                                                                        please
                                                                        contact
                                                                        us at{" "}
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                            <bdt className="question">
                                                                              pawan@eaglemountsoft.com
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="else-block">
                                                                          <bdt className="block-component" />
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="privacyrights"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                8.
                                                                                WHAT
                                                                                ARE
                                                                                YOUR
                                                                                PRIVACY
                                                                                RIGHTS?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <em>
                                                                            In
                                                                            Short:
                                                                          </em>
                                                                        </strong>
                                                                        <em>
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <em>
                                                                                  <bdt className="block-component" />
                                                                                </em>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          You
                                                                          may
                                                                          review,
                                                                          change,
                                                                          or
                                                                          terminate
                                                                          your
                                                                          account
                                                                          at any
                                                                          time,
                                                                          depending
                                                                          on
                                                                          your
                                                                          country,
                                                                          province,
                                                                          or
                                                                          state
                                                                          of
                                                                          residence.
                                                                        </em>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <bdt className="block-component" />
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="withdrawconsent"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <u>
                                                                            Withdrawing
                                                                            your
                                                                            consent:
                                                                          </u>
                                                                        </strong>{" "}
                                                                        If we
                                                                        are
                                                                        relying
                                                                        on your
                                                                        consent
                                                                        to
                                                                        process
                                                                        your
                                                                        personal
                                                                        information,
                                                                        <bdt className="block-component" />{" "}
                                                                        which
                                                                        may be
                                                                        express
                                                                        and/or
                                                                        implied
                                                                        consent
                                                                        depending
                                                                        on the
                                                                        applicable
                                                                        law,
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        you have
                                                                        the
                                                                        right to
                                                                        withdraw
                                                                        your
                                                                        consent
                                                                        at any
                                                                        time.
                                                                        You can
                                                                        withdraw
                                                                        your
                                                                        consent
                                                                        at any
                                                                        time by
                                                                        contacting
                                                                        us by
                                                                        using
                                                                        the
                                                                        contact
                                                                        details
                                                                        provided
                                                                        in the
                                                                        section{" "}
                                                                        <bdt className="block-component" />
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <a
                                                                    data-custom-class="link"
                                                                    href="#contact"
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 58, 250)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(0, 58, 250)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          HOW
                                                                          CAN
                                                                          YOU
                                                                          CONTACT
                                                                          US
                                                                          ABOUT
                                                                          THIS
                                                                          NOTICE?
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        "
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        below
                                                                        <bdt className="block-component" />
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      However,
                                                                      please
                                                                      note that
                                                                      this will
                                                                      not affect
                                                                      the
                                                                      lawfulness
                                                                      of the
                                                                      processing
                                                                      before its
                                                                      withdrawal
                                                                      nor,
                                                                      <bdt className="block-component" />{" "}
                                                                      when
                                                                      applicable
                                                                      law
                                                                      allows,
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      will it
                                                                      affect the
                                                                      processing
                                                                      of your
                                                                      personal
                                                                      information
                                                                      conducted
                                                                      in
                                                                      reliance
                                                                      on lawful
                                                                      processing
                                                                      grounds
                                                                      other than
                                                                      consent.
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="heading_2">
                                                                      <strong>
                                                                        Account
                                                                        Information
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      If you
                                                                      would at
                                                                      any time
                                                                      like to
                                                                      review or
                                                                      change the
                                                                      information
                                                                      in your
                                                                      account or
                                                                      terminate
                                                                      your
                                                                      account,
                                                                      you can:
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <ul>
                                                                  <li
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <bdt className="question">
                                                                          Contact
                                                                          us
                                                                          using
                                                                          the
                                                                          contact
                                                                          information
                                                                          provided.
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <bdt className="forloop-component" />
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Upon your
                                                                      request to
                                                                      terminate
                                                                      your
                                                                      account,
                                                                      we will
                                                                      deactivate
                                                                      or delete
                                                                      your
                                                                      account
                                                                      and
                                                                      information
                                                                      from our
                                                                      active
                                                                      databases.
                                                                      However,
                                                                      we may
                                                                      retain
                                                                      some
                                                                      information
                                                                      in our
                                                                      files to
                                                                      prevent
                                                                      fraud,
                                                                      troubleshoot
                                                                      problems,
                                                                      assist
                                                                      with any
                                                                      investigations,
                                                                      enforce
                                                                      our legal
                                                                      terms
                                                                      and/or
                                                                      comply
                                                                      with
                                                                      applicable
                                                                      legal
                                                                      requirements.
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <u>
                                                                            Cookies
                                                                            and
                                                                            similar
                                                                            technologies:
                                                                          </u>
                                                                        </strong>{" "}
                                                                        Most Web
                                                                        browsers
                                                                        are set
                                                                        to
                                                                        accept
                                                                        cookies
                                                                        by
                                                                        default.
                                                                        If you
                                                                        prefer,
                                                                        you can
                                                                        usually
                                                                        choose
                                                                        to set
                                                                        your
                                                                        browser
                                                                        to
                                                                        remove
                                                                        cookies
                                                                        and to
                                                                        reject
                                                                        cookies.
                                                                        If you
                                                                        choose
                                                                        to
                                                                        remove
                                                                        cookies
                                                                        or
                                                                        reject
                                                                        cookies,
                                                                        this
                                                                        could
                                                                        affect
                                                                        certain
                                                                        features
                                                                        or
                                                                        services
                                                                        of our
                                                                        Services.{" "}
                                                                        <bdt className="block-component">
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "15px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize:
                                                                                              "15px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      If you
                                                                      have
                                                                      questions
                                                                      or
                                                                      comments
                                                                      about your
                                                                      privacy
                                                                      rights,
                                                                      you may
                                                                      email us
                                                                      at{" "}
                                                                      <bdt className="question">
                                                                        contact@eaglemountsoft.com
                                                                      </bdt>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="DNT"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                9.
                                                                                CONTROLS
                                                                                FOR
                                                                                DO-NOT-TRACK
                                                                                FEATURES
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        Most web
                                                                        browsers
                                                                        and some
                                                                        mobile
                                                                        operating
                                                                        systems
                                                                        and
                                                                        mobile
                                                                        applications
                                                                        include
                                                                        a
                                                                        Do-Not-Track
                                                                        (
                                                                        <bdt className="block-component" />
                                                                        "DNT"
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        )
                                                                        feature
                                                                        or
                                                                        setting
                                                                        you can
                                                                        activate
                                                                        to
                                                                        signal
                                                                        your
                                                                        privacy
                                                                        preference
                                                                        not to
                                                                        have
                                                                        data
                                                                        about
                                                                        your
                                                                        online
                                                                        browsing
                                                                        activities
                                                                        monitored
                                                                        and
                                                                        collected.
                                                                        At this
                                                                        stage,
                                                                        no
                                                                        uniform
                                                                        technology
                                                                        standard
                                                                        for{" "}
                                                                        <bdt className="block-component" />
                                                                        recognizing
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        and
                                                                        implementing
                                                                        DNT
                                                                        signals
                                                                        has been{" "}
                                                                        <bdt className="block-component" />
                                                                        finalized
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        . As
                                                                        such, we
                                                                        do not
                                                                        currently
                                                                        respond
                                                                        to DNT
                                                                        browser
                                                                        signals
                                                                        or any
                                                                        other
                                                                        mechanism
                                                                        that
                                                                        automatically
                                                                        communicates
                                                                        your
                                                                        choice
                                                                        not to
                                                                        be
                                                                        tracked
                                                                        online.
                                                                        If a
                                                                        standard
                                                                        for
                                                                        online
                                                                        tracking
                                                                        is
                                                                        adopted
                                                                        that we
                                                                        must
                                                                        follow
                                                                        in the
                                                                        future,
                                                                        we will
                                                                        inform
                                                                        you
                                                                        about
                                                                        that
                                                                        practice
                                                                        in a
                                                                        revised
                                                                        version
                                                                        of this
                                                                        privacy
                                                                        notice.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </bdt>
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="policyupdates"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                10.
                                                                                DO
                                                                                WE
                                                                                MAKE
                                                                                UPDATES
                                                                                TO
                                                                                THIS
                                                                                NOTICE?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <em>
                                                                          <strong>
                                                                            In
                                                                            Short: 
                                                                          </strong>
                                                                          Yes,
                                                                          we
                                                                          will
                                                                          update
                                                                          this
                                                                          notice
                                                                          as
                                                                          necessary
                                                                          to
                                                                          stay
                                                                          compliant
                                                                          with
                                                                          relevant
                                                                          laws.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We may
                                                                        update
                                                                        this
                                                                        privacy
                                                                        notice
                                                                        from
                                                                        time to
                                                                        time.
                                                                        The
                                                                        updated
                                                                        version
                                                                        will be
                                                                        indicated
                                                                        by an
                                                                        updated{" "}
                                                                        <bdt className="block-component" />
                                                                        "Revised"
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        date at
                                                                        the top
                                                                        of this
                                                                        privacy
                                                                        notice.
                                                                        If we
                                                                        make
                                                                        material
                                                                        changes
                                                                        to this
                                                                        privacy
                                                                        notice,
                                                                        we may
                                                                        notify
                                                                        you
                                                                        either
                                                                        by
                                                                        prominently
                                                                        posting
                                                                        a notice
                                                                        of such
                                                                        changes
                                                                        or by
                                                                        directly
                                                                        sending
                                                                        you a
                                                                        notification.
                                                                        We
                                                                        encourage
                                                                        you to
                                                                        review
                                                                        this
                                                                        privacy
                                                                        notice
                                                                        frequently
                                                                        to be
                                                                        informed
                                                                        of how
                                                                        we are
                                                                        protecting
                                                                        your
                                                                        information.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="contact"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                11.
                                                                                HOW
                                                                                CAN
                                                                                YOU
                                                                                CONTACT
                                                                                US
                                                                                ABOUT
                                                                                THIS
                                                                                NOTICE?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        If you
                                                                        have
                                                                        questions
                                                                        or
                                                                        comments
                                                                        about
                                                                        this
                                                                        notice,
                                                                        you may{" "}
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                        contact
                                                                        our Data
                                                                        Protection
                                                                        Officer
                                                                        (DPO)
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                               by
                                                                              email
                                                                              at 
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="question">
                                                                              pawan@eaglemountsoft.com
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                        ,
                                                                        <bdt className="block-component" />
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                               by
                                                                              phone
                                                                              at{" "}
                                                                              <bdt className="question">
                                                                                +91-8660726301
                                                                              </bdt>
                                                                              ,
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="else-block">
                                                                              <bdt className="block-component" />{" "}
                                                                              or{" "}
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              contact
                                                                              us
                                                                              by
                                                                              post
                                                                              at:
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="question">
                                                                                  EagleMount
                                                                                  Software
                                                                                  Pvt
                                                                                  Ltd
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Data
                                                                      Protection
                                                                      Officer
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <bdt className="question">
                                                                      #201,
                                                                      Street 8,
                                                                      Bhavani
                                                                      Colony
                                                                    </bdt>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="question">
                                                                        Bandlaguda
                                                                        Jagir
                                                                      </bdt>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            Hyderabad
                                                                          </bdt>
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <bdt className="block-component" />

                                                                                ,{" "}
                                                                                <bdt className="question">
                                                                                  Telangana
                                                                                </bdt>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                <bdt className="block-component" />{" "}
                                                                                <bdt className="question">
                                                                                  500086
                                                                                </bdt>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <bdt className="block-component" />
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="question">
                                                                            India
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                  <bdt className="statement-end-if-in-editor">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "15px",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="block-component" />
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component">
                                                                                    <bdt className="block-component" />
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt className="block-component">
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      />
                                                                    </bdt>
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you
                                                                      have any
                                                                      further
                                                                      questions
                                                                      or
                                                                      comments,
                                                                      you may
                                                                      also
                                                                      contact us
                                                                      by post at
                                                                      the
                                                                      following
                                                                      corporate
                                                                      address:
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="question">
                                                                        EagleMount
                                                                        Software
                                                                        Pvt Ltd
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="question">
                                                                        #8-1-284/OU/623,
                                                                        OU
                                                                        Colony,
                                                                        Dream
                                                                        Valley
                                                                        Road,
                                                                        Manikonda
                                                                      </bdt>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="question">
                                                                        Shaikpet
                                                                      </bdt>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="question">
                                                                        Hyderabad
                                                                      </bdt>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                            ,{" "}
                                                                            <bdt className="question">
                                                                              Telangana
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="block-component" />{" "}
                                                                            <bdt className="question">
                                                                              500008
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="question">
                                                                        India
                                                                      </bdt>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span data-custom-class="body_text" />
                                                                  </bdt>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    Phone:{" "}
                                                                    <bdt className="question">
                                                                      +91-8660726301
                                                                    </bdt>
                                                                  </span>
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <span data-custom-class="body_text" />
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="request"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(127, 127, 127)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 0, 0)",
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                12.
                                                                                HOW
                                                                                CAN
                                                                                YOU
                                                                                REVIEW,
                                                                                UPDATE,
                                                                                OR
                                                                                DELETE
                                                                                THE
                                                                                DATA
                                                                                WE
                                                                                COLLECT
                                                                                FROM
                                                                                YOU?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        Based on
                                                                        the
                                                                        applicable
                                                                        laws of
                                                                        your
                                                                        country
                                                                        <bdt className="block-component" />
                                                                        , you
                                                                        may
                                                                        <bdt className="else-block">
                                                                          <bdt className="block-component">
                                                                            {" "}
                                                                            have
                                                                            the
                                                                            right
                                                                            to
                                                                            request
                                                                            access
                                                                            to
                                                                            the
                                                                            personal
                                                                            information
                                                                            we
                                                                            collect
                                                                            from
                                                                            you,
                                                                            details
                                                                            about
                                                                            how
                                                                            we
                                                                            have
                                                                            processed
                                                                            it,
                                                                            correct
                                                                            inaccuracies,
                                                                            or
                                                                            delete
                                                                            your
                                                                            personal
                                                                            information.
                                                                            You
                                                                            may
                                                                            also
                                                                            have
                                                                            the
                                                                            right
                                                                            to{" "}
                                                                            <bdt className="block-component" />
                                                                            withdraw
                                                                            your
                                                                            consent
                                                                            to
                                                                            our
                                                                            processing
                                                                            of
                                                                            your
                                                                            personal
                                                                            information.
                                                                            These
                                                                            rights
                                                                            may
                                                                            be
                                                                            limited
                                                                            in
                                                                            some
                                                                            circumstances
                                                                            by
                                                                            applicable
                                                                            law.
                                                                            To
                                                                            request
                                                                            to
                                                                            review,
                                                                            update,
                                                                            or
                                                                            delete
                                                                            your
                                                                            personal
                                                                            information,
                                                                            please{" "}
                                                                            <bdt className="block-component" />
                                                                            fill
                                                                            out
                                                                            and
                                                                            submit
                                                                            a 
                                                                          </bdt>
                                                                        </bdt>
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(0, 58, 250)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(0, 58, 250)",
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="https://app.termly.io/notify/157ce2e3-2870-4349-a449-874e7ffe672d"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                            >
                                                                              data
                                                                              subject
                                                                              access
                                                                              request
                                                                            </a>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span data-custom-class="body_text" />
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                  <span data-custom-class="body_text">
                                                                    .
                                                                  </span>
                                                                </div>
                                                                <style
                                                                  dangerouslySetInnerHTML={{
                                                                    __html:
                                                                      "      ul {        list-style-type: square;      }      ul > li > ul {        list-style-type: circle;      }      ul > li > ul > li > ul {        list-style-type: square;      }      ol li {        font-family: Arial ;      }    ",
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
