import React from "react";
import { Link, useParams } from "react-router-dom";
import { blogData } from "./Data";
import child1 from "../../assets/img/blog/rc_post01.jpg";
import child2 from "../../assets/img/blog/rc_post02.jpg";
import child3 from "../../assets/img/blog/rc_post03.jpg";
import child4 from "../../assets/img/blog/rc_post04.jpg";
import whatsapp from "../../assets/img/icons/whasapp.png";
import qrcode from "../../assets/img/qrcode.png";
import { faChessBoard, faStar } from "@fortawesome/free-solid-svg-icons";
import testimonial1 from "../../assets/img/images/testi_avatar01.png";
import testimonial2 from "../../assets/img/images/testi_avatar02.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import InputFloatLabel from "../../Common/FloatLabel/InputFloatLabel";
import SelectFloatLabel from "../../Common/FloatLabel/SelectFloatLabel";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePickerFloatLable";
import { Button, Col, Form, Row, Select, Space, notification } from "antd";
import "../../assets/css/Modal.css";
import dayjs from "dayjs";
import { useCreateDemoMutation } from "../../RTKQuery/RtkQuery";
import moment from "moment";
const BlogDetail = () => {
  // api call
  const [requestDemo] = Form.useForm();
  const [createDemo, { isLoading }] = useCreateDemoMutation();
  const { Option } = Select;
  const { path } = useParams();

  const blog = blogData.find((blog) => blog.path.toString() === path);

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  const onFinishFailed = (errorInfo) => {};
  const dateWithTime = (preferredDemoDate) => {
    return dayjs(preferredDemoDate).format("YYYY-MM-DD HH:mm:ss");
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onFinish = async (values) => {
    const { preferredDemoDate, ...restValues } = values;
    const formattedDateTime = dateWithTime(preferredDemoDate);

    const payload = {
      ...restValues,
      preferredDemoDate: formattedDateTime,
      schoolSize: "",
      roleAtSchool: "",
      additionalInformation: "",
    };
    try {
      const response = await createDemo(payload);
      if (response.data.status === 200) {
        requestDemo.resetFields();

        notification.success({
          message: "Demo Request Successful",
          description: "Your demo request has been successfully submitted.",
        });
      } else {
        notification.error({
          message: "Demo Request Failed",
          description: "Sorry, something went wrong with your demo request.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Demo Request Failed",
        description: "Sorry, something went wrong with your demo request.",
      });
    }
  };

  const handleModalCancel = () => {
    requestDemo.resetFields();
  };

  return (
    <>
      
      <section class="breadcrumb-area text-left">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li class="breadcrumb-item active">Blog Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="" />
          <img src={breadcrumb_shape2} alt="" />
        </div>
      </section>
      <section class=" mb-20 mt-20">
        <div class="container">
          <div
            class="cta-inner-wrap inner_cta"
            data-background="assets/img/bg/cta_bg.jpg"
          >
            <div class="row align-items-center">
              <div class="col-lg-9">
                <div class="cta-content">
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={testimonial1} alt="" />
                    </div>
                    <div class="content">
                      <span>Blog Author</span>
                      <span>Jonh smith</span>
                    </div>
                  </div>
                  <div class="cta-info-wrap">
                    <div class="icon">
                      <img src={testimonial2} alt="" />
                    </div>
                    <div class="content">
                      <span> Director</span>
                      <span>Harry shen</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="content-container">
                  <div class="blog-content-span">
                    <span> Published Date :</span>
                    <span>5th Dec 2022</span>
                  </div>
                  {/* <div class="blog-content-span">
                    <span> Published Date</span>
                    <span>5th Dec 2022</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="blog-area pt-120 pb-120">
        <div class="container">
          <div class="inner-blog-wrap">
            <div class="row justify-content-center">
              <div className="col-71">
                <div className="blog-post-wrap">
                  <div className="row">
                    <div className="blog-details-header blog-widget">
                      <img src={blog.image} alt="img" />
                      <div className="blog-header-content">
                        <h4 className="bw-title">{blog.title}</h4>
                        <p>{blog.description}</p>
                        <p>{blog.details}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="testimonial-item-wrap-two">
                  <div class="row testimonial-active-two">
                    <div class="testimonial-item-two">
                      <div class="testimonial-content-two">
                        <h3>Transforming Education Management with AI</h3>
                        <p>
                          In the dynamic world of education, managing
                          administrative tasks, enhancing communication, and
                          ensuring efficient operations can be overwhelming.
                          EdPedia, an AI-driven education management system, has
                          been a game-changer for numerous schools. This blog
                          highlights success stories from institutions that have
                          thrived using EdPedia.
                        </p>
                        <h3>Streamlining Administrative Tasks</h3>
                        <p>
                          Many schools have struggled with the burden of
                          administrative work, which often takes valuable time
                          away from educators. EdPedia's automated features have
                          alleviated this burden by simplifying processes like
                          attendance tracking, report generation, and resource
                          management. Schools now report significant time
                          savings, allowing staff to focus more on teaching and
                          student engagement.
                        </p>
                        <h3>Enhancing Communication</h3>
                        <p>
                          Effective communication between staff, students, and
                          parents is crucial for any educational institution.
                          EdPedia has revolutionized this aspect by providing a
                          unified platform for seamless communication. Teachers
                          can easily share updates, homework assignments, and
                          important notices, while parents can monitor their
                          child's progress and stay informed about school
                          activities. This improved interaction has fostered a
                          stronger school community.
                        </p>
                        <h3>Comprehensive Features for Better Management</h3>
                        <p>
                          EdPedia offers a comprehensive suite of features
                          tailored to the unique needs of schools. From academic
                          scheduling and performance analytics to financial
                          management and library systems, EdPedia covers all
                          aspects of school operations. Schools have praised the
                          platform for its user-friendly interface and the depth
                          of its functionality, which has led to more efficient
                          and organized management.
                        </p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-29">
                <aside class="blog-sidebar">
                  <div class="blog-widget">
                    <h4 class="bw-title">Connect for free trial</h4>
                    <div class="bs-cat-list">
                      <Form
                        name="RequestDemo"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={requestDemo}
                      >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="emailId"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Email is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Email"
                                type="email"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone number is required",
                                },
                                {
                                  min: 10,
                                  message: "Please enter valid phone number",
                                },
                                {
                                  max: 10,
                                  message: "Cannot exceed more than 10",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Phone Number"
                                type="number"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="areaOfInterest"
                              rules={[
                                {
                                  required: true,
                                  message: "Area of intrest is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Area of interest"
                              >
                                <Option value="useInstitution">
                                  I want to use it for my Institution
                                </Option>
                                <Option value="resell">
                                  I want to resell it
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="schoolName"
                              rules={[
                                {
                                  required: true,
                                  message: "Institute name is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Institute Name"
                              />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="preferredDemoDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Preferred demo date is required",
                                },
                              ]}
                            >
                              <DatePickerFloatLabel
                                className="datepicker"
                                label="Preferred Demo Date"
                                showTime
                                style={{ width: "100%", height: "40px" }}
                                format={dateWithTime}
                                disabledData={disabledStartDate}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: "Location is required",
                                },
                              ]}
                            >
                              <InputFloatLabel
                                className="float-inputs"
                                label="Location"
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                            <Form.Item
                              name="currentStatusOfERP"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Current status of your ERP is required",
                                },
                              ]}
                            >
                              <SelectFloatLabel
                                className="float-inputs"
                                label="Current ERP status"
                              >
                                <Option value="notInterested">
                                  Not yet, but I'm interested in learning more
                                </Option>
                                <Option value="freeTrial">
                                  Yes, I currently have a free trial account and
                                  wish to learn more
                                </Option>
                                <Option value="paidAccount">
                                  Yes, I currently have a paid account and wish
                                  to learn more
                                </Option>
                                <Option value="renewAccount">
                                  No, I want to renew my account
                                </Option>
                              </SelectFloatLabel>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>

                        <Row
                          className="my-row"
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                          <Col span={12} align="end">
                            <Space direction="vertical" size="large">
                              <Button
                                className="submit-button "
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                            </Space>
                          </Col>
                          <Col span={12}>
                            <Space direction="vertical" size="large">
                              <Button
                                className="cancel-button "
                                onClick={handleModalCancel}
                              >
                                Cancel
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
