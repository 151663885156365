import React from "react";
import breadcrumb_shape1 from "../../assets/img/images/breadcrumb_shape01.png";
import breadcrumb_shape2 from "../../assets/img/images/breadcrumb_shape02.png";
import feature_01 from "../../assets/img/images/features_shape01.png";
import feature_02 from "../../assets/img/images/features_shape02.png";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

const Alltime = () => {
  return (
    <>
      <section className="breadcrumb-area text-left">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/benefits">Benefits</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      24/7 Benefits
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-shape-wrap">
          <img src={breadcrumb_shape1} alt="Breadcrumb Shape 1" />
          <img src={breadcrumb_shape2} alt="Breadcrumb Shape 2" />
        </div>
      </section>
      <section
        className="features-area-five features-bg feature_bg_container"
        data-background="assets/img/bg/features_bg.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6">
              <div className="section-title text-center mb-50">
                <h2 className="title">24/7 Benefits</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className="flaticon-support"></i>
                    </div>
                    <h2 className="title">Around-the-Clock Support</h2>
                  </div>
                  <p>
                    Our dedicated support team is available 24/7 to assist you
                    with any issues or questions you may have. No matter the
                    time of day, help is always just a call or click away.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className="flaticon-support"></i>
                    </div>
                    <h2 className="title">Access Anytime, Anywhere</h2>
                  </div>
                  <p>
                    Enjoy the flexibility of accessing our platform from
                    anywhere in the world at any time. Whether you're in the
                    office, at home, or on the go, our services are always at
                    your fingertips.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className="flaticon-support"></i>
                    </div>
                    <h2 className="title">Real-Time Monitoring</h2>
                  </div>
                  <p>
                    Keep track of important metrics and data in real-time with
                    our monitoring tools. Receive instant alerts and updates,
                    ensuring you're always informed and can respond swiftly to
                    any situation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features-shape-wrap">
          <img src={feature_01} alt="Feature Shape 1" />
          <img src={feature_02} alt="Feature Shape 2" />
        </div>
      </section>
    </>
  );
};

export default Alltime;
